import {
  GET_CHAT_USERS,
  SET_LOADING_CHAT,
  SET_ACTIVE_CHAT_USER,
  GET_CHAT_MESSAGE,
  CLEAR_ACTIVE_MESSAGES,
  CLEAR_CHAT,
  GET_USER_MESSAGES,
  SET_LOADING_MESSAGES,
  GET_ONLINE_USERS,
  GET_UNREAD_MESSAGES,
  READ_MESSAGES,
  GET_WSS_CONNECTION,
  SORT_CHAT_USERS
} from '../actions/types';

const initialState = {
  active_user: {},
  active_messages: [],
  users_available: [],
  users_online: [],
  unread_messages: 0,
  loading: false,
  messages_loading: false,
  wss: null
};

export default function chat(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CHAT_USERS:
      return {
        ...state,
        users_available: payload,
        loading: false
      };
    case SET_LOADING_CHAT:
      return {
        ...state,
        loading: payload
      };
    case GET_WSS_CONNECTION:
      return {
        ...state,
        wss: payload
      };
    case GET_ONLINE_USERS:
      return {
        ...state,
        users_online: payload
      };
    case GET_UNREAD_MESSAGES:
      return {
        ...state,
        unread_messages: payload.qty,
        users_available: payload.users
      };
    case GET_USER_MESSAGES:
      return {
        ...state,
        active_messages: payload.messages,
        users_available: payload.users,
        messages_loading: false
      };
    case READ_MESSAGES:
      return {
        ...state,
        users_available: payload.upd_users,
        unread_messages: payload.unread_messages,
        active_messages: payload.active_messages
      };
    case SET_LOADING_MESSAGES:
      return {
        ...state,
        messages_loading: payload
      };
    case SET_ACTIVE_CHAT_USER:
      const active = state.users_available.find((u) => u._id === payload);
      return {
        ...state,
        active_user: active,
        active_messages: active.messages
          ? active.messages
          : state.active_messages
      };
    case GET_CHAT_MESSAGE:
      return {
        ...state,
        active_messages: payload.messages,
        users_available: payload.upd_users,
        unread_messages: payload.unread_messages
      };
    case SORT_CHAT_USERS:
      return {
        ...state,
        users_available: payload
      };
    case CLEAR_ACTIVE_MESSAGES:
      return {
        ...state,
        active_messages: [],
        active_user: {}
      };
    case CLEAR_CHAT:
      return initialState;
    default:
      return state;
  }
}
