import axios from 'axios';
//import { setAlert } from './alert';
//import setAuthToken from '../utils/setAuthToken';

import {
  GET_EBAY_LQS_DATA,
  GET_EBAY_LQS_CHART,
  GET_EBAY_LQS_MINDATE,
  SET_LOADING_EBAY_LQS,
  SET_LOADING_EBAY_LQS_CHART,
  CLEAR_EBAY_LQS,
} from './types';

const CancelToken = axios.CancelToken;
let cancelChart = null;

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

//Get Data for LQS page
export const getEbayLQS = (name) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING_EBAY_LQS });

    const res = await axios.get(
      `/api/mysql/ebay/lqs?name=${encodeURIComponent(name)}`
    );

    dispatch({
      type: GET_EBAY_LQS_DATA,
      payload: res.data,
    });
  } catch (err) {
    // if (err.response) {
    //   const errors = err.response.data.errors;
    //   if (errors) {
    //     errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    //   }
    // }
    dispatch({
      type: GET_EBAY_LQS_DATA,
      payload: {},
    });
  }
};

export const getEbayLQSminDate = (name) => async (dispatch) => {
  //setAuthToken(localStorage.token);
  try {
    const res = await axios.get(
      `/api/mysql/ebay/lqs/mindate?name=${encodeURIComponent(name)}`
    );

    dispatch({
      type: GET_EBAY_LQS_MINDATE,
      payload: res.data,
    });
  } catch (err) {
    // if (err.response) {
    //   const errors = err.response.data.errors;
    //   if (errors) {
    //     errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    //   }
    // } else {
    //   console.log(err);
    // }
    dispatch({
      type: GET_EBAY_LQS_MINDATE,
      payload: '',
    });
  }
};

//Get Data for LQS page
export const getEbayLQSChart = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_EBAY_LQS_CHART,
    });

    if (cancelChart) {
      cancelChart();
      cancelChart = null;
    }

    const config2 = {
      ...config,
      cancelToken: new CancelToken(function executor(c) {
        cancelChart = c;
      }),
    };

    const body = JSON.stringify(data);
    const res = await axios.post('/api/mysql/ebay/lqs/chart', body, config2);

    dispatch({
      type: GET_EBAY_LQS_CHART,
      payload: res.data,
    });
  } catch (err) {
    // if (err.response) {
    //   const errors = err.response.data.errors;
    //   if (errors) {
    //     errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    //   }
    // }
    dispatch({
      type: GET_EBAY_LQS_CHART,
      payload: [],
    });
  }
};

//Clear Brand
export const clearEbayLQS = () => (dispatch) => {
  dispatch({
    type: CLEAR_EBAY_LQS,
  });
};
