import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';

import {
  GET_SALES_DATA,
  GET_SALES_TABLE,
  SET_LOADING_SALES_TABLE,
  CLEAR_SALES,
  GET_SALES_CHART,
  SET_LOADING_SALES_CHART,
  GET_INVENTORY_ORDERS_CHART,
  SET_LOADING_INVENTORY_ORDERS_CHART,
  GET_SALES_MAP,
  SET_LOADING_SALES_MAP,
  GET_INVENTORY_TABLE,
  SET_LOADING_INVENTORY_TABLE,
  GET_INVENTORY_CHART,
  SET_LOADING_INVENTORY_CHART
} from './types';
import { actionErrorHandler } from '../utils/global.services';

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

const CancelToken = axios.CancelToken;
let cancelSalesChart = null;
let cancelSalesTable = null;
let cancelSalesMap = null;
let cancelInventoryTable = null;
let cancelInventoryOrdersChart = null;
let cancelInventoryChart = null;

//Get Data for Sellers report page
export const getSales =
  ({ name, from, to }) =>
  async (dispatch) => {
    setAuthToken(localStorage.token);
    try {
      const res = await axios.get(
        `/api/mysql/sales/params?name=${encodeURIComponent(
          name
        )}&from=${from}&to=${to}`
      );

      dispatch({
        type: GET_SALES_DATA,
        payload: res.data
      });
    } catch (err) {
      if (err.response) {
        const errors = err.response.data.errors;
        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
        }
      } else {
        console.log(err);
      }
      dispatch({
        type: GET_SALES_DATA,
        payload: {}
      });
    }
  };

//Get Data for Sales chart
export const getSalesChart = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_SALES_CHART
    });

    if (cancelSalesChart) {
      cancelSalesChart();
      cancelSalesChart = null;
    }

    const config2 = {
      ...config,
      cancelToken: new CancelToken(function executor(c) {
        cancelSalesChart = c;
      })
    };

    const body = JSON.stringify(data);
    const res = await axios.post('/api/mysql/sales/saleschart', body, config2);

    dispatch({
      type: GET_SALES_CHART,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_SALES_CHART,
      payload: { sales_chart: [], sales_chart_data: [] }
    });
    actionErrorHandler(err, dispatch, setAlert, SET_LOADING_SALES_CHART);
  }
};

//Get Data for Orders History chart
export const getInventoryOrdersChart =
  ({ name, from, to }) =>
  async (dispatch) => {
    setAuthToken(localStorage.token);
    try {
      dispatch({
        type: SET_LOADING_INVENTORY_ORDERS_CHART
      });

      if (cancelInventoryOrdersChart) {
        cancelInventoryOrdersChart();
        cancelInventoryOrdersChart = null;
      }

      const cancelConfig = {
        cancelToken: new CancelToken(function executor(c) {
          cancelInventoryOrdersChart = c;
        })
      };

      const res = await axios.get(
        `/api/mysql/sales/inventoryorders?name=${encodeURIComponent(
          name
        )}&from=${from}&to=${to}`,
        cancelConfig
      );

      dispatch({
        type: GET_INVENTORY_ORDERS_CHART,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: GET_INVENTORY_ORDERS_CHART,
        payload: { inventory_orders: [], inventory_orders_data: [] }
      });
      actionErrorHandler(
        err,
        dispatch,
        setAlert,
        SET_LOADING_INVENTORY_ORDERS_CHART
      );
    }
  };

//Get Data for Sales tab Table
export const getSalesTable =
  ({ name, from, to }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SET_LOADING_SALES_TABLE
      });

      if (cancelSalesTable) {
        cancelSalesTable();
        cancelSalesTable = null;
      }

      const cancelConfig = {
        cancelToken: new CancelToken(function executor(c) {
          cancelSalesTable = c;
        })
      };

      const res = await axios.get(
        `/api/mysql/sales/table?name=${encodeURIComponent(
          name
        )}&from=${from}&to=${to}`,
        cancelConfig
      );

      dispatch({
        type: GET_SALES_TABLE,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: GET_SALES_TABLE,
        payload: { item_sales: [], item_sales_total: {} }
      });
      actionErrorHandler(err, dispatch, setAlert, SET_LOADING_SALES_TABLE);
    }
  };

//Get Data for Sales tab Map
export const getSalesMap =
  ({ name, from, to }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SET_LOADING_SALES_MAP
      });

      if (cancelSalesMap) {
        cancelSalesMap();
        cancelSalesMap = null;
      }

      const cancelConfig = {
        cancelToken: new CancelToken(function executor(c) {
          cancelSalesMap = c;
        })
      };

      const res = await axios.get(
        `/api/mysql/sales/map?name=${encodeURIComponent(
          name
        )}&from=${from}&to=${to}`,
        cancelConfig
      );

      dispatch({
        type: GET_SALES_MAP,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: GET_SALES_MAP,
        payload: { sales_map: [], sales_map_total: 0 }
      });
      actionErrorHandler(err, dispatch, setAlert, SET_LOADING_SALES_MAP);
    }
  };

//Get Data for Sales tab Table
export const getInventoryTable =
  ({ name, from, to }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SET_LOADING_INVENTORY_TABLE
      });

      if (cancelInventoryTable) {
        cancelInventoryTable();
        cancelInventoryTable = null;
      }

      const cancelConfig = {
        cancelToken: new CancelToken(function executor(c) {
          cancelInventoryTable = c;
        })
      };

      const res = await axios.get(
        `/api/mysql/sales/inventorytable?name=${encodeURIComponent(
          name
        )}&from=${from}&to=${to}`,
        cancelConfig
      );

      dispatch({
        type: GET_INVENTORY_TABLE,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: GET_INVENTORY_TABLE,
        payload: []
      });
      actionErrorHandler(err, dispatch, setAlert, SET_LOADING_INVENTORY_TABLE);
    }
  };

//Get Data for Sales tab Map
export const getInventoryChart =
  ({ name, from, to }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SET_LOADING_INVENTORY_CHART
      });

      if (cancelInventoryChart) {
        cancelInventoryChart();
        cancelInventoryChart = null;
      }

      const cancelConfig = {
        cancelToken: new CancelToken(function executor(c) {
          cancelInventoryChart = c;
        })
      };

      const res = await axios.get(
        `/api/mysql/sales/inventorychart?name=${encodeURIComponent(
          name
        )}&from=${from}&to=${to}`,
        cancelConfig
      );

      dispatch({
        type: GET_INVENTORY_CHART,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: GET_INVENTORY_CHART,
        payload: { inventory_chart: [], inventory_chart_data: [] }
      });
      actionErrorHandler(err, dispatch, setAlert, SET_LOADING_INVENTORY_CHART);
    }
  };

//Get Data for Sellers report page
export const clearSales = () => (dispatch) => {
  dispatch({
    type: CLEAR_SALES
  });
};
