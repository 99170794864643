import {
  SET_ACTIVE_ITEM,
  GET_ITEM,
  SET_ITEM_LOADING,
  SET_CASE,
  CHANGE_CASE,
  REMOVE_CASE,
  CLEAR_ITEM,
} from '../actions/types';

const initialState = {
  active: null,
  data: null,
  case_data: null,
  loading: false,
};

export default function item(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_ACTIVE_ITEM:
      return {
        ...state,
        active: payload,
      };
    case GET_ITEM:
      return {
        ...state,
        data: payload,
        loading: false,
      };
    case SET_CASE:
      return {
        ...state,
        case_data: state.case_data ? [...state.case_data, payload] : [payload],
      };
    case CHANGE_CASE:
      return {
        ...state,
        case_data: state.case_data
          ? state.case_data.map((data) => {
              if (data.key === payload.key) {
                data.text = payload.text;
              }
              return data;
            })
          : [],
      };
    case REMOVE_CASE:
      return {
        ...state,
        case_data: state.case_data.filter((c) => c.key !== payload),
      };
    case SET_ITEM_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case CLEAR_ITEM:
      return initialState;

    default:
      return state;
  }
}
