import React, { useState } from 'react';
import logo from '../../img/logo.png';
import { FaPrint } from 'react-icons/fa';
import { printComponent } from '../../utils/global.services';
import { Button } from '../../UI/Button/Button';
import { updateVendor, updateVendorUser } from '../../actions/user';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { useEulaVersion } from './EulaModal.service';
import classes from './EulaModal.module.css';
import {
  eulaAcceptedMessage,
  eulaDeniedMessage,
  eulaPrintHeader,
  eulaStatuses
} from './EulaModal.data';
import { formatFullMonthDate } from '../../utils/constants';
import { roles } from '../../utils/accessPage/accessPage.data';
import { ModalContainer } from '../../UI/ModalContainer/ModalContainer';

const EulaModal = ({
  auth: { user, prohibitedEmailAccepted },
  setAlert,
  updateVendor,
  updateVendorUser
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const eulaStatus = useEulaVersion(user);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleAccept = async () => {
    const data = {
      formData: {
        eula_version: user.eula_current_version,
        eula_status: eulaStatuses.accepted
      },
      hideAlert: true
    };
    const res =
      user.role === roles.vendorUser
        ? await updateVendorUser(data)
        : await updateVendor(data);
    if (res) {
      setAlert(eulaAcceptedMessage, 'success');
      handleClose();
    }
  };

  const handleDecline = async () => {
    const data = {
      formData: {
        eula_version: user.eula_current_version,
        eula_status: eulaStatuses.declined
      },
      hideAlert: true
    };
    const res =
      user.role === roles.vendorUser
        ? await updateVendorUser(data)
        : await updateVendor(data);
    if (res) {
      setAlert(eulaDeniedMessage, 'warning', 15000);
      handleClose();
    }
  };

  if (user.prohibitedEmail && !prohibitedEmailAccepted) return null;

  if (!eulaStatus) return null;

  return (
    <ModalContainer
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      headerClass={classes.eulaModalHeader}
      header={
        <>
          <div className={classes.logoContainer}>
            <img src={logo} alt="Logo" />
          </div>
          <div className={classes.verticalLine} />
          <p className={classes.eulaModalTitle}>Vendor Portal</p>
        </>
      }
      containerClass={classes.eulaModal}
      bodyClass={classes.eulaModalBody}
    >
      <h2 className={classes.eulaHeader}>End User License Agreement</h2>
      <p className={classes.eulaSubHeader}>
        LATEST UPDATE - {formatFullMonthDate(new Date(user.eula_current_date))}
      </p>
      <iframe
        id="eula-iframe"
        src={user.eula_current_path}
        title="eula-body"
        className={classes.eulaIframe}
      />
      <div className={classes.eulaModalFooter}>
        <FaPrint
          className={`${classes.eulaPrintIcon} print-icon`}
          onClick={() =>
            printComponent({
              id: 'eula-iframe',
              type: 'iframe',
              header: eulaPrintHeader(user)
            })
          }
        />
        <div className={classes.eulaButtonsContainer}>
          <Button
            className={`${classes.eulaButton} ${classes.accept}`}
            onClick={handleAccept}
          >
            ACCEPT
          </Button>
          <Button
            className={`${classes.eulaButton} ${classes.decline}`}
            onClick={handleDecline}
          >
            DECLINE
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  updateVendor,
  updateVendorUser,
  setAlert
})(EulaModal);
