import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';

import {
  SET_LOADING_ADVERTISING,
  GET_ADVERTISING,
  GET_ADV_CALCULATOR,
  SET_ADV_CALCULATOR_LOADING,
  CLEAR_ADVERTISING,
  GET_ADVERTISING_TABLE,
  FILTER_ADVERTISING_TABLE,
  SET_LOADING_ADVERTISING_TABLE,
  GET_ADV_MIN_DATES,
  GET_ADV_KEYWORDS,
  SET_ADV_KEYWORDS_LOADING,
  FILTER_ADV_KEYWORDS,
} from './types';

//Get Advertising Data
export const getAdvertising =
  ({ name, from, to }) =>
  async (dispatch) => {
    setAuthToken(localStorage.token);
    try {
      dispatch({
        type: SET_LOADING_ADVERTISING,
        payload: true,
      });

      const res = await axios.get(
        `/api/mysql/adv?name=${encodeURIComponent(name)}&from=${from}&to=${to}`
      );

      dispatch({
        type: GET_ADVERTISING,
        payload: res.data,
      });
    } catch (err) {
      if (err.response) {
        const errors = err.response.data.errors;
        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
        }
      } else {
        console.log(err);
      }

      dispatch({
        type: GET_ADVERTISING,
        payload: {},
      });
    }
  };

//Get Advertising Table
export const getAdvertisingTable =
  ({ name, from, to }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SET_LOADING_ADVERTISING_TABLE,
      });

      const res = await axios.get(
        `/api/mysql/adv/items?name=${encodeURIComponent(
          name
        )}&from=${from}&to=${to}`
      );

      dispatch({
        type: GET_ADVERTISING_TABLE,
        payload: res.data,
      });
    } catch (err) {
      if (err.response) {
        const errors = err.response.data.errors;
        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
        }
      } else {
        console.log(err);
      }

      dispatch({
        type: GET_ADVERTISING_TABLE,
        payload: [],
      });
    }
  };

//Get Keywords Table
export const getAdvKeywords =
  ({ name, account, from, to }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SET_ADV_KEYWORDS_LOADING,
      });

      const res = await axios.get(
        `/api/mysql/adv/keywords?name=${encodeURIComponent(
          name
        )}&account=${account}&from=${from}&to=${to}`
      );

      dispatch({
        type: GET_ADV_KEYWORDS,
        payload: res.data,
      });
    } catch (err) {
      if (err.response) {
        const errors = err.response.data.errors;
        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
        }
      } else {
        console.log(err);
      }

      dispatch({
        type: GET_ADV_KEYWORDS,
        payload: {},
      });
    }
  };

//Get Advertising Min Dates
export const getAdvMinDates = (name) => async (dispatch) => {
  setAuthToken(localStorage.token);
  try {
    const res = await axios.get(
      `/api/mysql/adv/mindate?name=${encodeURIComponent(name)}`
    );

    dispatch({
      type: GET_ADV_MIN_DATES,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }

    dispatch({
      type: GET_ADV_MIN_DATES,
      payload: {},
    });
  }
};

//Get Advertising Calculator
export const getAdvCalculator = (name) => async (dispatch) => {
  try {
    dispatch({
      type: SET_ADV_CALCULATOR_LOADING,
    });

    const res = await axios.get(
      `/api/mysql/adv/calculator?name=${encodeURIComponent(name)}`
    );

    dispatch({
      type: GET_ADV_CALCULATOR,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }

    dispatch({
      type: GET_ADV_CALCULATOR,
      payload: {},
    });
  }
};

//Filter Advertising table
export const filterAdvTable = (data) => (dispatch) => {
  dispatch({
    type: FILTER_ADVERTISING_TABLE,
    payload: data,
  });
};

//Filter Advertising keywords
export const filterAdvKeywords = (data) => (dispatch) => {
  dispatch({
    type: FILTER_ADV_KEYWORDS,
    payload: data,
  });
};

//Clear Brand
export const clearAdvertising = () => (dispatch) => {
  dispatch({
    type: CLEAR_ADVERTISING,
  });
};
