import {
  GET_USER,
  GET_ALL_USERS,
  GET_ALL_ITEMS,
  GET_ALL_VENDORS,
  ADD_USER_LOADING,
  CLEAR_USER,
  BUTTON_LOADING,
  ITEMS_LOADING,
  BRANDS_LOADING,
  GET_ALL_BRANDS,
  SET_EDIT_USER,
  GET_EDIT_USER,
  UPDATE_USER,
  CREATE_USER,
  DELETE_USER,
  CREATE_VENDOR,
  DELETE_VENDOR,
  GET_USER_PAGE,
  GET_VENDOR_USERS,
  UPDATE_VENDOR_USER,
  DELETE_VENDOR_USER,
  CREATE_VENDOR_USER
} from '../actions/types';

const initialState = {
  responsible: null,
  active: {},
  buyer: null,
  marketing: null,
  users: null,
  vendors: null,
  vendorUsers: [],
  edit_user: {
    data: {}
  },
  items: [],
  items_count: 0,
  brands: null,
  user_brands: [],
  dashboard: null,
  dashIndex: 0,
  loading: false,
  items_loading: false,
  button_loading: false,
  users_loading: true,
  vendors_loading: true,
  vendorUsers_loading: true,
  brands_loading: false,
  active_user_loading: true
};

export default function user(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user._id !== payload),
        button_loading: false
      };
    case DELETE_VENDOR:
      return {
        ...state,
        vendors: state.vendors.filter((vendor) => vendor._id !== payload),
        button_loading: false
      };
    case DELETE_VENDOR_USER:
      return {
        ...state,
        vendorUsers: state.vendorUsers.filter((user) => user._id !== payload)
      };
    case CREATE_USER:
      if (state.users) {
        return {
          ...state,
          users: [...state.users, payload].sort((a, b) => {
            let propA = a.first_name ? a.first_name : '';
            let propB = b.first_name ? b.first_name : '';
            if (isNaN(propA)) {
              propA = propA.toLowerCase();
            }

            if (isNaN(propB)) {
              propB = propB.toLowerCase();
            }

            return propA > propB ? 1 : -1;
          })
        };
      } else {
        return state;
      }
    case CREATE_VENDOR:
      if (state.users) {
        return {
          ...state,
          vendors: [...state.vendors, payload].sort((a, b) => {
            let propA = a.vendorname ? a.vendorname : '';
            let propB = b.vendorname ? b.vendorname : '';
            if (isNaN(propA)) {
              propA = propA.toLowerCase();
            }

            if (isNaN(propB)) {
              propB = propB.toLowerCase();
            }

            return propA > propB ? 1 : -1;
          })
        };
      } else {
        return state;
      }
    case CREATE_VENDOR_USER:
      return {
        ...state,
        vendorUsers: [...state.vendorUsers, payload].sort((a, b) => {
          let propA = a.first_name ? a.first_name : '';
          let propB = b.first_name ? b.first_name : '';
          if (isNaN(propA)) propA = propA.toLowerCase();
          if (isNaN(propB)) propB = propB.toLowerCase();
          return propA > propB ? 1 : -1;
        })
      };
    case UPDATE_VENDOR_USER:
      return {
        ...state,
        vendorUsers: state.vendorUsers.map((user) =>
          user._id === payload._id ? payload : user
        )
      };
    case UPDATE_USER:
      if (payload.type === 'user') {
        return {
          ...state,
          users: state.users
            ? state.users.map((user) => {
                if (user._id === payload.data._id) {
                  return payload.data;
                } else {
                  return user;
                }
              })
            : null
        };
      } else {
        return {
          ...state,
          vendors: state.vendors
            ? state.vendors.map((vendor) => {
                if (vendor._id === payload.data._id) {
                  return payload.data;
                } else {
                  return vendor;
                }
              })
            : null
        };
      }
    case GET_USER:
      return {
        ...state,
        responsible: payload,
        loading: false
      };
    case GET_USER_PAGE:
      return {
        ...state,
        active: payload,
        active_user_loading: false
      };
    case GET_ALL_USERS:
      return {
        ...state,
        users: payload,
        users_loading: false
      };
    case GET_ALL_BRANDS:
      if (payload.hasAll) {
        return {
          ...state,
          user_brands: payload.brands,
          brands_loading: false
        };
      }

      return {
        ...state,
        brands: payload.brands,
        brands_loading: false
      };
    case SET_EDIT_USER:
      return {
        ...state,
        edit_user: {
          ...state.edit_user,
          ...payload
        }
      };
    case GET_EDIT_USER:
      let data = {};
      if (payload.type === 'vendor') {
        state.vendors.forEach((vendor) => {
          if (vendor._id === payload.id) {
            data = vendor;
          }
        });
      } else if (payload.type === 'user') {
        state.users.forEach((user) => {
          if (user._id === payload.id) {
            data = user;
          }
        });
      }
      return {
        ...state,
        edit_user: {
          ...state.edit_user,
          data
        }
      };
    case GET_ALL_ITEMS:
      return {
        ...state,
        ...payload,
        items_loading: false
      };
    case GET_ALL_VENDORS:
      return {
        ...state,
        vendors: payload,
        vendors_loading: false
      };
    case GET_VENDOR_USERS:
      return {
        ...state,
        vendorUsers: payload,
        vendorUsers_loading: false
      };
    case ADD_USER_LOADING:
      return {
        ...state,
        loading: true
      };
    case BUTTON_LOADING:
      return {
        ...state,
        button_loading: payload
      };
    case ITEMS_LOADING:
      return {
        ...state,
        items_loading: payload
      };
    case BRANDS_LOADING:
      return {
        ...state,
        brands_loading: true
      };
    case CLEAR_USER:
      return initialState;

    default:
      return state;
  }
}
