import { Suspense, useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkRecaptcha } from '../../../utils/global.services';
import { checkScore } from '../../../actions/auth';
import { MainLoader } from '../../../UI/Loaders/Loaders';
//import { noRedirectPublicRoutes } from '../../utils/accessPage/accessPage.data';

const PublicRoute = ({
  auth: { isAuthenticated, url },
  checkScore,
  component: Component,
  ...rest
}) => {
  const { search } = useLocation();
  useEffect(() => {
    checkRecaptcha(checkScore);
  }, [checkScore]);

  //Redirect if logged in
  if (isAuthenticated || localStorage.token) {
    if (url) {
      return <Redirect to={url} />;
    }

    return <Redirect to={`/dashboard/${search ? search : ''}`} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={<MainLoader />}>
          <Component {...props} />
        </Suspense>
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, { checkScore })(PublicRoute);
