import axios from 'axios';
import { setAlert } from './alert';
import { setAvatarUrl, updateUserInfo } from './auth';
import setAuthToken from '../utils/setAuthToken';

import {
  GET_USER,
  GET_ALL_USERS,
  GET_ALL_ITEMS,
  GET_ALL_VENDORS,
  ADD_USER_LOADING,
  GET_USER_PAGE,
  CLEAR_USER,
  BUTTON_LOADING,
  ITEMS_LOADING,
  BRANDS_LOADING,
  GET_ALL_BRANDS,
  SET_EDIT_USER,
  GET_EDIT_USER,
  UPDATE_USER,
  CREATE_USER,
  DELETE_USER,
  CREATE_VENDOR,
  DELETE_VENDOR,
  SET_EMAIL_LOADING,
  GET_VENDOR_USERS,
  UPDATE_VENDOR_USER,
  DELETE_VENDOR_USER,
  CREATE_VENDOR_USER
} from './types';
import { actionErrorHandler, convertToBase64 } from '../utils/global.services';

const CancelToken = axios.CancelToken;
let cancelItems = null;

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

//Send Email
export const sendEmail =
  ({ data, hideAlert }) =>
  async (dispatch) => {
    dispatch({
      type: SET_EMAIL_LOADING,
      payload: true
    });

    const encodedFiles = [];

    try {
      //Convert all files to Base64 format
      if (data.files) {
        for (let i = 0; i < data.files.length; i++) {
          const result = await convertToBase64(data.files[i]).catch((e) =>
            Error(e)
          );
          if (result instanceof Error) {
            dispatch(setAlert(result.message, 'error'));
            continue;
          }

          encodedFiles.push({
            name: data.files[i].name,
            content: result.replace(
              data.files[i].type
                ? `data:${data.files[i].type};base64,`
                : 'data:application/octet-stream;base64,',
              ''
            ),
            type: data.files[i].type
          });
        }
        if (encodedFiles.length) data.files = encodedFiles;
      }

      const body = JSON.stringify(data);
      const res = await axios.post('/api/email', body, config);
      if (!hideAlert) dispatch(setAlert(res.data?.message, 'success'));
      dispatch({
        type: SET_EMAIL_LOADING,
        payload: false
      });
      return true;
    } catch (err) {
      dispatch({
        type: SET_EMAIL_LOADING,
        payload: false
      });
      actionErrorHandler(err, dispatch, setAlert);
      return false;
    }
  };

//Get User
export const getUsers = (users) => async (dispatch) => {
  setAuthToken(localStorage.token);
  try {
    dispatch({
      type: ADD_USER_LOADING
    });

    const body = JSON.stringify({ users });
    const res = await axios.post('/api/user/list', body, config);

    dispatch({
      type: GET_USER,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_USER,
      payload: []
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
};

//Get User
export const getUserPage = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/user?id=${id}`);

    dispatch({
      type: GET_USER_PAGE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_USER_PAGE,
      payload: {}
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
};

//Get All Users
export const getAllUsers = () => async (dispatch) => {
  setAuthToken(localStorage.token);
  try {
    const res = await axios.get('/api/user');

    dispatch({
      type: GET_ALL_USERS,
      payload: res.data
    });
  } catch (err) {
    actionErrorHandler(err, dispatch, setAlert);
  }
};

//Get All Items
export const getAllItems = (body) => async (dispatch) => {
  try {
    dispatch({
      type: ITEMS_LOADING,
      payload: true
    });

    if (cancelItems) {
      cancelItems();
      cancelItems = null;
    }

    const config2 = {
      ...config,
      cancelToken: new CancelToken(function executor(c) {
        cancelItems = c;
      })
    };

    const items = await axios.post(
      '/api/mysql/items',
      JSON.stringify(body),
      config2
    );

    dispatch({
      type: GET_ALL_ITEMS,
      payload: items.data
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_ITEMS,
      payload: { items: [], items_count: 0 }
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
};

//Get All Items
export const getAllVendors = () => async (dispatch) => {
  setAuthToken(localStorage.token);
  try {
    const res = await axios.get('/api/vendor');

    dispatch({
      type: GET_ALL_VENDORS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_VENDORS,
      payload: []
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
};

//Get All Brands
export const getAllBrands = (hasAll) => async (dispatch) => {
  setAuthToken(localStorage.token);
  try {
    dispatch({
      type: BRANDS_LOADING
    });

    let brands = await axios.get('/api/mysql/brands');
    brands = brands.data;

    if (brands && hasAll) {
      brands = [{ name: 'All' }, ...brands];
    }

    dispatch({
      type: GET_ALL_BRANDS,
      payload: { hasAll, brands }
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_BRANDS,
      payload: []
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
};

//SET Edit User
export const setEditUser = (data) => (dispatch) => {
  dispatch({
    type: SET_EDIT_USER,
    payload: data
  });
};

//Get Edit User
export const getEditUser = (id, type) => async (dispatch) => {
  try {
    dispatch({
      type: GET_EDIT_USER,
      payload: { id, type }
    });
  } catch (err) {
    actionErrorHandler(err, dispatch, setAlert);
  }
};

//Delete User
export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({
      type: BUTTON_LOADING,
      payload: true
    });

    await axios.delete(`/api/user?id=${id}`);

    dispatch({
      type: DELETE_USER,
      payload: id
    });
    dispatch(setAlert('User successfully removed!', 'success'));
  } catch (err) {
    dispatch({
      type: BUTTON_LOADING,
      payload: false
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
};

//Delete Vendor
export const deleteVendor = (id) => async (dispatch) => {
  try {
    dispatch({
      type: BUTTON_LOADING,
      payload: true
    });

    await axios.delete(`/api/vendor?id=${id}`);

    dispatch({
      type: DELETE_VENDOR,
      payload: id
    });
    dispatch(setAlert('Vendor successfully removed!', 'success'));
  } catch (err) {
    dispatch({
      type: BUTTON_LOADING,
      payload: false
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
};

//Clear Users data
export const clearUsers = () => (dispatch) => {
  dispatch({
    type: CLEAR_USER
  });
};

//Create Vendor
export const createVendor = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  try {
    dispatch({
      type: BUTTON_LOADING,
      payload: true
    });

    const body = JSON.stringify(formData);
    const res = await axios.post('/api/vendor', body, config);
    dispatch({
      type: CREATE_VENDOR,
      payload: res.data
    });
    dispatch(setAlert('Vendor successfully created!', 'success'));
    dispatch({
      type: BUTTON_LOADING,
      payload: false
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: BUTTON_LOADING,
      payload: false
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
  return null;
};

//Update Vendor by Admin
export const updateVendorByAdmin =
  ({ formData, id, hideAlert }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: BUTTON_LOADING,
        payload: true
      });

      const body = JSON.stringify(formData);
      const res = await axios.put(`/api/vendor?id=${id}`, body, config);
      dispatch({
        type: UPDATE_USER,
        payload: { data: res.data, type: 'vendor' }
      });

      if (!hideAlert) {
        dispatch(setAlert('Vendor user successfully updated!', 'success'));
      }

      dispatch({
        type: BUTTON_LOADING,
        payload: false
      });
      return res.data;
    } catch (err) {
      dispatch({
        type: BUTTON_LOADING,
        payload: false
      });
      actionErrorHandler(err, dispatch, setAlert);
    }
    return null;
  };

//Update Vendor by Vendor
export const updateVendor =
  ({ formData, hideAlert }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: BUTTON_LOADING,
        payload: true
      });

      const body = JSON.stringify(formData);
      const res = await axios.put('/api/vendor/userInfo', body, config);

      dispatch(updateUserInfo(res.data));

      if (!hideAlert) {
        dispatch(setAlert('Vendor successfully updated!', 'success'));
      }

      dispatch({
        type: BUTTON_LOADING,
        payload: false
      });
      return res.data;
    } catch (err) {
      dispatch({
        type: BUTTON_LOADING,
        payload: false
      });
      actionErrorHandler(err, dispatch, setAlert);
    }
    return null;
  };

//Create User
export const createUser = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: BUTTON_LOADING,
      payload: true
    });

    const body = JSON.stringify(formData);
    const res = await axios.post('/api/user', body, config);
    dispatch({
      type: CREATE_USER,
      payload: res.data
    });
    dispatch(setAlert('User successfully created!', 'success'));
    dispatch({
      type: BUTTON_LOADING,
      payload: false
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: BUTTON_LOADING,
      payload: false
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
  return null;
};

//Update User
export const updateUserByAdmin = (formData, id) => async (dispatch) => {
  try {
    dispatch({
      type: BUTTON_LOADING,
      payload: true
    });

    const url = id ? `/api/user?id=${id}` : '/api/user';
    const body = JSON.stringify(formData);

    const res = await axios.put(url, body, config);
    dispatch({
      type: UPDATE_USER,
      payload: { data: res.data, type: 'user' }
    });
    dispatch(setAlert('User successfully updated!', 'success'));
    dispatch({
      type: BUTTON_LOADING,
      payload: false
    });

    return res.data;
  } catch (err) {
    dispatch({
      type: BUTTON_LOADING,
      payload: false
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
  return null;
};

//Update User from UserInfo page
export const updateUser =
  ({ formData, hideAlert }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: BUTTON_LOADING,
        payload: true
      });

      const body = JSON.stringify(formData);

      const res = await axios.put('/api/user/userInfo', body, config);

      dispatch(updateUserInfo(res.data));

      if (!hideAlert) {
        dispatch(setAlert('User successfully updated!', 'success'));
      }

      dispatch({
        type: BUTTON_LOADING,
        payload: false
      });

      return res.data;
    } catch (err) {
      dispatch({
        type: BUTTON_LOADING,
        payload: false
      });
      actionErrorHandler(err, dispatch, setAlert);
    }
    return null;
  };

export const sendAvatar = (file, avatar) => async (dispatch) => {
  if (!file) return;
  try {
    dispatch({
      type: BUTTON_LOADING,
      payload: true
    });

    const content = await convertToBase64(file).catch((e) => Error(e));
    const end = file.name.split('.').pop();

    if (content instanceof Error) {
      dispatch(setAlert(content.message, 'error'));
      dispatch({
        type: BUTTON_LOADING,
        payload: false
      });
      return;
    }
    const data = {
      file: { end, content },
      oldPath: avatar
    };

    const res = await axios.post(
      '/api/user/avatar',
      JSON.stringify(data),
      config
    );

    dispatch(setAvatarUrl(res.data));
    dispatch(setAlert('Your file successfully uploaded', 'success'));
    dispatch({
      type: BUTTON_LOADING,
      payload: false
    });
  } catch (err) {
    dispatch({
      type: BUTTON_LOADING,
      payload: false
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
};

//Get All Vendor Users
export const getAllVendorUsers = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/vendorUser');

    dispatch({
      type: GET_VENDOR_USERS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_VENDOR_USERS,
      payload: []
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
};

//Create User
export const createVendorUser = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: BUTTON_LOADING,
      payload: true
    });

    const body = JSON.stringify(formData);
    const res = await axios.post('/api/vendorUser', body, config);
    dispatch({
      type: CREATE_VENDOR_USER,
      payload: res.data
    });
    dispatch(setAlert('Vendor user successfully created!', 'success'));
    dispatch({
      type: BUTTON_LOADING,
      payload: false
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: BUTTON_LOADING,
      payload: false
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
  return null;
};

//Update Vendor User by Vendor/Admin
export const updateVendorUserByVendor =
  ({ formData, id }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: BUTTON_LOADING,
        payload: true
      });

      const body = JSON.stringify(formData);

      const res = await axios.put(`/api/vendorUser?id=${id}`, body, config);
      dispatch({
        type: UPDATE_VENDOR_USER,
        payload: res.data
      });
      dispatch(setAlert('Vendor User successfully updated!', 'success'));
      dispatch({
        type: BUTTON_LOADING,
        payload: false
      });

      return res.data;
    } catch (err) {
      dispatch({
        type: BUTTON_LOADING,
        payload: false
      });
      actionErrorHandler(err, dispatch, setAlert);
    }
    return null;
  };

//Update Vendor User by Vendor/Admin
export const updateVendorUser =
  ({ formData, hideAlert }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: BUTTON_LOADING,
        payload: true
      });

      const body = JSON.stringify(formData);

      const res = await axios.put('/api/vendorUser/userInfo', body, config);

      dispatch(updateUserInfo(res.data));

      if (!hideAlert) {
        dispatch(setAlert('Vendor User successfully updated!', 'success'));
      }

      dispatch({
        type: BUTTON_LOADING,
        payload: false
      });

      return res.data;
    } catch (err) {
      dispatch({
        type: BUTTON_LOADING,
        payload: false
      });
      actionErrorHandler(err, dispatch, setAlert);
    }
    return null;
  };

//Delete Vendor User
export const deleteVendorUser = (id) => async (dispatch) => {
  try {
    dispatch({
      type: BUTTON_LOADING,
      payload: true
    });

    await axios.delete(`/api/vendorUser?id=${id}`);

    dispatch({
      type: DELETE_VENDOR_USER,
      payload: id
    });
    dispatch(setAlert('Vendor user successfully removed!', 'success'));
    dispatch({
      type: BUTTON_LOADING,
      payload: false
    });
    return true;
  } catch (err) {
    dispatch({
      type: BUTTON_LOADING,
      payload: false
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
  return false;
};
