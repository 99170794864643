import { useEffect } from 'react';
import { useLocation, Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadUser } from '../../actions/auth';
import classes from './Page404.module.css';
import { BiError } from 'react-icons/bi';

const Page404 = ({
  auth: { isAuthenticated, auth_loading, user },
  loadUser
}) => {
  const location = useLocation().pathname;

  useEffect(() => {
    if (!user.email) {
      loadUser();
    }
  }, [loadUser, user.email]);

  if (!auth_loading) {
    if (!isAuthenticated) {
      return <Redirect to="/" />;
    } else {
      return (
        <div className={classes.notFoundBox}>
          <div className={classes.notFoundHeader}>
            <BiError className={classes.notFoundIcon} />
            <h1>
              Sorry, we couldn't find{' '}
              <span className={classes.notFoundUrl}>{location}</span> page
            </h1>
          </div>
          <Link to="/dashboard/" className={classes.notFoundBtn}>
            Dashboard
          </Link>
        </div>
      );
    }
  } else {
    return <div></div>;
  }
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, { loadUser })(Page404);
