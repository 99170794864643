import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  ADD_LOADING,
  LOAD_USER,
  SET_URL,
  SET_AVATAR_URL,
  REMOVE_AVATAR_URL,
  SET_EMAIL_LOADING,
  GET_CAPTCHA_SCORE,
  CHECK_RESET_TOKEN,
  START_TRACKING,
  CHANGE_EMAIL,
  SET_CHANGE_EMAIL_LOADING,
  SET_CHANGE_PASSWORD_LOADING,
  UPDATE_USER_INFO,
  PROHIBITED_EMAIL_ACCEPTED
} from '../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  loading: false,
  auth_loading: true,
  user: {},
  prohibitedEmailAccepted: false,
  hasAdBlocker: false,
  score: 1,
  url: null,
  url_id: null,
  emailLoading: false,
  resetUser: null,
  resetTokenLoading: true,
  tracking: false,
  changeEmailLoading: false,
  changePasswordLoading: false
};

export default function auth(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
        auth_loading: false
      };
    case SET_AVATAR_URL:
      return {
        ...state,
        user: {
          ...state.user,
          avatar: payload
        }
      };
    case REMOVE_AVATAR_URL:
      return {
        ...state,
        user: {
          ...state.user,
          avatar: ''
        }
      };
    case ADD_LOADING:
      return {
        ...state,
        loading: true,
        auth_loading: true
      };
    case LOAD_USER:
      return {
        ...state,
        user: payload.user,
        hasAdBlocker: payload.hasAdBlocker,
        isAuthenticated: true,
        loading: false,
        auth_loading: false
      };
    case UPDATE_USER_INFO:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload
        }
      };
    case SET_URL:
      return {
        ...state,
        url: payload
      };
    case SET_EMAIL_LOADING:
      return {
        ...state,
        emailLoading: payload
      };
    case GET_CAPTCHA_SCORE:
      return {
        ...state,
        score: payload
      };
    case CHECK_RESET_TOKEN:
      return {
        ...state,
        resetUser: payload,
        resetTokenLoading: false
      };
    case LOGIN_FAIL:
      localStorage.removeItem('token');
      return {
        ...initialState,
        token: null,
        auth_loading: false
      };
    case START_TRACKING:
      return {
        ...state,
        tracking: true
      };
    case CHANGE_EMAIL:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload
        },
        changeEmailLoading: false
      };
    case SET_CHANGE_EMAIL_LOADING:
      return {
        ...state,
        changeEmailLoading: payload
      };
    case SET_CHANGE_PASSWORD_LOADING:
      return {
        ...state,
        changePasswordLoading: payload
      };
    case PROHIBITED_EMAIL_ACCEPTED:
      return {
        ...state,
        prohibitedEmailAccepted: true
      };
    case LOGOUT:
      localStorage.removeItem('token');
      return {
        ...initialState,
        token: null
      };

    default:
      return state;
  }
}
