import axios from 'axios';
//import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';

import {
  GET_DASHBOARD_LQS,
  SET_DASHBOARD_LOADING_LQS,
  GET_DASHBOARD_SELLERS,
  SET_DASHBOARD_LOADING_SELLERS,
  GET_DASHBOARD_ADV,
  SET_DASHBOARD_LOADING_ADV,
  GET_DASHBOARD_SALES,
  SET_DASHBOARD_LOADING_SALES,
  GET_LQS_SPEED,
  GET_ADV_SPEED,
  GET_SALES_SPEED,
  GET_SELLERS_SPEED,
  CLEAR_DASHBOARD,
  CHECK_DASHBOARD,
  GET_DASHBOARD_SQS,
} from './types';

//Dashboard get LQS
export const getDashLQS = (name) => async (dispatch) => {
  setAuthToken(localStorage.token);
  try {
    dispatch({
      type: SET_DASHBOARD_LOADING_LQS,
      payload: true,
    });

    const res = await axios.get(
      `/api/mysql/dashboard/lqs?name=${encodeURIComponent(name)}`
    );

    dispatch({
      type: GET_DASHBOARD_LQS,
      payload: res.data,
    });
  } catch (err) {
    // if (err.response) {
    //   const errors = err.response.data.errors;
    //   if (errors) {
    //     errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    //   }
    // } else {
    //   console.log(err);
    // }
    console.log(err);
    dispatch({
      type: GET_DASHBOARD_LQS,
      payload: { name },
    });
  }
};

//Dashboard get Sellers
export const getDashSellers = (name) => async (dispatch) => {
  setAuthToken(localStorage.token);
  try {
    dispatch({
      type: SET_DASHBOARD_LOADING_SELLERS,
      payload: true,
    });

    const res = await axios.get(
      `/api/mysql/dashboard/sellers?name=${encodeURIComponent(name)}`
    );

    dispatch({
      type: GET_DASHBOARD_SELLERS,
      payload: res.data,
    });
  } catch (err) {
    // if (err.response) {
    //   const errors = err.response.data.errors;
    //   if (errors) {
    //     errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    //   }
    // } else {
    //   console.log(err);
    // }
    console.log(err);
    dispatch({
      type: GET_DASHBOARD_SELLERS,
      payload: { name },
    });
  }
};

//Dashboard get Advertising
export const getDashAdv = (name) => async (dispatch) => {
  setAuthToken(localStorage.token);
  try {
    dispatch({
      type: SET_DASHBOARD_LOADING_ADV,
    });

    const res = await axios.get(
      `/api/mysql/dashboard/adv?name=${encodeURIComponent(name)}`
    );

    dispatch({
      type: GET_DASHBOARD_ADV,
      payload: res.data,
    });
  } catch (err) {
    // if (err.response) {
    //   const errors = err.response.data.errors;
    //   if (errors) {
    //     errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    //   }
    // } else {
    //   console.log(err);
    // }
    console.log(err);
    dispatch({
      type: GET_DASHBOARD_ADV,
      payload: { name },
    });
  }
};

//Dashboard get Sales
export const getDashSales = (name) => async (dispatch) => {
  setAuthToken(localStorage.token);
  try {
    dispatch({
      type: SET_DASHBOARD_LOADING_SALES,
      payload: true,
    });

    const res = await axios.get(
      `/api/mysql/dashboard/sales?name=${encodeURIComponent(name)}`
    );

    dispatch({
      type: GET_DASHBOARD_SALES,
      payload: res.data,
    });
  } catch (err) {
    // if (err.response) {
    //   const errors = err.response.data.errors;
    //   if (errors) {
    //     errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    //   }
    // } else {
    //   console.log(err);
    // }
    console.log(err);
    dispatch({
      type: GET_DASHBOARD_SALES,
      payload: { name },
    });
  }
};

//Dashboard get Amazon SQS
export const getDashAmzSQS = (name) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/mysql/dashboard/amazonsqs?name=${encodeURIComponent(name)}`
    );

    dispatch({
      type: GET_DASHBOARD_SQS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_DASHBOARD_SQS,
      payload: {},
    });
  }
};

//Get LQS speed
export const getDashSpeedLQS = (name) => async (dispatch) => {
  setAuthToken(localStorage.token);
  try {
    const res = await axios.get(
      `/api/mysql/dashboard/speed/lqs?name=${encodeURIComponent(name)}`
    );

    dispatch({
      type: GET_LQS_SPEED,
      payload: res.data,
    });
  } catch (err) {
    // if (err.response) {
    //   const errors = err.response.data.errors;
    //   if (errors) {
    //     errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    //   }
    // } else {
    //   console.log(err);
    // }
    console.log(err);
    dispatch({
      type: GET_LQS_SPEED,
      payload: 0,
    });
  }
};

//Get Advertising speed
export const getDashSpeedAdv = (name) => async (dispatch) => {
  setAuthToken(localStorage.token);
  try {
    const res = await axios.get(
      `/api/mysql/dashboard/speed/adv?name=${encodeURIComponent(name)}`
    );

    dispatch({
      type: GET_ADV_SPEED,
      payload: res.data,
    });
  } catch (err) {
    // if (err.response) {
    //   const errors = err.response.data.errors;
    //   if (errors) {
    //     errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    //   }
    // } else {
    //   console.log(err);
    // }
    console.log(err);
    dispatch({
      type: GET_ADV_SPEED,
      payload: 0,
    });
  }
};

//Get Sellers speed
export const getDashSpeedSellers = (name) => async (dispatch) => {
  setAuthToken(localStorage.token);
  try {
    const res = await axios.get(
      `/api/mysql/dashboard/speed/sellers?name=${encodeURIComponent(name)}`
    );

    dispatch({
      type: GET_SELLERS_SPEED,
      payload: res.data,
    });
  } catch (err) {
    // if (err.response) {
    //   const errors = err.response.data.errors;
    //   if (errors) {
    //     errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    //   }
    // } else {
    //   console.log(err);
    // }
    console.log(err);
    dispatch({
      type: GET_SELLERS_SPEED,
      payload: 0,
    });
  }
};

//Get Sales speed
export const getDashSpeedSales = (name) => async (dispatch) => {
  setAuthToken(localStorage.token);
  try {
    const res = await axios.get(
      `/api/mysql/dashboard/speed/sales?name=${encodeURIComponent(name)}`
    );

    dispatch({
      type: GET_SALES_SPEED,
      payload: res.data,
    });
  } catch (err) {
    // if (err.response) {
    //   const errors = err.response.data.errors;
    //   if (errors) {
    //     errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    //   }
    // } else {
    //   console.log(err);
    // }
    console.log(err);
    dispatch({
      type: GET_SALES_SPEED,
      payload: 0,
    });
  }
};

//Check Vendor Dashboard
export const checkDashboard = (name) => async (dispatch) => {
  setAuthToken(localStorage.token);
  try {
    const res = await axios.get(
      `/api/mysql/dashboard/check?name=${encodeURIComponent(name)}`
    );

    dispatch({
      type: CHECK_DASHBOARD,
      payload: res.data,
    });
  } catch (err) {
    // if (err.response) {
    //   const errors = err.response.data.errors;
    //   if (errors) {
    //     errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    //   }
    // } else {
    //   console.log(err);
    // }
    console.log(err);
    dispatch({
      type: CHECK_DASHBOARD,
      payload: 0,
    });
  }
};

//Clear Dashboard
export const clearDashboard = () => (dispatch) => {
  dispatch({
    type: CLEAR_DASHBOARD,
  });
};
