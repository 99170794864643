export const getTimezoneHours = (timezone) => {
  try {
    const today = new Date();
    const todayUTC = new Date(
      today.toLocaleString('en-US', { timeZone: 'UTC' })
    );
    const todayTimezone = new Date(
      today.toLocaleString('en-US', { timeZone: timezone })
    );
    return Math.round(
      (todayUTC.getTime() - todayTimezone.getTime()) / (-60 * 60000)
    );
  } catch (error) {
    console.log(error);
  }
  return 0;
};

const getTimezoneLabel = ({ timezone, label }) => {
  const hours = getTimezoneHours(timezone);
  return `(UTC${hours ? (hours > 0 ? '+' : '-') : ''}${
    hours ? `${('0' + String(Math.abs(hours))).slice(-2)}` : ''
  }) ${label}`;
};

export const timezones = [
  {
    label: getTimezoneLabel({
      timezone: 'America/Los_Angeles',
      label: 'US/Pacific Time'
    }),
    value: 'America/Los_Angeles'
  },
  {
    label: getTimezoneLabel({
      timezone: 'America/Denver',
      label: 'US/Mountain Time'
    }),
    value: 'America/Denver'
  },
  {
    label: getTimezoneLabel({
      timezone: 'America/Chicago',
      label: 'US/Central Time'
    }),
    value: 'America/Chicago'
  },
  {
    label: getTimezoneLabel({ timezone: 'UTC', label: 'UTC' }),
    value: 'UTC'
  },
  {
    label: getTimezoneLabel({
      timezone: 'Europe/London',
      label: 'Europe/London'
    }),
    value: 'Europe/London'
  },
  {
    label: getTimezoneLabel({
      timezone: 'Europe/Warsaw',
      label: 'Europe/Warsaw'
    }),
    value: 'Europe/Warsaw'
  },
  {
    label: getTimezoneLabel({
      timezone: 'Europe/Vilnius',
      label: 'Europe/Vilnius'
    }),
    value: 'Europe/Vilnius'
  },
  {
    label: getTimezoneLabel({
      timezone: 'Europe/Minsk',
      label: 'Europe/Minsk'
    }),
    value: 'Europe/Minsk'
  }
];

export const recalcTime = (date, timezone) => {
  if (!date) return '';
  const options = { hourCycle: 'h23' };
  if (timezone) {
    options.timeZone = timezone;
  }
  try {
    const current = new Date(date);
    if (isNaN(current.getTime())) return date;
    return current.toLocaleString('en-US', options);
  } catch (error) {
    console.log(error);
  }
  return date;
};
