import axios from 'axios';
import { setAlert } from './alert';

import {
  GET_PRICES_LQS,
  OPEN_PRICES_LQS_TABLE,
  CLEAR_PRICES,
  GET_PRICES_AMZ,
} from './types';

//Get Pricing Data
export const getPricesLQS = (name) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/mysql/prices?name=${encodeURIComponent(name)}`
    );

    dispatch({
      type: GET_PRICES_LQS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }

    dispatch({
      type: GET_PRICES_LQS,
      payload: {},
    });
  }
};

//Get Pricing Data
export const getPricesAmz = (name) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/mysql/prices/amz?name=${encodeURIComponent(name)}`
    );

    dispatch({
      type: GET_PRICES_AMZ,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }

    dispatch({
      type: GET_PRICES_AMZ,
      payload: {},
    });
  }
};

//Open Prices LQS table
export const openPricesLQSTable = (isOpen) => async (dispatch) => {
  dispatch({
    type: OPEN_PRICES_LQS_TABLE,
    payload: isOpen,
  });
};

//Clear PRices
export const clearPrices = () => (dispatch) => {
  dispatch({
    type: CLEAR_PRICES,
  });
};
