import {
  GET_ALL_LOGS,
  SET_LOADING_LOGS,
  FILTER_VENDOR_LOGS,
  FILTER_USER_LOGS,
  CLEAR_LOGS,
  GET_STATISTICS_DAU,
  GET_ALL_EULA,
  SET_LOADING_ADD_EULA,
  ADD_EULA,
  DELETE_EULA
} from '../actions/types';

const initialState = {
  logs: {
    users_logs: [],
    vendors_logs: [],
    error_logs: []
  },
  filtered_logs: {
    users_logs: [],
    vendors_logs: [],
    error_logs: []
  },
  logs_qty: {
    users: 0,
    vendors: 0
  },
  eula: [],
  statistics_dau: {},
  users: [],
  vendors: [],
  getLogs: false,
  loading: false,
  loadingDau: true,
  loadingEula: true,
  loadingAddEula: false
};

export default function item(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_LOGS:
      return {
        ...state,
        ...payload,
        logs_qty: {
          users: payload.users_logs ? payload.users_logs.length : 0,
          vendors: payload.vendors_logs ? payload.vendors_logs.length : 0
        },
        loading: false,
        getLogs: true
      };
    case SET_LOADING_LOGS:
      return {
        ...state,
        loading: true
      };
    case FILTER_VENDOR_LOGS:
      let filteredVendors = [...state.logs.vendors_logs];
      if (payload?.users?.length) {
        filteredVendors = filteredVendors.filter((log) =>
          payload.users.includes(log.email)
        );
      }

      //filter by Page
      return {
        ...state,
        filtered_logs: {
          ...state.filtered_logs,
          vendors_logs: filteredVendors.filter((item, index) => {
            const { page, qty } = payload;
            return index < page * qty && index >= (page - 1) * qty;
          })
        },
        logs_qty: {
          ...state.logs_qty,
          vendors: filteredVendors.length
        }
      };
    case FILTER_USER_LOGS:
      let filteredUsers = [...state.logs.users_logs];
      if (payload?.users?.length) {
        filteredUsers = filteredUsers.filter((log) =>
          payload.users.includes(log.email)
        );
      }

      //filter by Page
      return {
        ...state,
        filtered_logs: {
          ...state.filtered_logs,
          users_logs: filteredUsers.filter((item, index) => {
            const { page, qty } = payload;
            return index < page * qty && index >= (page - 1) * qty;
          })
        },
        logs_qty: {
          ...state.logs_qty,
          users: filteredUsers.length
        }
      };
    case GET_STATISTICS_DAU:
      return {
        ...state,
        statistics_dau: payload,
        loadingDau: false
      };
    case GET_ALL_EULA:
      return {
        ...state,
        eula: payload,
        loadingEula: false
      };
    case SET_LOADING_ADD_EULA:
      return {
        ...state,
        loadingAddEula: payload
      };
    case ADD_EULA:
      return {
        ...state,
        eula: [payload, ...state.eula],
        loadingAddEula: false
      };
    case DELETE_EULA:
      return {
        ...state,
        eula: state.eula.filter((eula) => eula._id !== payload)
      };
    case CLEAR_LOGS:
      return initialState;

    default:
      return state;
  }
}
