import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';

import {
  SET_LOADING_SELLERS,
  CLEAR_SELLERS,
  FILTER_OFFERS,
  GET_MAP_CHARTS,
  GET_MAP_TABLE,
  SET_LOADING_TABLE,
  SET_OFFERS_DATE,
  SET_SELLERS_DATE,
  GET_SELLERS_DATA,
  GET_SELLERS_REPORT,
  SET_SELLERS_REPORT_LOADING,
  GET_SELLERS_CHARTS,
  SET_SELLERS_CHARTS_LOADING,
  GET_AVAILABLE_REPORT_DATES,
  HIDE_PRODUCTS_CHART_DATA,
  HIDE_SELLERS_TABLE_DATA,
  HIDE_OFFERS_CHART_DATA,
  HIDE_SELLERS_TABLE_USED,
  HIDE_SELLERS_CHART_DATA,
  GET_SELLERS_MODAL
} from './types';
import { actionErrorHandler } from '../utils/global.services';

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

//Set Offers Date
export const setOffersDate = (data) => (dispatch) => {
  dispatch({
    type: SET_OFFERS_DATE,
    payload: data
  });
};

// Hide/Show products chart data
export const hideProductsChart = (props) => (dispatch) => {
  dispatch({
    type: HIDE_PRODUCTS_CHART_DATA,
    payload: props
  });
};

// Hide/Show offers chart data
export const hideOffersChart = (props) => (dispatch) => {
  dispatch({
    type: HIDE_OFFERS_CHART_DATA,
    payload: props
  });
};

// Hide/Show sellers chart data
export const hideSellersChart = (props) => (dispatch) => {
  dispatch({
    type: HIDE_SELLERS_CHART_DATA,
    payload: props
  });
};

// Hide/Show used offers for sellers table
export const hideSellersTableUsed = (isActive) => (dispatch) => {
  dispatch({
    type: HIDE_SELLERS_TABLE_USED,
    payload: isActive
  });
};

// Hide/Show sellers at the Sellers table
export const hideSellersTable = (props) => (dispatch) => {
  dispatch({
    type: HIDE_SELLERS_TABLE_DATA,
    payload: props
  });
};

// Hide/Show sellers modal window
export const getSellersModal = (props) => (dispatch) => {
  dispatch({
    type: GET_SELLERS_MODAL,
    payload: props
  });
};

//Set Sellers Date
export const setSellersDate = (data) => (dispatch) => {
  dispatch({
    type: SET_SELLERS_DATE,
    payload: data
  });
};

//Get Data for Sellers report page
export const getSellers =
  ({ name, from, to }) =>
  async (dispatch) => {
    setAuthToken(localStorage.token);
    try {
      dispatch({
        type: SET_LOADING_SELLERS,
        payload: true
      });

      const res = await axios.get(
        `/api/mysql/sellers?name=${encodeURIComponent(
          name
        )}&from=${from}&to=${to}`
      );

      dispatch({
        type: GET_SELLERS_DATA,
        payload: res.data
      });
    } catch (err) {
      if (err.response) {
        const errors = err.response.data.errors;
        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
        }
      } else {
        console.log(err);
      }
      dispatch({
        type: GET_SELLERS_DATA,
        payload: {}
      });
    }
  };

//Get Data for Sellers charts for tab
export const getSellersCharts =
  ({ name, from, to }) =>
  async (dispatch) => {
    setAuthToken(localStorage.token);
    try {
      dispatch({
        type: SET_SELLERS_CHARTS_LOADING
      });

      const res = await axios.get(
        `/api/mysql/sellers/charts?name=${encodeURIComponent(
          name
        )}&from=${from}&to=${to}`
      );

      dispatch({
        type: GET_SELLERS_CHARTS,
        payload: res.data
      });
    } catch (err) {
      if (err.response) {
        const errors = err.response.data.errors;
        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
        }
      } else {
        console.log(err);
      }
      dispatch({
        type: GET_SELLERS_CHARTS,
        payload: {}
      });
    }
  };

//Get Sellers Charts
export const getMapCharts =
  ({ name, from, to }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SET_LOADING_SELLERS,
        payload: true
      });

      const res = await axios.get(
        `/api/mysql/mapcharts?name=${encodeURIComponent(
          name
        )}&from=${from}&to=${to}`
      );

      dispatch({
        type: GET_MAP_CHARTS,
        payload: res.data
      });
    } catch (err) {
      if (err.response) {
        const errors = err.response.data.errors;
        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
        }
      } else {
        console.log(err);
      }
      dispatch({
        type: SET_LOADING_SELLERS,
        payload: false
      });
    }
  };

//Clear Brand
export const clearSellers = () => (dispatch) => {
  dispatch({
    type: CLEAR_SELLERS
  });
};

//Filter Offers Table
export const filterOffers = (data) => (dispatch) => {
  dispatch({
    type: FILTER_OFFERS,
    payload: data
  });
};

//Get Sellers or Offers Table
export const getMapTable = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TABLE,
      payload: { type: data.type, value: true }
    });

    const body = JSON.stringify(data);
    const res = await axios.post('/api/mysql/maptable', body, config);

    dispatch({
      type: GET_MAP_TABLE,
      payload: { data: res.data, type: data.type }
    });
    if (data.props) {
      dispatch(hideSellersTable(data.props));
    }
  } catch (err) {
    dispatch({
      type: SET_LOADING_TABLE,
      payload: { type: data.type, value: false }
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
};

//Get Sellers or Offers Table
export const getSellersReport = (args) => async (dispatch) => {
  setAuthToken(localStorage.token);
  try {
    dispatch({
      type: SET_SELLERS_REPORT_LOADING,
      payload: true
    });

    let body = JSON.stringify(args);
    const res = await axios.post('/api/mysql/reports/sellers', body, config);

    dispatch({
      type: GET_SELLERS_REPORT,
      payload: res.data
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }
    dispatch({
      type: GET_SELLERS_REPORT,
      payload: {}
    });
  }
};

//Get Available dates for sellers report
export const getAvailableDates = (data) => async (dispatch) => {
  const { name, from, to } = data;
  setAuthToken(localStorage.token);
  try {
    const res = await axios.get(
      `/api/mysql/reports/sellers?name=${encodeURIComponent(
        name
      )}&from=${from}&to=${to}`
    );

    dispatch({
      type: GET_AVAILABLE_REPORT_DATES,
      payload: res.data
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }

    dispatch({
      type: GET_AVAILABLE_REPORT_DATES,
      payload: []
    });
  }
};
