import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';
import {
  actionErrorHandler,
  isAbotUser,
  saveBrand
} from '../utils/global.services';
import { sendLoginAction } from '../analitics/Analytics.service';
import { roles } from '../utils/accessPage/accessPage.data';
import { clearAllData } from './service/auth.service';
import { confirmEmailTab } from './confirmation';
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  ADD_LOADING,
  LOAD_USER,
  SET_URL,
  SET_AVATAR_URL,
  REMOVE_AVATAR_URL,
  SET_EMAIL_LOADING,
  GET_CAPTCHA_SCORE,
  CHECK_RESET_TOKEN,
  START_TRACKING,
  CHANGE_EMAIL,
  SET_CHANGE_EMAIL_LOADING,
  SET_CHANGE_PASSWORD_LOADING,
  UPDATE_USER_INFO,
  PROHIBITED_EMAIL_ACCEPTED
} from './types';
import { storageEvents } from '../providers/EventHandler/EventHandler.data';

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

//Login User
export const login = (email, password) => async (dispatch) => {
  const body = JSON.stringify({ email, password });
  try {
    dispatch({
      type: ADD_LOADING
    });

    const res = await axios.post('/api/auth', body, config);

    //Check Ad Blocker
    if (res.data?.user?.role !== roles.help) {
      try {
        await axios.get('/api/analytics');
      } catch {
        res.data.hasAdBlocker = true;
      }
    }

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });
    setAuthToken(localStorage.token);
    if (res.data.user) {
      if (res.data.user.role !== roles.help) {
        sendLoginAction(res.data.user);
      }
    }
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
      dispatch({
        type: LOGIN_FAIL
      });
    } else {
      console.log(err);
    }
  }
};

//Load User
export const loadUser = () => async (dispatch) => {
  setAuthToken(localStorage.token);
  try {
    dispatch({
      type: ADD_LOADING
    });

    const res = await axios.get('/api/auth');

    //Check Ad Blocker
    let hasAdBlocker = false;
    if (res.data?.role !== roles.help) {
      try {
        await axios.get('/api/analytics');
      } catch {
        hasAdBlocker = true;
      }
    }

    dispatch({
      type: LOAD_USER,
      payload: { user: res.data, hasAdBlocker }
    });
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL
    });
  }
};

//Log Out / Clear
export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT
  });
  clearAllData(dispatch);
  saveBrand();
};

//Set direct URL
export const setURL = (url) => (dispatch) => {
  dispatch({
    type: SET_URL,
    payload: url
  });
};

export const setAvatarUrl = (path) => (dispatch) => {
  dispatch({
    type: SET_AVATAR_URL,
    payload: path
  });
};

export const removeAvatarUrl = (path) => async (dispatch) => {
  // const body = JSON.stringify({ oldPath: path });
  try {
    await axios.delete('/api/user/avatar', { data: { oldPath: path } });

    dispatch({
      type: REMOVE_AVATAR_URL
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }
  }
};

//Load User
export const getUserID = async () => {
  setAuthToken(localStorage.token);
  try {
    const res = await axios.get('/api/auth/id');

    return res.data;
  } catch (err) {
    console.log(err);
    return null;
  }
};

//Send Email Request
export const sendEmailRequest = (data) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  dispatch({
    type: SET_EMAIL_LOADING,
    payload: true
  });

  try {
    const body = JSON.stringify(data);
    await axios.post('/api/email/request', body, config);
    dispatch({
      type: SET_EMAIL_LOADING,
      payload: false
    });
    return true;
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => console.log(error));
      }
    } else {
      console.log(err);
    }
  }
  dispatch({
    type: SET_EMAIL_LOADING,
    payload: false
  });
  return false;
};

//Check reCaptcha score
export const checkScore = (token) => async (dispatch) => {
  try {
    const body = JSON.stringify({ token });
    const res = await axios.post('/api/auth/score', body, config);

    dispatch({ type: GET_CAPTCHA_SCORE, payload: res.data });

    if (isAbotUser(res.data))
      dispatch(
        setAlert('High risk activity detected. Try again later', 'error', 10000)
      );
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }
    dispatch({ type: GET_CAPTCHA_SCORE, payload: 1 });
  }
};

//Check Reset Password Token
export const checkResetToken = (token) => async (dispatch) => {
  try {
    const res = await axios.get('/api/auth/resetpwd', {
      headers: {
        'x-auth-token': token
      }
    });

    dispatch({
      type: CHECK_RESET_TOKEN,
      payload: res.data
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }
    dispatch({
      type: CHECK_RESET_TOKEN,
      payload: null
    });
  }
};

export const sendRecoveryToken = (email) => async (dispatch) => {
  const body = JSON.stringify({ email });
  try {
    dispatch({
      type: SET_EMAIL_LOADING,
      payload: true
    });

    await axios.post('/api/auth/resetpwd', body, config);

    dispatch({
      type: SET_EMAIL_LOADING,
      payload: false
    });
    return true;
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    }
  }

  dispatch({
    type: SET_EMAIL_LOADING,
    payload: false
  });
  return false;
};

export const changePassword = (password, token) => async (dispatch) => {
  const body = JSON.stringify({ password });
  try {
    dispatch({
      type: SET_EMAIL_LOADING,
      payload: true
    });

    await axios.put('/api/auth/user', body, {
      headers: { 'x-auth-token': token, 'Content-Type': 'application/json' }
    });

    dispatch({
      type: SET_EMAIL_LOADING,
      payload: false
    });
    return true;
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    }
  }

  dispatch({
    type: SET_EMAIL_LOADING,
    payload: false
  });
  return false;
};

export const startTracking = () => (dispatch) => {
  dispatch({
    type: START_TRACKING
  });
};

export const changeEmail = (data) => async (dispatch) => {
  const body = JSON.stringify(data);
  try {
    dispatch({
      type: SET_CHANGE_EMAIL_LOADING,
      payload: true
    });

    await axios.put(`/api/auth/email`, body, config);

    if (localStorage.getItem(storageEvents.EMAIL_CONFIRMED)) {
      localStorage.removeItem(storageEvents.EMAIL_CONFIRMED);
    }

    localStorage.setItem(storageEvents.EMAIL_CONFIRMED, JSON.stringify(false));
    dispatch(confirmEmailTab(false));
    dispatch({
      type: CHANGE_EMAIL,
      payload: {
        email: data.email,
        newsletter: data.newsletter,
        emailConfirmed: false
      }
    });
    return true;
  } catch (err) {
    dispatch({
      type: SET_CHANGE_EMAIL_LOADING,
      payload: false
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
  return false;
};

export const changePasswordByRole = (data) => async (dispatch) => {
  const body = JSON.stringify(data);
  try {
    dispatch({
      type: SET_CHANGE_PASSWORD_LOADING,
      payload: true
    });

    await axios.put(`/api/auth/password`, body, config);

    dispatch({
      type: SET_CHANGE_PASSWORD_LOADING,
      payload: false
    });
    return true;
  } catch (err) {
    dispatch({
      type: SET_CHANGE_PASSWORD_LOADING,
      payload: false
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
  return false;
};

export const setProhibitedEmailAccepted = () => (dispatch) => {
  dispatch({
    type: PROHIBITED_EMAIL_ACCEPTED
  });
};

//Log Out / Clear
export const updateUserInfo = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_USER_INFO,
    payload: data
  });
};
