import { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { ModalContainer } from '../../UI/ModalContainer/ModalContainer';
import { Checkbox, CheckboxTypes } from '../../UI/Checkbox/Checkbox';
import { Button, buttonTypes } from '../../UI/Button/Button';
import { setAlert } from '../../actions/alert';
import { changeEmail } from '../../actions/auth';
import classes from './ChangeEmailModal.module.css';
import emailIcon from '../../img/email_icon_main.png';
import { Input, InputTypes } from '../../UI/Input/Input';
import { checkIfAdminSupport } from '../../utils/accessPage/accessPage.service';

const ChangeEmailModal = ({
  auth: { user, changeEmailLoading },
  isOpen,
  setIsOpen,
  setAlert,
  changeEmail
}) => {
  const initialState = useMemo(
    () => ({
      email: '',
      newsletter: true,
      password: ''
    }),
    []
  );
  const [formData, setFormData] = useState(initialState);

  const changeNewsletter = () => {
    setFormData({ ...formData, newsletter: !formData.newsletter });
  };
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onClick = async (e) => {
    e.preventDefault();
    const data = { ...formData };
    if (checkIfAdminSupport(user?.role)) {
      delete data.newsletter;
    }
    const res = await changeEmail(data);
    if (res) {
      setAlert('Email successfully changed', 'success');
      setIsOpen(false);
      setFormData(initialState);
    }
  };

  const closeModal = (value) => {
    setIsOpen(value);
    if (!value) setFormData(initialState);
  };

  return (
    <ModalContainer
      header={
        <div className={classes.modalHeader}>
          <img className={classes.emailIcon} src={emailIcon} alt="Email Icon" />
          <span>Change your email address</span>
        </div>
      }
      isOpen={isOpen}
      setIsOpen={closeModal}
      containerClass={classes.modalContainer}
      bodyClass={classes.modalBody}
      headerClass={classes.modalHeaderContainer}
    >
      <div className={classes.bodyBox}>
        <form className={classes.bodyBoxContent}>
          <div className={classes.formRow}>
            <label className={classes.formRowLabel}>Email</label>
            <Input
              className={classes.modalInput}
              inputType={InputTypes.LABELOUTLINE}
              value={user.email}
              disabled
            />
          </div>
          <div className={classes.formRow}>
            <label className={classes.formRowLabel}>New Email</label>
            <Input
              className={classes.modalInput}
              inputType={InputTypes.LABELOUTLINE}
              name="email"
              value={formData.email}
              onChange={onChange}
              data-lpignore="true"
            />
          </div>
          <div className={classes.formRow}>
            <label className={classes.formRowLabel}>Password</label>
            <Input
              className={classes.modalInput}
              inputType={InputTypes.LABELOUTLINE}
              name="password"
              type="password"
              value={formData.password}
              onChange={onChange}
              data-lpignore="true"
            />
          </div>
          {!checkIfAdminSupport(user?.role) && (
            <div className={classes.subscription}>
              <Checkbox
                size="22px"
                active={formData.newsletter}
                onChange={changeNewsletter}
                checkboxType={CheckboxTypes.MAIN_GREEN}
              />
              <span>Subscribe to WebyCorp Newsletter</span>
            </div>
          )}
          <Button
            className={classes.modalButton}
            buttonType={buttonTypes.SECONDARY}
            onClick={onClick}
            disabled={changeEmailLoading}
            loading={changeEmailLoading}
          >
            Change Email Address
          </Button>
        </form>
      </div>
    </ModalContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth
});
export default connect(mapStateToProps, { setAlert, changeEmail })(
  ChangeEmailModal
);
