import {
  GET_SALES_DATA,
  GET_SALES_TABLE,
  SET_LOADING_SALES_TABLE,
  CLEAR_SALES,
  GET_SALES_CHART,
  SET_LOADING_SALES_CHART,
  GET_INVENTORY_ORDERS_CHART,
  SET_LOADING_INVENTORY_ORDERS_CHART,
  GET_SALES_MAP,
  SET_LOADING_SALES_MAP,
  GET_INVENTORY_TABLE,
  SET_LOADING_INVENTORY_TABLE,
  GET_INVENTORY_CHART,
  SET_LOADING_INVENTORY_CHART
} from '../actions/types';

const initialState = {
  item_sales: [],
  item_sales_total: {},
  last_po: {},
  inventory_chart: [],
  inventory_chart_data: [],
  inventory_orders: [],
  inventory_orders_data: [],
  inventory_table: [],
  sales_channels: [],
  sales_chart: [],
  sales_chart_data: [],
  sales_map: [],
  historyTotal: [],
  historyYTD: [],
  historyMTD: [],
  historyWeek: [],
  sales_map_total: null,
  max_po_date: null,
  credit_limit: null,
  first_order: null,
  payment_method: null,
  terms: null,
  closed_orders: 0,
  inventory_backorder: 0,
  inventory_received: 0,
  new_orders: 0,
  price_list_items: 0,
  value_instock: 0,
  weby_qty: 0,
  sales_loading: true,
  sales_table_loading: false,
  sales_chart_loading: false,
  inventory_chart_loading: false,
  inventory_orders_loading: false,
  inventory_table_loading: false,
  sales_map_loading: false
};

export default function sales(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_SALES_DATA:
      return {
        ...state,
        ...payload,
        sales_loading: false
      };
    case GET_INVENTORY_CHART:
      return {
        ...state,
        ...payload,
        inventory_chart_loading: false
      };
    case GET_SALES_MAP:
      return {
        ...state,
        ...payload,
        sales_map_loading: false
      };
    case GET_INVENTORY_TABLE:
      return {
        ...state,
        inventory_table: payload,
        inventory_table_loading: false
      };
    case GET_SALES_TABLE:
      return {
        ...state,
        ...payload,
        filtered_sales_table: [],
        sales_table_loading: false
      };
    case GET_SALES_CHART:
      return {
        ...state,
        ...payload,
        sales_chart_loading: false
      };
    case GET_INVENTORY_ORDERS_CHART:
      return {
        ...state,
        ...payload,
        inventory_orders_loading: false
      };
    case SET_LOADING_SALES_TABLE:
      return {
        ...state,
        sales_table_loading: true
      };
    case SET_LOADING_SALES_CHART:
      return {
        ...state,
        sales_chart_loading: true
      };
    case SET_LOADING_INVENTORY_ORDERS_CHART:
      return {
        ...state,
        inventory_orders_loading: true
      };
    case SET_LOADING_SALES_MAP:
      return {
        ...state,
        sales_map_loading: true
      };
    case SET_LOADING_INVENTORY_TABLE:
      return {
        ...state,
        inventory_table_loading: true
      };
    case SET_LOADING_INVENTORY_CHART:
      return {
        ...state,
        inventory_chart_loading: true
      };
    case CLEAR_SALES:
      return initialState;
    default:
      return state;
  }
}
