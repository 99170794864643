import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';

import {
  GET_CHAT_USERS,
  SET_LOADING_CHAT,
  SET_ACTIVE_CHAT_USER,
  GET_CHAT_MESSAGE,
  CLEAR_ACTIVE_MESSAGES,
  CLEAR_CHAT,
  GET_USER_MESSAGES,
  SET_LOADING_MESSAGES,
  GET_ONLINE_USERS,
  READ_MESSAGES,
  GET_UNREAD_MESSAGES,
  GET_WSS_CONNECTION,
  SORT_CHAT_USERS
} from './types';

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

export const getChatUsers = (users) => async (dispatch) => {
  setAuthToken(localStorage.token);
  try {
    dispatch({
      type: SET_LOADING_CHAT,
      payload: true
    });

    const body = JSON.stringify({ users });
    const res = await axios.post('/api/chat/users', body, config);

    dispatch({
      type: GET_CHAT_USERS,
      payload: res.data
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }
    dispatch({
      type: SET_LOADING_CHAT,
      payload: false
    });
  }
};

export const readMessages =
  ({ user, current, users }) =>
  async (dispatch) => {
    if (user) {
      const messages = [];
      if (user.messages) {
        user.messages.forEach((m) => {
          if (!m.seen && m.to !== user._id) {
            messages.push(m._id);
          }
        });
      }

      if (messages.length) {
        try {
          const body = JSON.stringify({ messages });
          await axios.put('/api/message/read', body, config);

          //Read messages from target user
          let active_messages = [];
          const upd_users = users.map((u) => {
            if (user._id === u._id && u.messages) {
              u.messages = u.messages.map((m) => {
                if (!m.seen) {
                  m.seen = true;
                }
                return m;
              });
              active_messages = u.messages;
            }
            return u;
          });

          //Count rest of unread messages
          let unread_messages = 0;
          upd_users.forEach((u) => {
            if (u.messages) {
              u.messages.forEach((m) => {
                if (!m.seen && m.to === current) {
                  unread_messages++;
                }
              });
            }
          });

          dispatch({
            type: READ_MESSAGES,
            payload: { upd_users, unread_messages, active_messages }
          });
        } catch (err) {
          if (err.response) {
            const errors = err.response.data.errors;
            if (errors) {
              errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
            }
          } else {
            console.log(err);
          }
        }
      }
    }
  };

export const getActiveUserMessages =
  ({ user, current, users }) =>
  async (dispatch) => {
    if (!user.messagesLoaded) {
      try {
        dispatch({
          type: SET_LOADING_MESSAGES,
          payload: true
        });

        const res = await axios.get(`/api/message?user=${user._id}`);

        let allMessages = res.data;
        user.messages = allMessages;

        const index = users.findIndex((u) => u._id === user._id);
        users[index].messages = allMessages;
        users[index].messagesLoaded = true;

        dispatch({
          type: GET_USER_MESSAGES,
          payload: { users, messages: allMessages }
        });
      } catch (err) {
        if (err.response) {
          const errors = err.response.data.errors;
          if (errors) {
            errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
          }
        } else {
          console.log(err);
        }
        dispatch({
          type: SET_LOADING_MESSAGES,
          payload: false
        });
      }
    }

    setTimeout(() => {
      dispatch(readMessages({ user, current, users }));
    }, 3000);
  };

export const setActiveChatUser = (id) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_CHAT_USER,
    payload: id
  });
};

export const getUnreadMessages = (users_available) => async (dispatch) => {
  setAuthToken(localStorage.token);
  try {
    const res = await axios.get(`/api/message/unread`);
    const totalQty = res.data.length;
    if (totalQty) {
      users_available.forEach((user) => {
        const messages = res.data.filter((m) => m.from === user._id);
        if (messages.length) {
          if (user.messages) {
            user.messages = [...user.messages, ...messages];
          } else {
            user.messages = messages;
          }
        }
      });
    }

    dispatch({
      type: GET_UNREAD_MESSAGES,
      payload: { qty: totalQty, users: users_available }
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }
  }
};

export const readOneMessage = async (message) => {
  if (message) {
    try {
      const body = JSON.stringify({ messages: [message] });
      await axios.put('/api/message/read', body, config);
    } catch (err) {
      console.log(err);
    }
  }
};

export const getChatMessage =
  ({ message, users, current, active_user, active_messages }) =>
  (dispatch) => {
    const { from, to, _id } = message;
    let found = active_messages.find((m) => m._id === _id) ? true : false;
    const userIndex = users.findIndex((u) => u._id === from || u._id === to);
    const isActive = active_user._id === from || active_user._id === to;
    if (!found && userIndex >= 0) {
      if (users[userIndex].messages) {
        found = users[userIndex].messages.find((m) => m._id === _id)
          ? true
          : false;
      }
    }

    if (!found) {
      //Read message in database
      if (active_user._id === from) {
        readOneMessage(_id);
        message.seen = true;
      }

      if (active_user._id === to) {
        message.seen = true;
      }

      //Add message to the active messages
      const messages = isActive
        ? [...active_messages, message]
        : active_messages;

      //Add message to the target user
      let upd_users = [...users];
      if (upd_users[userIndex]) {
        if (upd_users[userIndex].messages) {
          upd_users[userIndex].messages.push(message);
        } else {
          upd_users[userIndex].messages = [message];
        }
      }

      //Count unread messages
      let unread_messages = 0;
      upd_users.forEach((u) => {
        if (u.messages) {
          u.messages.forEach((m) => {
            if (!m.seen && m.to === current) {
              unread_messages++;
            }
          });
        }
      });

      dispatch({
        type: GET_CHAT_MESSAGE,
        payload: { messages, upd_users, unread_messages }
      });
    }
  };

export const sortChatUsers = (users, unread_messages) => (dispatch) => {
  const hasUnreadMessage = (messages) => {
    if (messages) {
      const index = messages.findIndex((m) => m.seen === false);
      return index >= 0 ? true : false;
    } else {
      return false;
    }
  };

  users.sort((a, b) => {
    if (!hasUnreadMessage(a.messages) && hasUnreadMessage(b.messages)) {
      return 1;
    }

    if (hasUnreadMessage(a.messages) && !hasUnreadMessage(b.messages)) {
      return -1;
    }

    return 0;
  });

  dispatch({
    type: SORT_CHAT_USERS,
    payload: users
  });
};

export const clearActiveMessages = () => (dispatch) => {
  dispatch({
    type: CLEAR_ACTIVE_MESSAGES
  });
};

export const getWSSconnection = (wss) => (dispatch) => {
  dispatch({
    type: GET_WSS_CONNECTION,
    payload: wss
  });
};

export const clearChat = () => (dispatch) => {
  dispatch({
    type: CLEAR_CHAT
  });
};

export const getOnlineUsers = (users) => (dispatch) => {
  dispatch({
    type: GET_ONLINE_USERS,
    payload: users
  });
};
