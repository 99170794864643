import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';
import { primaryColor, disableColor } from '../utils/constants';
import { clearSales } from './sales';
import { clearSellers } from './sellers';
import { clearLQS } from './lqs';
import { clearDashboard } from './dashboard';
import { clearAdvertising } from './advertising';
import { clearPrices } from './prices';
import { clearEbayLQS } from './ebay';
import { clearSeller } from './seller';
import { clearSellersComparison } from './sellers_comparison';

import {
  SET_ACTIVE_BRAND,
  CLEAR_BRAND,
  CHECK_REPORT_AVAILABILITY,
  SET_LOADING_BRANDS,
  GET_BRAND_DETAILS,
  GET_BRAND_USER_DETAILS
} from './types';

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

//Set Active Brand
export const setActiveBrand = (brand) => async (dispatch) => {
  dispatch({
    type: SET_ACTIVE_BRAND,
    payload: brand
  });
  dispatch(clearSales());
  dispatch(clearLQS());
  dispatch(clearSellers());
  dispatch(clearDashboard());
  dispatch(clearAdvertising());
  dispatch(clearPrices());
  dispatch(clearEbayLQS());
  dispatch(clearSeller());
  dispatch(clearSellersComparison(brand));
};

//Get Active brand details
export const getBrandDetails = (name) => async (dispatch) => {
  setAuthToken(localStorage.token);
  try {
    const res = await axios.get(
      `/api/mysql/brand?name=${encodeURIComponent(name)}`
    );

    dispatch({
      type: GET_BRAND_DETAILS,
      payload: res.data
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }
    dispatch({
      type: GET_BRAND_DETAILS,
      payload: {}
    });
  }
};

//Clear Brand
export const clearBrand = () => (dispatch) => {
  dispatch({
    type: CLEAR_BRAND
  });
};

//Print Page
export const printComponent = (e) => (dispatch) => {
  const id = e.currentTarget.getAttribute('print');
  const target = document.querySelector(`#${id}`);
  if (target) {
    const a = window.open('', '', 'height=600, width=1200');
    const s = `background-color:${disableColor};-webkit-print-color-adjust:exact;padding-top:20px;color:${primaryColor}`;
    a.document.write(
      `<html><body><div style="${s}">${target.innerHTML}</div></body></html>`
    );
    a.document.close();
    a.print();
  }
};

//Check reports Availability
export const checkBrandReports = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_BRANDS,
      payload: true
    });

    const body = JSON.stringify(data);
    const res = await axios.post('/api/mysql/brand', body, config);

    dispatch({
      type: CHECK_REPORT_AVAILABILITY,
      payload: res.data
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }
    dispatch({
      type: SET_LOADING_BRANDS,
      payload: false
    });
  }
};

//Get User
export const getBrandUser =
  ({ first_name, last_name, isMarketing, isBuyer }) =>
  async (dispatch) => {
    try {
      const res = await axios.get(
        `/api/user?first=${first_name}&last=${last_name}`
      );

      dispatch({
        type: GET_BRAND_USER_DETAILS,
        payload: { data: res.data, isMarketing, isBuyer }
      });
    } catch (err) {
      if (err.response) {
        const errors = err.response.data.errors;
        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
        }
      } else {
        console.log(err);
      }
      dispatch({
        type: GET_BRAND_USER_DETAILS,
        payload: { data: {}, isMarketing, isBuyer }
      });
    }
  };
