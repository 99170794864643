import {
  CLEAR_DASHBOARD,
  GET_DASHBOARD_LQS,
  SET_DASHBOARD_LOADING_LQS,
  GET_DASHBOARD_SELLERS,
  SET_DASHBOARD_LOADING_SELLERS,
  GET_DASHBOARD_ADV,
  SET_DASHBOARD_LOADING_ADV,
  GET_DASHBOARD_SALES,
  SET_DASHBOARD_LOADING_SALES,
  GET_LQS_SPEED,
  GET_ADV_SPEED,
  GET_SALES_SPEED,
  GET_SELLERS_SPEED,
  CHECK_DASHBOARD,
  GET_DASHBOARD_SQS,
} from '../actions/types';

const initialState = {
  lqs: null,
  sellers: null,
  adv: null,
  sales: null,
  speed: {
    lqs: 0,
    sellers: 0,
    adv: 0,
    sales: 0,
    loading_lqs: true,
    loading_sellers: true,
    loading_adv: true,
    loading_sales: true,
    get_metrics: false,
  },
  sqs: {
    sellersQty: null,
    averageSQS: null,
    sellersTable: [],
    sellersMap: [],
    sellersRangeChart: [],
    averageIndicators: [],
    gritr: null,
  },
  checked_index: null,
  loading_lqs: false,
  loading_sellers: false,
  loading_adv: false,
  loading_sales: false,
  loading_sqs: true,
};

export default function dashboard(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CHECK_DASHBOARD:
      return {
        ...state,
        checked_index: payload,
      };
    case GET_DASHBOARD_LQS:
      return {
        ...state,
        lqs: payload,
        loading_lqs: false,
      };
    case GET_DASHBOARD_SELLERS:
      return {
        ...state,
        sellers: payload,
        loading_sellers: false,
      };
    case GET_DASHBOARD_ADV:
      return {
        ...state,
        adv: payload,
        loading_adv: false,
      };
    case GET_DASHBOARD_SALES:
      return {
        ...state,
        sales: payload,
        loading_sales: false,
      };
    case GET_DASHBOARD_SQS:
      return {
        ...state,
        sqs: {
          ...state.sqs,
          ...payload,
        },
        loading_sqs: false,
      };
    case GET_LQS_SPEED:
      return {
        ...state,
        speed: {
          ...state.speed,
          lqs: payload,
          loading_lqs: false,
        },
      };
    case GET_ADV_SPEED:
      return {
        ...state,
        speed: {
          ...state.speed,
          adv: payload,
          loading_adv: false,
          get_metrics: true,
        },
      };
    case GET_SALES_SPEED:
      return {
        ...state,
        speed: {
          ...state.speed,
          sales: payload,
          loading_sales: false,
        },
      };
    case GET_SELLERS_SPEED:
      return {
        ...state,
        speed: {
          ...state.speed,
          sellers: payload,
          loading_sellers: false,
        },
      };
    case SET_DASHBOARD_LOADING_LQS:
      return {
        ...state,
        loading_lqs: payload,
      };
    case SET_DASHBOARD_LOADING_ADV:
      return {
        ...state,
        loading_adv: true,
      };
    case SET_DASHBOARD_LOADING_SELLERS:
      return {
        ...state,
        loading_sellers: payload,
      };
    case SET_DASHBOARD_LOADING_SALES:
      return {
        ...state,
        loading_sales: payload,
      };
    case CLEAR_DASHBOARD:
      return initialState;

    default:
      return state;
  }
}
