import { AiOutlineWarning } from 'react-icons/ai';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { RiErrorWarningLine } from 'react-icons/ri';
import classes from './AlertBlock.module.css';

export const alertTypes = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning'
};

export const alertStyles = {
  success: {
    className: classes.alertMessageSuccess,
    header: (
      <p className={classes.alertMessageHeader}>
        <IoIosCheckmarkCircleOutline
          className={classes.alertMessageSuccessIcon}
        />{' '}
        Success
      </p>
    )
  },
  error: {
    className: classes.alertMessageError,
    header: (
      <p className={classes.alertMessageHeader}>
        <RiErrorWarningLine className={classes.alertMessageErrorIcon} /> Error
      </p>
    )
  },
  warning: {
    className: classes.alertMessageWarning,
    header: (
      <p className={classes.alertMessageHeader}>
        <AiOutlineWarning className={classes.alertMessageWarningIcon} /> Warning
      </p>
    )
  }
};
