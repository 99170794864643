import { CLEAR_PURCHASE_ORDER, GET_PURCHASE_ORDER } from '../actions/types';

const initialState = {
  table: [],
  total: {
    orderedQty: 0,
    orderedAmount: 0,
    receivedAmountQty: 0,
    receivedAmount: 0,
    backorderedQty: 0,
    backorderedAmount: 0,
    closedQty: 0,
    closedAmount: 0,
    pendingReceiptQty: 0,
    pendingReceiptAmount: 0
  },
  poNumber: null,
  poDate: null,
  poDateCreated: null,
  brands: [],
  loading: true
};

export default function purchaseOrder(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_PURCHASE_ORDER:
      return {
        ...state,
        ...payload,
        loading: false
      };
    case CLEAR_PURCHASE_ORDER:
      return initialState;
    default:
      return state;
  }
}
