import { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  getChatUsers,
  getChatMessage,
  getWSSconnection,
  getOnlineUsers,
  getUnreadMessages
} from '../../actions/chat';
import { setAlert } from '../../actions/alert';
//import { logout } from '../../actions/auth';
import { roles } from '../../utils/accessPage/accessPage.data';
import { startConnection } from './WebSocketConnection.services';

const WebSocketConnection = ({
  auth: { token, user },
  chat: { active_user, users_available, active_messages, wss },
  getChatUsers,
  getChatMessage,
  getWSSconnection,
  getOnlineUsers,
  getUnreadMessages,
  setAlert
  //logout
}) => {
  const getUsers = useRef(false);
  const getMessages = useRef(false);
  const [wssMessage, getMessage] = useState({});

  useEffect(() => {
    if (
      users_available.length &&
      !getMessages.current &&
      user.role !== roles.help
    ) {
      getUnreadMessages(users_available);
      getMessages.current = true;
    }
  }, [user, users_available, getUnreadMessages]);

  //Wait for messages from WSS
  useEffect(() => {
    if (user._id && wssMessage._id && user.role !== roles.help) {
      getChatMessage({
        message: wssMessage,
        users: users_available,
        current: user._id,
        active_user,
        active_messages
      });
      getMessage({});
    }
  }, [
    wssMessage,
    user,
    users_available,
    active_user,
    active_messages,
    getChatMessage
  ]);

  //Get Available users
  useEffect(() => {
    if (user._id && !getUsers.current && user.role !== roles.help) {
      if (user.role === roles.vendor) {
        const users = user.responsible
          ? user.responsible.map((u) => u.user)
          : [];
        getChatUsers(users);
      } else {
        getChatUsers();
      }
      getUsers.current = true;
    }
  }, [user, users_available, getChatUsers]);

  useEffect(() => {
    if (user._id && !wss && user.role !== roles.help) {
      startConnection({
        token,
        user,
        getOnlineUsers,
        //logout,
        setAlert,
        getWSSconnection,
        getMessage
      });
    }

    return () => {
      if (wss) {
        wss.close();
        getUsers.current = false;
        getMessages.current = false;
      }
    };
  }, [user, setAlert, getOnlineUsers, getWSSconnection, token, wss]);

  return null;
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  chat: state.chat
});

export default connect(mapStateToProps, {
  getChatUsers,
  getChatMessage,
  getWSSconnection,
  getUnreadMessages,
  getOnlineUsers,
  //logout,
  setAlert
})(WebSocketConnection);
