import { clearItem } from '../item';
import { clearUsers } from '../user';
import { clearBrand } from '../brand';
import { clearSales } from '../sales';
import { clearSellers } from '../sellers';
import { clearSeller } from '../seller';
import { clearLQS } from '../lqs';
import { clearVendorItem } from '../vendoritem';
import { clearDashboard } from '../dashboard';
import { clearChat } from '../chat';
import { clearLogs } from '../logs';
import { clearEbayLQS } from '../ebay';
import { clearSellersComparison } from '../sellers_comparison';
import { clearPurchaseOrder } from '../purchaseOrder';
import { clearNotifications } from '../notifications';
import { clearConfirmation } from '../confirmation';

export const clearAllData = (dispatch) => {
  dispatch(clearUsers());
  dispatch(clearBrand());
  dispatch(clearItem());
  dispatch(clearSales());
  dispatch(clearLQS());
  dispatch(clearSellers());
  dispatch(clearDashboard());
  dispatch(clearChat());
  dispatch(clearLogs());
  dispatch(clearVendorItem());
  dispatch(clearEbayLQS());
  dispatch(clearSeller());
  dispatch(clearSellersComparison());
  dispatch(clearPurchaseOrder());
  dispatch(clearNotifications());
  dispatch(clearConfirmation());
};
