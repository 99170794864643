import {
  GET_SELLERS_COMPARISON,
  CLEAR_SELLERS_COMPARISON,
  CHANGE_SELLERS_IN_COMPARISON,
  GET_SQS_CHART_COMPARISON,
  GET_OFFERS_CHART_COMPARISON,
  GET_MAP_CHART_COMPARISON,
  SET_LOADING_SQS_CHART_COMPARISON,
  SET_LOADING_OFFERS_CHART_COMPARISON,
  SET_LOADING_MAP_CHART_COMPARISON
} from '../actions/types';

const initialState = {
  webyIncluded: false,
  sellersScore: [],
  sellersIndicators: [],
  sellersOverview: [],
  sellersInComparsion: [],
  webyScore: {},
  webyIndicators: {},
  webyOverview: {},
  chartSQS: [],
  chartOffers: [],
  chartMap: [],
  getFirst: false,
  chartSQSerror: false,
  chartOffersError: false,
  chartMapError: false,
  loading: true,
  loadingSQS: false,
  loadingOffers: false,
  loadingMap: false
};

export default function sellers(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_SELLERS_COMPARISON:
      return {
        ...state,
        ...payload,
        loading: false
      };
    case CHANGE_SELLERS_IN_COMPARISON:
      return {
        ...state,
        sellersInComparsion: payload
      };
    case GET_SQS_CHART_COMPARISON:
      return {
        ...state,
        ...payload,
        loadingSQS: false
      };
    case GET_OFFERS_CHART_COMPARISON:
      return {
        ...state,
        ...payload,
        getFirst: true,
        loadingOffers: false
      };
    case GET_MAP_CHART_COMPARISON:
      return {
        ...state,
        ...payload,
        loadingMap: false
      };
    case SET_LOADING_SQS_CHART_COMPARISON:
      return {
        ...state,
        loadingSQS: true
      };
    case SET_LOADING_OFFERS_CHART_COMPARISON:
      return {
        ...state,
        loadingOffers: true
      };
    case SET_LOADING_MAP_CHART_COMPARISON:
      return {
        ...state,
        loadingMap: true
      };
    case CLEAR_SELLERS_COMPARISON:
      return initialState;

    default:
      return state;
  }
}
