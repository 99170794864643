import { formatFullMonthDate } from '../../utils/constants';

export const eulaStatuses = {
  pending: '',
  accepted: 'accepted',
  declined: 'declined'
};

export const eulaPrintHeader = (user) => `<h2>End User License Agreement</h2>
<p style="margin-top:5px;margin-bottom:10px;font-size:13px">
  LATEST UPDATE - ${formatFullMonthDate(new Date(user.eula_current_date))}
</p>`;

export const eulaDeniedMessage = `You have declined the End-User License Agreement. This means you may be subject to certain restrictions when using our website. We recommend you review the EULA and consider accepting its terms in order to have full access to all of our website's features. If you declined the EULA by mistake, you will be prompted to review it again during your next session.`;
export const eulaAcceptedMessage = `You have accepted our EULA and now have full access to our website. Thank you!`;
