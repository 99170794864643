export const sortLQSfilters = ({ filter, selected_filters, filters }) => {
  filter.values.sort((a, b) => {
    const isSeason = filter.name === 'launch_season';
    const selectedA = selected_filters.find(
      (selected) => selected.name === filter.name && selected.values.includes(a)
    );
    const selectedB = selected_filters.find(
      (selected) => selected.name === filter.name && selected.values.includes(b)
    );
    const foundA = filters.find(
      (avail) => avail.name === filter.name && avail.values.includes(a)
    );
    const foundB = filters.find(
      (avail) => avail.name === filter.name && avail.values.includes(b)
    );
    const textA = a ? (isSeason ? +a.slice(-4) : a.toUpperCase()) : '';
    const textB = b ? (isSeason ? +b.slice(-4) : b.toUpperCase()) : '';
    const comparsionA = isSeason ? textA > textB : textA < textB;
    const comparsionB = isSeason ? textA < textB : textA > textB;

    //Sort selected options from a to z
    if (selectedA && selectedB && comparsionA) return -1;
    if (selectedA && selectedB && comparsionB) return 1;

    //Set Selected first
    if (selectedA) return -1;
    if (selectedB) return 1;

    //Sort Available filters
    if (foundA && foundB && comparsionA) return -1;
    if (foundA && foundB && comparsionB) return 1;

    if (foundA) return -1;
    if (foundB) return 1;

    //Sort inactive options from a to z
    if (comparsionA) return -1;
    if (comparsionB) return 1;

    return 0;
  });
};
