import axios from 'axios';
import { setAlert } from './alert';
import {
  SET_CONFIRMATION_LOADING,
  CHECK_CONFIRMATION_TOKEN,
  SET_CONFIRMATION_OPEN,
  SET_CONFIRMATION_MESSAGE_OPEN,
  CONFIRM_EMAIL_TAB,
  CLEAR_CONFIRMATION
} from './types';
import { actionErrorHandler } from '../utils/global.services';
import { storageEvents } from '../providers/EventHandler/EventHandler.data';

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

//Check Email Confirmation Token
export const checkConfirmationToken = (token) => async (dispatch) => {
  try {
    const res = await axios.get('/api/confirmation/email', {
      headers: {
        'x-auth-token': token
      }
    });

    if (localStorage.getItem(storageEvents.EMAIL_CONFIRMED)) {
      localStorage.removeItem(storageEvents.EMAIL_CONFIRMED);
    }

    localStorage.setItem(storageEvents.EMAIL_CONFIRMED, JSON.stringify(true));

    dispatch({
      type: CHECK_CONFIRMATION_TOKEN,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: CHECK_CONFIRMATION_TOKEN,
      payload: null
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
  window.history.replaceState(null, '', window.location.pathname);
};

export const sendConfirmationToken = (newsletter) => async (dispatch) => {
  const body = JSON.stringify({ newsletter });
  try {
    dispatch({
      type: SET_CONFIRMATION_LOADING,
      payload: true
    });

    await axios.post('/api/confirmation/email', body, config);

    dispatch({
      type: SET_CONFIRMATION_LOADING,
      payload: false
    });
    return true;
  } catch (err) {
    dispatch({
      type: SET_CONFIRMATION_LOADING,
      payload: false
    });
    actionErrorHandler(err, dispatch, setAlert);
  }

  return false;
};

//Open Confirmation Modal
export const openConfirmationModal = (payload) => (dispatch) => {
  dispatch({
    type: SET_CONFIRMATION_OPEN,
    payload
  });
};

//Open Confirmation Message
export const openConfirmationMessage = (payload) => (dispatch) => {
  dispatch({
    type: SET_CONFIRMATION_MESSAGE_OPEN,
    payload
  });
};

// Confirm Email on the current Tab
export const confirmEmailTab = (data) => (dispatch) => {
  dispatch({
    type: CONFIRM_EMAIL_TAB,
    payload: data
  });
};

//Clear Confirmation
export const clearConfirmation = () => (dispatch) => {
  dispatch({
    type: CLEAR_CONFIRMATION
  });
  localStorage.removeItem(storageEvents.EMAIL_CONFIRMED);
};
