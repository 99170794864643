import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';

import {
  GET_ALL_LOGS,
  SET_LOADING_LOGS,
  FILTER_VENDOR_LOGS,
  FILTER_USER_LOGS,
  CLEAR_LOGS,
  GET_STATISTICS_DAU,
  GET_ALL_EULA,
  SET_LOADING_ADD_EULA,
  ADD_EULA,
  DELETE_EULA
} from './types';
import { actionErrorHandler } from '../utils/global.services';

//Get All Users
export const getAllLogs =
  ({ from, to }) =>
  async (dispatch) => {
    setAuthToken(localStorage.token);
    try {
      dispatch({
        type: SET_LOADING_LOGS
      });

      const res = await axios.get(`/api/auth/logs?from=${from}&to=${to}`);

      dispatch({
        type: GET_ALL_LOGS,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: GET_ALL_LOGS,
        payload: {}
      });
      actionErrorHandler(err, dispatch, setAlert);
    }
  };

//Get Statistics DAU
export const getStatisticsDau = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/statistics/dau`);

    dispatch({
      type: GET_STATISTICS_DAU,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_STATISTICS_DAU,
      payload: {}
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
};

//Get Statistics DAU
export const getAllEula = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/eula');

    dispatch({
      type: GET_ALL_EULA,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_EULA,
      payload: []
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
};

export const addEula = (data, version) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data' //application/x-www-form-urlencoded
    }
  };
  try {
    dispatch({
      type: SET_LOADING_ADD_EULA,
      payload: true
    });
    const res = await axios.post(`/api/eula?version=${version}`, data, config);

    dispatch(setAlert('Your file successfully uploaded', 'success'));
    dispatch({
      type: ADD_EULA,
      payload: res.data
    });
    return true;
  } catch (err) {
    dispatch({
      type: SET_LOADING_ADD_EULA,
      payload: false
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
  return false;
};

//Get Statistics DAU
export const deleteEula = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/eula/${id}`);

    dispatch(setAlert('EULA successfully removed', 'success'));
    dispatch({
      type: DELETE_EULA,
      payload: id
    });
  } catch (err) {
    actionErrorHandler(err, dispatch, setAlert);
  }
};

//Filter Vendor Logs data
export const filterVendorLogs = (data) => (dispatch) => {
  dispatch({
    type: FILTER_VENDOR_LOGS,
    payload: data
  });
};

//Filter Vendor Logs data
export const filterUserLogs = (data) => (dispatch) => {
  dispatch({
    type: FILTER_USER_LOGS,
    payload: data
  });
};

//Clear Logs data
export const clearLogs = () => (dispatch) => {
  dispatch({
    type: CLEAR_LOGS
  });
};
