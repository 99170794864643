import axios from 'axios';

import { actionErrorHandler } from '../utils/global.services';
import { setAlert } from './alert';
import {
  GET_SELLERS_COMPARISON,
  CLEAR_SELLERS_COMPARISON,
  CHANGE_SELLERS_IN_COMPARISON,
  GET_SQS_CHART_COMPARISON,
  GET_OFFERS_CHART_COMPARISON,
  GET_MAP_CHART_COMPARISON,
  SET_LOADING_SQS_CHART_COMPARISON,
  SET_LOADING_OFFERS_CHART_COMPARISON,
  SET_LOADING_MAP_CHART_COMPARISON
} from './types';
import { storageEvents } from '../providers/EventHandler/EventHandler.data';

const CancelToken = axios.CancelToken;
let cancelSQSchart = null;
let cancelOffersChart = null;
let cancelMapChart = null;

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

//Get All Sellers for comparison
export const getSellersComparison = (name) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/comparison/sellers?name=${encodeURIComponent(name)}`
    );

    dispatch({
      type: GET_SELLERS_COMPARISON,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_SELLERS_COMPARISON,
      payload: {}
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
};

//Get Data for SQS chart
export const getSQSchart = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING_SQS_CHART_COMPARISON });

    if (cancelSQSchart) {
      cancelSQSchart();
      cancelSQSchart = null;
    }

    const config2 = {
      ...config,
      cancelToken: new CancelToken(function executor(c) {
        cancelSQSchart = c;
      })
    };

    const body = JSON.stringify(data);
    const res = await axios.post('/api/comparison/sqschart', body, config2);

    dispatch({
      type: GET_SQS_CHART_COMPARISON,
      payload: { chartSQS: res.data, chartSQSerror: false }
    });
  } catch (err) {
    dispatch({
      type: GET_SQS_CHART_COMPARISON,
      payload: { chartSQS: [], chartSQSerror: true }
    });
    actionErrorHandler(
      err,
      dispatch,
      setAlert,
      SET_LOADING_SQS_CHART_COMPARISON
    );
  }
};

//Get Data for Offers chart
export const getOffersChart = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING_OFFERS_CHART_COMPARISON });

    if (cancelOffersChart) {
      cancelOffersChart();
      cancelOffersChart = null;
    }

    const config2 = {
      ...config,
      cancelToken: new CancelToken(function executor(c) {
        cancelOffersChart = c;
      })
    };

    const body = JSON.stringify(data);
    const res = await axios.post('/api/comparison/offerschart', body, config2);

    dispatch({
      type: GET_OFFERS_CHART_COMPARISON,
      payload: { chartOffers: res.data, chartOffersError: false }
    });
  } catch (err) {
    dispatch({
      type: GET_OFFERS_CHART_COMPARISON,
      payload: { chartOffers: [], chartOffersError: true }
    });
    actionErrorHandler(
      err,
      dispatch,
      setAlert,
      SET_LOADING_OFFERS_CHART_COMPARISON
    );
  }
};

//Get Data for Map chart
export const getMapChart = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING_MAP_CHART_COMPARISON });

    if (cancelMapChart) {
      cancelMapChart();
      cancelMapChart = null;
    }

    const config2 = {
      ...config,
      cancelToken: new CancelToken(function executor(c) {
        cancelMapChart = c;
      })
    };

    const body = JSON.stringify(data);
    const res = await axios.post('/api/comparison/mapchart', body, config2);

    dispatch({
      type: GET_MAP_CHART_COMPARISON,
      payload: { chartMap: res.data, chartMapError: false }
    });
  } catch (err) {
    dispatch({
      type: GET_MAP_CHART_COMPARISON,
      payload: { chartMap: [], chartMapError: true }
    });
    actionErrorHandler(
      err,
      dispatch,
      setAlert,
      SET_LOADING_MAP_CHART_COMPARISON
    );
  }
};

export const changeSellersComparison =
  (sellers = []) =>
  (dispatch) => {
    dispatch({
      type: CHANGE_SELLERS_IN_COMPARISON,
      payload: sellers
    });
    if (sellers.length) {
      localStorage.setItem(
        storageEvents.SELLERS_IN_COMPARISON,
        JSON.stringify(sellers)
      );
    } else {
      localStorage.removeItem(storageEvents.SELLERS_IN_COMPARISON);
    }
  };

//Clear Sellers Comparison
export const clearSellersComparison = (brand) => (dispatch) => {
  dispatch({
    type: CLEAR_SELLERS_COMPARISON
  });

  if (brand?.name && brand.name === localStorage.getItem('brand')) {
    const sellers = localStorage.getItem(storageEvents.SELLERS_IN_COMPARISON);
    if (sellers) {
      dispatch({
        type: CHANGE_SELLERS_IN_COMPARISON,
        payload: JSON.parse(sellers)
      });
    }
  } else {
    localStorage.removeItem(storageEvents.SELLERS_IN_COMPARISON);
  }
};
