import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';

import {
  SET_VENDOR_ITEM,
  CHECK_ACCESS_VENDOR_ITEM,
  CLEAR_VENDOR_ITEM,
  GET_VENDOR_ITEM_LQS,
  SEARCH_VENDOR_ITEMS,
  SET_LOADING_VENDOR_ITEM_SEARCH,
  SET_VENDOR_ITEM_OPTIONS,
  GET_VENDOR_ITEM_ADV,
  GET_VENDOR_ITEM_SALES,
  GET_VENDOR_ITEM_SALES_HISTORY,
  SET_LOADING_VENDOR_SALES_HISTORY,
  GET_VENDOR_ITEM_SALES_COGS,
  SET_LOADING_VENDOR_SALES_COGS,
  GET_VENDOR_ITEM_SALES_TABLE,
  SET_LOADING_VENDOR_SALES_TABLE,
  GET_VENDOR_ITEM_MAP,
  GET_VENDOR_ITEM_MAP_CHART,
  SET_LOADING_VENDOR_ITEM_MAP_CHART,
  GET_VENDOR_ITEM_MAP_TABLE,
  SET_LOADING_VENDOR_ITEM_MAP_TABLE,
  FILTER_MAP_CHART_SELLERS,
} from './types';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const CancelToken = axios.CancelToken;
let cancelSearch = null;
let cancelMapChart = null;

//Set LQS Item
export const setVendorItem = (asin, index) => (dispatch) => {
  dispatch({
    type: SET_VENDOR_ITEM,
    payload: { asin, index },
  });
};

//Check Access to the item
export const checkItemAccess = (data) => async (dispatch) => {
  setAuthToken(localStorage.token);
  try {
    const body = JSON.stringify(data);
    const res = await axios.post('/api/mysql/vendoritem', body, config);

    dispatch({
      type: CHECK_ACCESS_VENDOR_ITEM,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
      return;
    }

    dispatch({
      type: CHECK_ACCESS_VENDOR_ITEM,
      payload: false,
    });
  }
};

//Get LQS Vendor item data
export const getVendorItemLQS = (asin) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/mysql/vendoritem/lqs?asin=${asin}`);

    dispatch({
      type: GET_VENDOR_ITEM_LQS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      console.log(errors);
      // if (errors) {
      //   errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      // }
    } else {
      console.log(err);
    }

    dispatch({
      type: GET_VENDOR_ITEM_LQS,
      payload: {},
    });
  }
};

//Get Sales Vendor item data
export const getVendorItemSales = (asin) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/mysql/vendoritem/sales?asin=${asin}`);

    dispatch({
      type: GET_VENDOR_ITEM_SALES,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      console.log(errors);
      // if (errors) {
      //   errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      // }
    } else {
      console.log(err);
    }

    dispatch({
      type: GET_VENDOR_ITEM_SALES,
      payload: {},
    });
  }
};

//Get Vendor Item  Sales history chart
export const getVendorSalesHistory = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_VENDOR_SALES_HISTORY,
    });

    const body = JSON.stringify(data);
    const res = await axios.post(
      '/api/mysql/vendoritem/sales/history',
      body,
      config
    );

    dispatch({
      type: GET_VENDOR_ITEM_SALES_HISTORY,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      console.log(errors);
      // if (errors) {
      //   errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      // }
    } else {
      console.log(err);
    }

    dispatch({
      type: GET_VENDOR_ITEM_SALES_HISTORY,
      payload: {},
    });
  }
};

//Get Sales COGS Vendor item data
export const getVendorSalesCOGS = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_VENDOR_SALES_COGS,
    });

    const res = await axios.get(
      `/api/mysql/vendoritem/sales/cogs?asin=${data.asin}&from=${data.from}&to=${data.to}`
    );

    dispatch({
      type: GET_VENDOR_ITEM_SALES_COGS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      console.log(errors);
      // if (errors) {
      //   errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      // }
    } else {
      console.log(err);
    }

    dispatch({
      type: GET_VENDOR_ITEM_SALES_COGS,
      payload: [],
    });
  }
};

//Get Sales Table Vendor item data
export const getVendorSalesTable = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_VENDOR_SALES_TABLE,
    });

    const res = await axios.get(
      `/api/mysql/vendoritem/sales/table?asin=${data.asin}&from=${data.from}&to=${data.to}`
    );

    dispatch({
      type: GET_VENDOR_ITEM_SALES_TABLE,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      console.log(errors);
      // if (errors) {
      //   errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      // }
    } else {
      console.log(err);
    }

    dispatch({
      type: GET_VENDOR_ITEM_SALES_TABLE,
      payload: [],
    });
  }
};

//Get Adv Vendor item data
export const getVendorItemAdv = (asin) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/mysql/vendoritem/adv?asin=${asin}`);

    dispatch({
      type: GET_VENDOR_ITEM_ADV,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      console.log(errors);
      // if (errors) {
      //   errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      // }
    } else {
      console.log(err);
    }

    dispatch({
      type: GET_VENDOR_ITEM_ADV,
      payload: {},
    });
  }
};

//Get Search ASIN or UPC Results
export const searchVendorItem = (text) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_VENDOR_ITEM_SEARCH,
    });

    if (cancelSearch) {
      cancelSearch();
      cancelSearch = null;
    }

    const config2 = {
      cancelToken: new CancelToken(function executor(c) {
        cancelSearch = c;
      }),
    };

    const res = await axios.get(
      `/api/mysql/vendoritem/search?text=${text ? text.trim() : ''}`,
      config2
    );

    dispatch({
      type: SEARCH_VENDOR_ITEMS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      console.log(errors);
      // if (errors) {
      //   errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      // }
    } else {
      //console.log(err);
      dispatch({
        type: SET_LOADING_VENDOR_ITEM_SEARCH,
      });
      return;
    }

    dispatch({
      type: SEARCH_VENDOR_ITEMS,
      payload: [],
    });
  }
};

//Get Map Vendor item data
export const getVendorItemMap = (asin) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/mysql/vendoritem/map?asin=${asin}`);

    dispatch({
      type: GET_VENDOR_ITEM_MAP,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      console.log(errors);
      // if (errors) {
      //   errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      // }
    } else {
      console.log(err);
    }

    dispatch({
      type: GET_VENDOR_ITEM_MAP,
      payload: {},
    });
  }
};

//Get Map Vendor item Chart data
export const getVendorItemMapChart = (data) => async (dispatch) => {
  dispatch({
    type: SET_LOADING_VENDOR_ITEM_MAP_CHART,
  });

  try {
    if (cancelMapChart) {
      cancelMapChart();
      cancelMapChart = null;
    }

    const config2 = {
      ...config,
      cancelToken: new CancelToken(function executor(c) {
        cancelMapChart = c;
      }),
    };

    const body = JSON.stringify(data);

    const res = await axios.post(
      `/api/mysql/vendoritem/map/chart`,
      body,
      config2
    );

    dispatch({
      type: GET_VENDOR_ITEM_MAP_CHART,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      console.log(errors);
      // if (errors) {
      //   errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      // }

      dispatch({
        type: GET_VENDOR_ITEM_MAP_CHART,
        payload: { sellers: [] },
      });
    } else {
      dispatch({
        type: SET_LOADING_VENDOR_ITEM_MAP_CHART,
      });
      console.log(err);
    }
  }
};

//Get Map Vendor item Table data
export const getVendorItemMapTable =
  ({ asin, from, to }) =>
  async (dispatch) => {
    dispatch({
      type: SET_LOADING_VENDOR_ITEM_MAP_TABLE,
    });

    try {
      const res = await axios.get(
        `/api/mysql/vendoritem/map/table?asin=${asin}&from=${from}&to=${to}`
      );

      dispatch({
        type: GET_VENDOR_ITEM_MAP_TABLE,
        payload: res.data,
      });
    } catch (err) {
      if (err.response) {
        const errors = err.response.data.errors;
        console.log(errors);
        // if (errors) {
        //   errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
        // }
      } else {
        console.log(err);
      }

      dispatch({
        type: GET_VENDOR_ITEM_MAP_TABLE,
        payload: [],
      });
    }
  };

//Set Vendor Item options
export const setVendorItemOptions = (options) => (dispatch) => {
  dispatch({
    type: SET_VENDOR_ITEM_OPTIONS,
    payload: options,
  });
};

//Filter Available Sellers
export const filterAvailableSellers = (name) => (dispatch) => {
  dispatch({
    type: FILTER_MAP_CHART_SELLERS,
    payload: name,
  });
};

//Clear Vendor Item
export const clearVendorItem = () => (dispatch) => {
  dispatch({
    type: CLEAR_VENDOR_ITEM,
  });
};
