import { useState } from 'react';
import { IoTrashOutline } from 'react-icons/io5';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { changeSellersComparison } from '../../actions/sellers_comparison';
import { ModalConfirmation } from '../../UI/ModalConfirmation/ModalConfirmation';

import classes from './CompareButton.module.css';

const CompareButton = ({
  sellers_comparison: { sellersInComparsion },
  changeSellersComparison,
  className = ''
}) => {
  const [isOpen, setOpen] = useState(false);

  const onApprove = () => {
    changeSellersComparison([]);
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  if (!sellersInComparsion.length) return null;

  return (
    <div className={`${classes.compareBtnBox} ${className}`}>
      <button className={classes.compareBtn}>
        <Link
          to="/dashboard/sellers-comparison/"
          className={classes.compareLink}
        >
          {sellersInComparsion.length} sellers in comparison
        </Link>
        <IoTrashOutline
          className={classes.compareBtnIcon}
          onClick={openModal}
        />
      </button>
      <ModalConfirmation
        isOpen={isOpen}
        setOpen={setOpen}
        onApprove={onApprove}
        message="Are you sure you want to remove sellers in comparsion?"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  sellers_comparison: state.sellers_comparison
});

export default connect(mapStateToProps, {
  changeSellersComparison
})(CompareButton);
