export const qtyRows = 100;
export const qty10rows = 10;
export const defaultLQS = 78;
export const allValue = 'All';
export const minSellerLifetimeDate = '2022-07-01';
export const marketingRecipients =
  'klobanova@webycorp.com,eazolina@webycorp.com,marketing@webycorp.com';
export const prohibitedEmails = '@webycorp.com';
export const calculateThumb = ({ value, max, min, correction }) => {
  return `calc(${((value - 1) / (max - min)) * 100}% + (${
    (correction ? correction : -12) - ((value - 1) / (max - min)) * 100 * 0.2
  }px))`;
};
export const isAuthorizedValue = 'Yes';
export const webySeller = { amazon_name: 'Gritr', amazon_id: 'A86M66Z7Z0Z95' };

// Colors
export const primaryColor = '#2f3440';
export const secondaryColor = '#dd7e43';
export const disableColor = '#f6f6f6';
export const disableGrayColor = '#a7a19d';
export const groundColor = 'white';
export const chartGrayColor = '#616b84';
export const chartGreenColor = '#91c559';
export const chartBlueColor = '#577ecb';
export const chartRedColor = '#ff3333';
export const chartOrangeColor = '#ffc200';

export const formatCurrency = (num) =>
  isNaN(num)
    ? num
    : new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(num);

export const formatCurrencyShort = (num) =>
  isNaN(num)
    ? num
    : new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(num);

export const formatNumber = (num) =>
  isNaN(num) ? num : new Intl.NumberFormat('en-US').format(num);

export const lqsColors = [
  '#dd4343',
  '#dd4343',
  '#dd7e43',
  '#dd7e43',
  '#ffc65f',
  '#ffc65f',
  '#a0c460',
  '#a0c460',
  '#5dc564',
  '#5dc564'
];

export const lqsColors5 = [
  '#dd4343',
  '#dd7e43',
  '#ffc65f',
  '#a0c460',
  '#5dc564'
];

export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return 'mobile';
  }
  return 'desktop';
};

export const nsPath = 'https://969904.app.netsuite.com';
export const getAmzOfferURL = (asin) =>
  `https://www.amazon.com/dp/${asin}?th=1&psc=1`;
//`https://www.amazon.com/gp/offer-listing/${asin}?th=1&psc=1`;
export const amzProductURL = 'https://www.amazon.com/dp/';
export const nsItemUrl =
  'https://969904.app.netsuite.com/app/common/item/item.nl?id=';
export const amzSellerURL = 'https://www.amazon.com/sp?seller=';

export const calcDate = (days, defaultDate) => {
  try {
    const today = defaultDate ? new Date(defaultDate) : new Date();
    return new Date(today.setDate(today.getDate() - days));
  } catch (error) {
    console.log(error);
  }
  return new Date();
};

export const formAvailableDates = (available) => {
  const availableDates = available ? available.map((d) => new Date(d)) : [];

  if (!availableDates.length) {
    for (let i = 0; i <= 365; i++) {
      availableDates.push(calcDate(i));
    }
  }

  return availableDates;
};

export const getDaysBetween = (from, to) => {
  try {
    const fromDate = new Date(from.setHours(0, 0, 0, 0));
    const toDate = new Date(to.setHours(23, 59, 59, 999));
    const diff = Math.abs(toDate.getTime() - fromDate.getTime());
    return Math.ceil(diff / (1000 * 60 * 60 * 24));
  } catch (error) {
    console.log(error);
  }
  return 0;
};

export const formatMessageDate = (d) => {
  try {
    const date = new Date(d);
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: '2-digit',
      hour: 'numeric',
      minute: 'numeric'
    }).format(date);
  } catch (error) {
    console.log(error);
  }
  return d;
};

export const formatLogsDate = (d) => {
  if (!d) return '';
  try {
    const date = new Date(d);
    return new Intl.DateTimeFormat('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }).format(date);
  } catch (error) {
    console.log(error);
  }
  return d;
};

export const formatDate = (custom) => {
  return `${custom.getFullYear()}/${custom.getMonth() + 1}/${custom.getDate()}`;
};

export const formatDateUS = (custom) => {
  return `${custom.getMonth() + 1}/${custom.getDate()}/${custom.getFullYear()}`;
};

export const formatFileDateUS = (custom) => {
  return `${custom.getMonth() + 1}-${custom.getDate()}-${
    custom.getFullYear() % 100
  }`;
};

export const formatDateLastDay = (custom) => {
  const year = custom.getFullYear();
  const month = custom.getMonth() + 1;
  const lastDay = new Date(year, month, 0).getDate();
  return `${year}/${month}/${lastDay}`;
};

export const getFromToMonth = (fromDate, toDate, maxDate) => {
  const today = maxDate instanceof Date ? maxDate : new Date();
  const lastTo = formatDateLastDay(toDate);
  const lastFrom = formatDateLastDay(fromDate);
  const dates = {
    from: formatDate(fromDate),
    to: new Date(lastTo) > today ? formatDate(today) : lastTo
  };

  if (fromDate.getTime() > toDate.getTime()) {
    dates.from = formatDate(toDate);
    dates.to = new Date(lastFrom) > today ? formatDate(today) : lastFrom;
  }
  return dates;
};

export const getFromToDate = (fromDate, toDate) => {
  const dates = {
    from: formatDate(fromDate),
    to: formatDate(toDate)
  };

  if (fromDate.getTime() > toDate.getTime()) {
    dates.from = formatDate(toDate);
    dates.to = formatDate(fromDate);
  }
  return dates;
};

export const getPreviousMonth = () => {
  const dates = {
    from: new Date(),
    to: new Date()
  };

  dates.from = new Date(dates.from.setMonth(dates.from.getMonth() - 1));
  dates.from = formatDate(new Date(dates.from.setDate(1)));
  dates.to = formatDateLastDay(
    new Date(dates.to.setMonth(dates.to.getMonth() - 1))
  );

  return dates;
};

export const getFirstMonthDate = (date) =>
  date instanceof Date ? new Date(date.setDate(1)) : date;

export const getColor = (value) => {
  value = Number(value);
  if (value < 20) {
    return '#dd4343';
  } else if (value >= 20 && value < 40) {
    return '#dd7e43';
  } else if (value >= 40 && value < 60) {
    return '#ffc65f';
  } else if (value >= 60 && value < 80) {
    return '#a0c460';
  } else {
    return '#5dc564';
  }
};

export const getItemPtsColor = (value) => {
  if (+value < 0) {
    return '#dd4343';
  } else if (value === 0) {
    return '#ffc65f';
  } else {
    return '#5dc564';
  }
};

export const getItemLQSColor = (value) => {
  if (+value <= 40) {
    return '#dd4343';
  } else if (value <= 60) {
    return '#ffc65f';
  } else {
    return '#5dc564';
  }
};

export const getRatingColor = (value) => {
  value = Number(value);
  if (value < 90) {
    return '#ff9999';
  } else if (value >= 90 && value < 95) {
    return '#ffc000';
  } else {
    return '#a9d08e';
  }
};

export const getOffersColor = (value) => {
  value = Number(value);
  if (value > 0) {
    return '#70ad47';
  } else if (value < 0) {
    return '#ff0000';
  } else {
    return '';
  }
};

export const getDiffColor = (value) => {
  value = Number(value);
  if (value < 0) {
    return '#70ad47';
  } else if (value > 0) {
    return '#ff0000';
  } else {
    return '';
  }
};

export const getDashSellersColor = (percent) => {
  let color = '#2f3440';
  if (percent >= 50) {
    color = '#dd4343';
  } else if (percent >= 25 && percent < 50) {
    color = '#dd7e43';
  } else if (percent >= 10 && percent < 25) {
    color = '#ffc65f';
  } else if (percent >= 5 && percent < 10) {
    color = '#a0c460';
  } else if (percent < 5) {
    color = '#5dc564';
  }

  return color;
};

export const getLQSproblemsColor = (percent) => {
  const opacity = '0.5';
  let color = `rgba(47,52,64,${opacity})`;
  if (percent >= 50) {
    color = `rgba(221,67,67,${opacity})`;
  } else if (percent >= 25 && percent < 50) {
    color = `rgba(221,126,67,${opacity})`;
  } else if (percent >= 10 && percent < 25) {
    color = `rgba(225,198,95,${opacity})`;
  } else if (percent >= 5 && percent < 10) {
    color = `rgba(160,196,96,${opacity})`;
  } else if (percent < 5) {
    color = `rgba(93,197,100,${opacity})`;
  }

  return color;
};

export const getSellersColor = (total) => {
  const opacity = '0.5';
  let color = `rgba(47,52,64,${opacity})`;
  if (total > 50) {
    color = `rgba(221,67,67,${opacity})`;
  } else if (total >= 26 && total <= 50) {
    color = `rgba(221,126,67,${opacity})`;
  } else if (total >= 11 && total <= 25) {
    color = `rgba(225,198,95,${opacity})`;
  } else if (total >= 1 && total <= 10) {
    color = `rgba(160,196,96,${opacity})`;
  } else if (total === 0) {
    color = `rgba(93,197,100,${opacity})`;
  }

  return color;
};

export const getQualityScore = (data) => {
  const { title, description, bullets } = data;
  let qs = 0;
  if (title) {
    if (title >= 25 && title < 50) {
      qs = 2;
    } else if (title >= 50 && title < 100) {
      qs = 4;
    } else if (title >= 100 && title < 150) {
      qs = 6;
    } else if (title >= 150 && title <= 200) {
      qs = 10;
    } else if (title > 200) {
      qs = 8;
    }
  }

  if (description) {
    if (description < 250) {
      qs = 2;
    } else if (description >= 250 && description < 500) {
      qs = 4;
    } else if (description >= 500 && description < 750) {
      qs = 6;
    } else if (description >= 750 && description < 1000) {
      qs = 8;
    } else if (description >= 1000) {
      qs = 10;
    }
  }

  if (bullets) {
    let total = 0;
    bullets.forEach((b) => (total += b));
    total = Math.round(total / bullets.length);

    if (total >= 40 && total < 80) {
      qs = 2;
    } else if (total >= 80 && total < 120) {
      qs = 4;
    } else if ((total >= 120 && total < 160) || total > 300) {
      qs = 6;
    } else if (total >= 160 && total < 200) {
      qs = 8;
    } else if (total >= 200 && total <= 300) {
      qs = 10;
    }
  }

  return qs;
};

export const getCaseText = (asin, data) => {
  if (!data.length) {
    return '';
  }
  const bullets = [];
  let text = `Could you change these fields for the ${asin} page:\r\n`;
  data.forEach((d) => {
    if (d.key.indexOf('title') >= 0) {
      text += `Title to: ${d.text}\r\n`;
    } else if (d.key.indexOf('description') >= 0) {
      text += `Description to: ${d.text}\r\n`;
    } else if (d.key.indexOf('video') >= 0) {
      text += `Video link to: ${d.text}\r\n`;
    } else if (d.key.indexOf('bullet') >= 0) {
      bullets.push({
        index: +d.key.substring(d.key.indexOf('bullet') + 6),
        text: d.text
      });
    }
  });

  bullets.sort((a, b) => (a.index > b.index ? 1 : -1));
  bullets.forEach(
    (b, i) => (text += `${i === 0 ? 'Bullets to: \r\n' : ''} ${b.text}\r\n`)
  );

  return text;
};

export const teams = ['Marketing', 'Sales', 'Development'];
export const teamRoles = {
  Marketing: ['Advertising Manager', 'Content Manager'],
  Sales: ['Account Manager', 'CEO', 'President'],
  Development: [
    'Technical Support',
    'Developer',
    'IT Development Manager',
    'CIO'
  ]
};

export const cutString = (str, qty) => {
  if (str.length > qty) {
    str = `${str.slice(0, qty - 3)}...`;
  }
  return str;
};

export const formatFullMonthDate = (num) => {
  try {
    return new Intl.DateTimeFormat('en', {
      month: 'long',
      year: 'numeric'
    }).format(num);
  } catch (error) {
    console.log(error);
    return '';
  }
};

export const formatShortMonthDate = (num) =>
  new Intl.DateTimeFormat('en', {
    month: 'short',
    year: 'numeric'
  }).format(num);

export const getArrayOfMonth = (firstDate) => {
  const arr = [];
  let startDate = new Date(firstDate);
  const endDate = new Date();
  while (startDate <= endDate) {
    const current = new Date(startDate);
    arr.push({
      period: formatFullMonthDate(current),
      period_short: formatShortMonthDate(current),
      date: current
    });
    startDate = current.setMonth(current.getMonth() + 1);
  }

  return arr;
};

export const modifyFirstLetter = (str) => {
  if (str) {
    str = str[0].toUpperCase() + str.slice(1);
    str = str.replace(/_/g, ' ');
  }
  return str;
};

export const checkAllSelected = (arrOld, arrNew) => {
  let added = '';
  if (arrNew.length > 1) {
    if (arrOld.length < arrNew.length) {
      arrNew.forEach((value) => {
        if (!arrOld.includes(value)) {
          added = value;
        }
      });
      if (added === 'All') {
        return ['All'];
      } else if (arrNew.includes('All')) {
        return arrNew.filter((value) => value !== 'All');
      }
    }
  }

  return arrNew;
};

export const userStatuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
};
