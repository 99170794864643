import setAuthToken from '../../utils/setAuthToken';
import { storageEvents, userTimeout } from './EventHandler.data';

//Event Tracking
export const startTimer = ({ logout, timeoutId }) => {
  localStorage.setItem(storageEvents.UPDATE_TIME_TRACKER, Date.now());
  timeoutId.current = window.setTimeout(() => {
    const storedTime = +localStorage.getItem(storageEvents.UPDATE_TIME_TRACKER);
    if (Date.now() - storedTime >= userTimeout) {
      setAuthToken();
      logout();
      localStorage.setItem(storageEvents.UPDATE_TIME_TRACKER, '');
    }
  }, userTimeout);
};

export const setupTimers = ({ logout, timeoutId, resetTimer }) => {
  if (!timeoutId.current) {
    document.timeoutId = timeoutId;
    document.addEventListener('mousemove', resetTimer, false);
    document.addEventListener('mousedown', resetTimer, false);
    document.addEventListener('keydown', resetTimer, false);
    document.addEventListener('touchmove', resetTimer, false);
    document.addEventListener('scroll', resetTimer, false);
    startTimer({ logout, timeoutId });
  }
};

export const removeTracking = ({ timeoutId, resetTimer }) => {
  if (timeoutId.current) {
    document.removeEventListener('mousemove', resetTimer);
    document.removeEventListener('mousedown', resetTimer);
    document.removeEventListener('keydown', resetTimer);
    document.removeEventListener('touchmove', resetTimer);
    document.removeEventListener('scroll', resetTimer);
    window.clearTimeout(timeoutId.current);
    timeoutId.current = null;
    delete document.timeoutId;
    localStorage.setItem(storageEvents.UPDATE_TIME_TRACKER, '');
  }
};

// Offline Listeners

const offlineListener = () => {
  setTimeout(() => {
    setAuthToken();
    localStorage.removeItem('token');
  }, userTimeout);
};

export const addOfflineListener = () => {
  window.addEventListener('offline', offlineListener);
};

export const removeOfflineListener = () => {
  window.removeEventListener('offline', offlineListener);
};
