import {
  SET_LOADING_SELLERS,
  CLEAR_SELLERS,
  GET_SELLERS_DATA,
  FILTER_OFFERS,
  GET_MAP_TABLE,
  SET_LOADING_TABLE,
  SET_OFFERS_DATE,
  SET_SELLERS_DATE,
  GET_MAP_CHARTS,
  GET_SELLERS_REPORT,
  SET_SELLERS_REPORT_LOADING,
  GET_SELLERS_CHARTS,
  SET_SELLERS_CHARTS_LOADING,
  GET_AVAILABLE_REPORT_DATES,
  HIDE_PRODUCTS_CHART_DATA,
  HIDE_SELLERS_TABLE_DATA,
  HIDE_OFFERS_CHART_DATA,
  HIDE_SELLERS_TABLE_USED,
  HIDE_SELLERS_CHART_DATA,
  GET_SELLERS_MODAL
} from '../actions/types';
import { filterSorts } from '../utils/tables';

const initialState = {
  sellers_data: {
    offers: {
      total_offers: [],
      per_producst: [],
      product_voilation: [],
      total_violations: null,
      total_violations_with_used: null,
      average_fba: null,
      average_fbm: null,
      average_total: null,
      total_fbm: null,
      total_fba: null,
      total_all: null
    },
    products: {
      total_offers: [],
      total_violations: [],
      with_listings: null,
      no_listings: null,
      price_list: null,
      with_offer: null,
      without_offer: null,
      map_violations: null,
      map_violations_with_used: null
    }
  },
  sellers_tab: {
    table: [],
    table_total: {},
    total_sellers: [],
    total_seller_violations: []
  },
  reports: {
    sellers: null,
    sellers_from: null,
    sellers_to: null,
    sellers_loading: false,
    available_dates: [],
    dates_loading: true,
    name: null
  },
  offers_table: [],
  sellers_table_props: {
    '>50': true,
    '26-50': true,
    '11-25': true,
    '1-10': true,
    0: true
  },
  filtered_offers_props: {
    prop: 'date',
    method: filterSorts.DSC
  },
  filtered_offers_text: '',
  filtered_offers_page: 1,
  filtered_offers_conditions: {
    new: true,
    used: false,
    amazonDiscount: false,
    hasViolations: false
  },
  products_data_props: {
    with_offer: true,
    without_offer: true,
    no_listings: true,
    violations_used: false
  },
  offers_data_props: {
    used_offers: false,
    used_product: false,
    used_violation: false
  },
  sellers_data_props: {
    map_violations_used: false
  },
  seller_modal: {
    name: '',
    isOpen: false,
    title: '',
    subtitle: '',
    page: 1,
    text: '',
    table: [],
    modal_props: {
      prop: '',
      method: ''
    }
  },
  sellers_table_used: false,
  offers_table_filtered: false,
  offers_table_date: { from: null, to: null },
  sellers_table_date: { from: null, to: null },
  getSellersData: false,
  loading: true,
  table_loading: false,
  sellers_chart_loading: false,
  sellers_table_loading: false
};

export default function sellers(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_SELLERS_REPORT:
      return {
        ...state,
        reports: {
          ...state.reports,
          sellers: payload.sellers,
          sellers_from: payload.from,
          sellers_to: payload.to,
          name: payload.name,
          sellers_loading: false
        }
      };
    case GET_AVAILABLE_REPORT_DATES:
      return {
        ...state,
        reports: {
          ...state.reports,
          available_dates: payload,
          dates_loading: false
        }
      };
    case GET_SELLERS_MODAL:
      return {
        ...state,
        seller_modal: { ...state.seller_modal, ...payload }
      };
    case HIDE_PRODUCTS_CHART_DATA:
      return {
        ...state,
        products_data_props: payload
      };
    case HIDE_OFFERS_CHART_DATA:
      return {
        ...state,
        offers_data_props: payload
      };
    case HIDE_SELLERS_TABLE_USED:
      return {
        ...state,
        sellers_table_used: payload
      };
    case HIDE_SELLERS_CHART_DATA:
      return {
        ...state,
        sellers_data_props: payload
      };
    case SET_SELLERS_REPORT_LOADING:
      return {
        ...state,
        reports: {
          ...state.reports,
          sellers_loading: payload
        }
      };
    case SET_OFFERS_DATE:
      return {
        ...state,
        offers_table_date: payload
      };
    case SET_SELLERS_DATE:
      return {
        ...state,
        sellers_table_date: payload
      };
    case GET_MAP_TABLE:
      if (payload.type === 'offers') {
        return {
          ...state,
          offers_table: payload.data,
          offers_table_filtered: false,
          table_loading: false
        };
      } else if (payload.type === 'sellers') {
        return {
          ...state,
          sellers_tab: { ...state.sellers_tab, ...payload.data },
          sellers_table_loading: false
        };
      } else {
        return state;
      }
    case GET_SELLERS_DATA:
      return {
        ...state,
        sellers_data: { ...state.sellers_data, ...payload },
        getSellersData: true,
        loading: false
      };
    case GET_SELLERS_CHARTS:
      return {
        ...state,
        sellers_tab: {
          ...state.sellers_tab,
          ...payload
        },
        sellers_chart_loading: false
      };
    case SET_SELLERS_CHARTS_LOADING:
      return {
        ...state,
        sellers_chart_loading: true
      };
    case GET_MAP_CHARTS:
      return {
        ...state,
        sellers_data: {
          ...state.sellers_data,
          offers: {
            ...state.sellers_data.offers,
            ...payload.offers
          },
          sellers: {
            ...state.sellers_data.sellers,
            ...payload.sellers
          },
          products: {
            ...state.sellers_data.products,
            ...payload.products
          },
          from: payload.from,
          to: payload.to
        },
        loading: false
      };
    case SET_LOADING_SELLERS:
      return {
        ...state,
        loading: payload
      };
    case SET_LOADING_TABLE:
      if (payload.type === 'offers') {
        return {
          ...state,
          table_loading: payload.value
        };
      } else if (payload.type === 'sellers') {
        return {
          ...state,
          sellers_table_loading: payload.value
        };
      } else {
        return state;
      }
    case FILTER_OFFERS:
      return {
        ...state,
        filtered_offers_props: payload?.props
          ? payload?.props
          : state.filtered_offers_props,
        filtered_offers_text:
          payload && payload.text !== undefined
            ? payload.text
            : state.filtered_offers_text,
        filtered_offers_page: payload?.page
          ? payload?.page
          : state.filtered_offers_page,
        filtered_offers_conditions: payload?.conditions
          ? payload?.conditions
          : state.filtered_offers_conditions
      };
    case HIDE_SELLERS_TABLE_DATA:
      return {
        ...state,
        sellers_table_props: { ...payload }
      };

    case CLEAR_SELLERS:
      return initialState;

    default:
      return state;
  }
}
