import { useState, useMemo, useRef, useEffect } from 'react';
import classes from './TextEditor.module.css';
import { getCommandsState, textEditorTools } from './TextEditor.service';
import { AddFiles, AddFilesList } from '../AddFiles/AddFiles';
import { validateFiles } from '../AddFiles/AddFiles.service';
import { IoAttach } from 'react-icons/io5';
import { TextEditorFormatterBtn } from './components/TextEditorFormatterBtn';
import { MdPreview } from 'react-icons/md';
import { AiFillCaretDown } from 'react-icons/ai';

export const TextEditor = ({
  onChange,
  containerClassName,
  placeholder,
  editorRef,
  files,
  fileTypes,
  filesLimit,
  setAttachments,
  setAlert,
  loading,
  hideFilesAdd,
  defaultValue,
  error,
  label,
  previewContent
}) => {
  const textEditor = useRef(null);
  const previewRef = useRef(null);
  const initialState = useMemo(() => {
    const res = {};
    textEditorTools.forEach((t) => (res[t.name] = false));
    return res;
  }, []);
  const [isOpenPreview, setOpenPreview] = useState(false);
  const [btnStatus, setBtnStatus] = useState(initialState);

  const onInput = (e) => {
    setBtnStatus(getCommandsState());
    onChange(e);
  };
  const openPreview = () => setOpenPreview(!isOpenPreview);
  const onClick = () => {
    setBtnStatus(getCommandsState());
  };

  const onPaste = async (e) => {
    if (!e?.clipboardData?.files?.length) return;
    e.preventDefault();
    if (files && files !== undefined) {
      validateFiles({
        addedFiles: e.clipboardData.files,
        files,
        setAlert,
        setAttachments,
        filesLimit,
        fileTypes
      });
    }
  };

  useEffect(() => {
    if (defaultValue !== undefined) {
      if (editorRef) {
        editorRef.current.innerHTML = defaultValue;
      } else {
        textEditor.current.innerHTML = defaultValue;
      }
    }
  }, [defaultValue, editorRef]);

  useEffect(() => {
    if (previewContent && previewRef.current) {
      previewRef.current.innerHTML = previewContent;
    }
  }, [previewContent]);

  return (
    <div>
      <div
        className={`${classes.textEditorContainer} ${
          containerClassName ? containerClassName : ''
        } ${error ? classes.textEditorError : ''}`}
      >
        <div
          className={`${classes.textEditorBox} `}
          onInput={onInput}
          onClick={onClick}
          onPaste={onPaste}
          contentEditable={true}
          placeholder={placeholder}
          ref={editorRef ? editorRef : textEditor}
        />
        <div className={classes.textEditorMenu}>
          <TextEditorFormatterBtn
            btnStatus={btnStatus}
            setBtnStatus={setBtnStatus}
          />
          {!hideFilesAdd && (
            <AddFiles
              files={files}
              setAttachments={setAttachments}
              setAlert={setAlert}
              loading={loading}
              fileTypes={fileTypes}
              filesLimit={filesLimit}
              id="help-btn-files"
              containerClassName={classes.attachedBtn}
            >
              <IoAttach className="upload-icon" />
            </AddFiles>
          )}
          {previewContent && (
            <>
              <MdPreview
                className={`${classes.previewIcon} ${
                  isOpenPreview ? classes.previewIconActive : ''
                }`}
                onClick={openPreview}
              />
              <div
                style={{ display: !isOpenPreview ? 'none' : '' }}
                className={classes.previewBox}
              >
                <div ref={previewRef} className={classes.previewContent}></div>
              </div>
              <AiFillCaretDown
                style={{ display: !isOpenPreview ? 'none' : '' }}
                className={classes.previewBoxArrow}
              />
            </>
          )}
        </div>
        {!hideFilesAdd && (
          <AddFilesList
            files={files}
            setAttachments={setAttachments}
            containerClassName={classes.attachedBox}
          />
        )}
        {label && <label className={classes.label}>{label}</label>}
      </div>
      {error !== undefined && <p className={classes.error}>{error}</p>}
    </div>
  );
};
