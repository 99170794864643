import axios from 'axios';
import { setAlert } from './alert';
import { CLEAR_PURCHASE_ORDER, GET_PURCHASE_ORDER } from './types';
import { actionErrorHandler } from '../utils/global.services';

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

//Get Data for Sales chart
export const getPurchaseOrder = (data) => async (dispatch) => {
  try {
    const body = JSON.stringify(data);
    const res = await axios.post('/api/mysql/purchaseOrder', body, config);

    dispatch({
      type: GET_PURCHASE_ORDER,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_PURCHASE_ORDER,
      payload: { table: [] }
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
};

//Get Data for Sellers report page
export const clearPurchaseOrder = () => (dispatch) => {
  dispatch({
    type: CLEAR_PURCHASE_ORDER
  });
};
