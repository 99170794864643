export const startConnection = ({
  token,
  user,
  getOnlineUsers,
  //logout,
  setAlert,
  getWSSconnection,
  getMessage
}) => {
  let origin = `wss${window.origin.substring(window.origin.indexOf('://'))}`;

  if (window.origin.indexOf('localhost') >= 0) {
    origin = `ws://localhost:5001`;
  }

  const wss = new WebSocket(origin);
  wss.onopen = () => {
    const data = {
      connectedId: user._id,
      email: user.email,
      connectedToken: token
    };
    wss.send(JSON.stringify(data));
    const check = { getOnline: true, from: user._id };
    wss.send(JSON.stringify(check));
  };
  wss.onmessage = (e) => {
    try {
      let message = JSON.parse(e.data);
      if (message.usersOnline) {
        getOnlineUsers(message.usersOnline);
      } else if (message.close) {
        wss.close(1000, 'inactive');
        // logout();
        // setAlert(
        //   message.message ? message.message : 'Your token has expired',
        //   'error'
        // );
      } else if (message.logout) {
        wss.close();
        //logout();
      } else {
        getMessage(message);
      }
    } catch (err) {
      setAlert(err, 'error');
    }
  };

  wss.onclose = (e) => {
    if (!e.wasClean) {
      console.log(
        'ws closed by restarted server. Will reconnect through 10 sec'
      );
      setTimeout(() => {
        startConnection({
          user,
          getOnlineUsers,
          //logout,
          setAlert,
          getWSSconnection,
          getMessage
        });
      }, 10000);
    }
  };

  getWSSconnection(wss);
  //Update wss for tracking
  document.wss = wss;

  return wss;
};
