import {
  SET_VENDOR_ITEM,
  CHECK_ACCESS_VENDOR_ITEM,
  CLEAR_VENDOR_ITEM,
  GET_VENDOR_ITEM_LQS,
  SEARCH_VENDOR_ITEMS,
  SET_LOADING_VENDOR_ITEM_SEARCH,
  SET_VENDOR_ITEM_OPTIONS,
  GET_VENDOR_ITEM_ADV,
  GET_VENDOR_ITEM_SALES,
  GET_VENDOR_ITEM_SALES_HISTORY,
  SET_LOADING_VENDOR_SALES_HISTORY,
  GET_VENDOR_ITEM_SALES_COGS,
  SET_LOADING_VENDOR_SALES_COGS,
  GET_VENDOR_ITEM_SALES_TABLE,
  SET_LOADING_VENDOR_SALES_TABLE,
  GET_VENDOR_ITEM_MAP,
  GET_VENDOR_ITEM_MAP_CHART,
  SET_LOADING_VENDOR_ITEM_MAP_CHART,
  GET_VENDOR_ITEM_MAP_TABLE,
  SET_LOADING_VENDOR_ITEM_MAP_TABLE,
  FILTER_MAP_CHART_SELLERS,
} from '../actions/types';

const initialState = {
  active: null,
  tabIndex: 0,
  hasAccess: false,
  search_results: [],
  lqs: {},
  adv: {
    lastMonth: {},
    lifetime: {},
    productClicks: [],
    productSales: [],
    hasBrandsAdv: false,
    advSpeed: 0,
    brandName: null,
    calculatorData: {},
  },
  sales: {
    sales_chart: [],
    cogs_chart: [],
    table: [],
    markets_list: [],
    sync_statuses: [],
    sales_speed: 0,
    history_loading: false,
    cogs_loading: false,
    table_loading: false,
  },
  map: {
    map_status: {},
    current_prices: [],
    price_history: [],
    sellers: [],
    available_sellers: [],
    map_history: [],
    map_speed: 0,
    map_price: null,
    min_date: null,
    last_update: null,
    prices_loading: false,
    history_loading: false,
  },
  search_loading: false,
  lqs_loading: true,
  adv_loading: true,
  sales_loading: true,
  check_loading: true,
  map_loading: true,
};

export default function lqs(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_VENDOR_ITEM:
      return {
        ...state,
        active: payload.asin,
        tabIndex: payload.index ? payload.index : 0,
      };
    case CHECK_ACCESS_VENDOR_ITEM:
      return {
        ...state,
        hasAccess: payload,
        check_loading: false,
      };
    case GET_VENDOR_ITEM_LQS:
      return {
        ...state,
        lqs: payload,
        lqs_loading: false,
      };
    case GET_VENDOR_ITEM_MAP:
      return {
        ...state,
        map: {
          ...state.map,
          ...payload,
        },
        map_loading: false,
      };
    case GET_VENDOR_ITEM_MAP_CHART:
      const newSellersAvailable = [
        ...state.map.available_sellers,
        ...payload.sellers
          .filter(
            (newSeller) =>
              state.map.sellers.findIndex(
                (oldSeller) =>
                  oldSeller.nameCondition === newSeller.nameCondition
              ) === -1
          )
          .map((s) => s.nameCondition),
      ];

      return {
        ...state,
        map: {
          ...state.map,
          ...payload,
          available_sellers: newSellersAvailable,
          prices_loading: false,
        },
      };
    case FILTER_MAP_CHART_SELLERS:
      const seller = state.map.sellers.find((s) => s.nameCondition === payload);
      const filteredSellersAvailable = state.map.available_sellers.includes(
        payload
      )
        ? state.map.available_sellers.filter((a) => a !== payload)
        : seller
        ? [...state.map.available_sellers, seller.nameCondition]
        : state.map.available_sellers;
      return {
        ...state,
        map: {
          ...state.map,
          available_sellers: filteredSellersAvailable,
        },
      };
    case GET_VENDOR_ITEM_MAP_TABLE:
      return {
        ...state,
        map: {
          ...state.map,
          map_history: payload,
          history_loading: false,
        },
      };
    case GET_VENDOR_ITEM_SALES:
      return {
        ...state,
        sales: { ...state.sales, ...payload },
        sales_loading: false,
      };
    case GET_VENDOR_ITEM_SALES_HISTORY:
      return {
        ...state,
        sales: { ...state.sales, ...payload, history_loading: false },
      };
    case GET_VENDOR_ITEM_SALES_COGS:
      return {
        ...state,
        sales: { ...state.sales, cogs_chart: payload, cogs_loading: false },
      };
    case GET_VENDOR_ITEM_SALES_TABLE:
      return {
        ...state,
        sales: {
          ...state.sales,
          table: payload,
          table_loading: false,
        },
      };
    case GET_VENDOR_ITEM_ADV:
      return {
        ...state,
        adv: { ...state.adv, ...payload },
        adv_loading: false,
      };
    case SEARCH_VENDOR_ITEMS:
      return {
        ...state,
        search_results: payload,
        search_loading: false,
      };
    case SET_VENDOR_ITEM_OPTIONS:
      return {
        ...state,
        lqs: {
          ...state.lqs,
          content: payload,
        },
      };
    case SET_LOADING_VENDOR_ITEM_SEARCH:
      return {
        ...state,
        search_loading: true,
      };
    case SET_LOADING_VENDOR_SALES_HISTORY:
      return {
        ...state,
        sales: { ...state.sales, history_loading: true },
      };
    case SET_LOADING_VENDOR_SALES_COGS:
      return {
        ...state,
        sales: { ...state.sales, cogs_loading: true },
      };
    case SET_LOADING_VENDOR_SALES_TABLE:
      return {
        ...state,
        sales: { ...state.sales, table_loading: true },
      };
    case SET_LOADING_VENDOR_ITEM_MAP_CHART:
      return {
        ...state,
        map: { ...state.map, prices_loading: true },
      };
    case SET_LOADING_VENDOR_ITEM_MAP_TABLE:
      return {
        ...state,
        map: { ...state.map, history_loading: true },
      };
    case CLEAR_VENDOR_ITEM:
      return initialState;

    default:
      return state;
  }
}
