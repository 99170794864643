import { compareByText } from '../../hooks/useFilters';

export const filterByItem = ({ payload, allItems }) => {
  let filtered = null;
  if (payload.prop) {
    // Filter by a-z z-a
    filtered = allItems.sort((a, b) => {
      const { method, prop } = payload;
      let propA = a[prop]
        ? prop === 'problems'
          ? a[prop].join(', ')
          : a[prop]
        : '';
      let propB = b[prop]
        ? prop === 'problems'
          ? b[prop].join(', ')
          : b[prop]
        : '';
      if (isNaN(propA)) {
        propA = propA.toLowerCase();
      }
      if (isNaN(propB)) {
        propB = propB.toLowerCase();
      }
      if (method === 'asc') {
        return propA > propB ? 1 : -1;
      } else {
        return propA > propB ? -1 : 1;
      }
    });
  } else {
    filtered = allItems;
  }
  // filter by text ASIN, UPC or MPN
  filtered = filtered.filter((item, index) => {
    const { text } = payload;
    let { asin, upc, mpn } = item;
    if (text) {
      asin = compareByText({ value: asin, text });
      upc = compareByText({ value: upc, text });
      mpn = compareByText({ value: mpn, text });
      return asin || upc || mpn;
    }
    return true;
  });
  //Filter by problems
  if (payload.problems) {
    if (payload.problems.length) {
      filtered = filtered.filter((item) => {
        let foundQty = 0;
        for (let i = 0; i < payload.problems.length; i++) {
          if (payload.problems[i] === 'No Problems') {
            if (!item.problems.length) {
              foundQty++;
            }
          } else if (item.problems.includes(payload.problems[i])) {
            foundQty++;
          }
        }
        if (foundQty === payload.problems.length) {
          return true;
        } else {
          return false;
        }
      });
    }
  }
  return filtered;
};

export const addLqsFilter = ({ payload, state }) => {
  let selectedFilters = [...state.selected_filters];

  if (
    selectedFilters.find(
      (f) => f.name === payload.name && f.values.includes(payload.value)
    )
  ) {
    selectedFilters.forEach((f) => {
      if (f.name === payload.name) {
        const index = f.values.findIndex((value) => payload.value === value);
        f.values.splice(index, 1);
      }
    });
    selectedFilters = selectedFilters.filter((f) => f.values.length);
  } else {
    const filter = selectedFilters.find((f) => f.name === payload.name);

    if (filter) {
      filter.values.push(payload.value);
    } else if (payload.value && payload.name) {
      selectedFilters.push({ name: payload.name, values: [payload.value] });
    }
  }

  //Form an array of selected values only
  let selectedFiltersValues = [];
  selectedFilters.forEach((s) => {
    s.values.forEach((v) =>
      selectedFiltersValues.push({ name: s.name, value: v })
    );
  });

  selectedFiltersValues.sort((a, b) => {
    const textA = a.value ? a.value.toUpperCase() : '';
    const textB = b.value ? b.value.toUpperCase() : '';
    if (textA < textB) return -1;
    if (textA > textB) return 1;
    return 0;
  });

  return [selectedFilters, selectedFiltersValues];
};
