export const urls = {
  user: '/userinfo/',
  contacts: '/contacts/',
  logs: '/logs/',
  chat: '/chat/',
  help: '/help/',
  vendor_item: '/dashboard/vendoritem/',
  lqs: '/dashboard/lqs/amazon/',
  lqsEbay: '/dashboard/lqs/ebay/',
  sqs: '/dashboard/sqs/',
  sellers: '/dashboard/sellers/',
  advertising: '/dashboard/advertising/',
  prices: '/dashboard/prices/',
  sales: '/dashboard/sales/',
  forecast: '/dashboard/forecast/',
  vendors: '/vendors/',
  users: '/users/',
  vendorUsers: '/vendorUsers/',
  userpage: '/dashboard/userpage/',
  vendor_dashboard: '/dashboard/vendordashboard/',
  sellers_comparison: '/dashboard/sellers-comparison/',
  notifications: '/notifications/',
  confirmEmail: '/confirmEmail/:token'
};

export const pagesForSelect = [
  { name: 'LQS Amazon', key: 'lqs' },
  { name: 'LQS Ebay', key: 'lqsEbay' },
  { name: 'SQS Amazon', key: 'sqs' },
  { name: 'Sellers Comparison', key: 'sellers_comparison' },
  { name: 'MAP & Sellers', key: 'sellers' },
  { name: 'Advertising', key: 'advertising' },
  { name: 'Prices', key: 'prices' },
  { name: 'Sales', key: 'sales' },
  { name: 'Forecast', key: 'forecast' },
  { name: 'Notifications', key: 'notifications' }
];

export const noRedirectPublicRoutes = [urls.confirmEmail];

export const otherAccessSelect = [
  { name: 'Prices -> Amazon PPC', key: 'prices_amz_ppc' }
];

export const basicAccess = 'basic';
export const roles = {
  help: 'help',
  support: 'support',
  admin: 'admin',
  vendor: 'vendor',
  vendorUser: 'vendorUser'
};
export const rolesList = [
  { label: 'Admin', value: roles.admin },
  { label: 'Support', value: roles.support },
  { label: 'Help', value: roles.help }
];
