export const helpBtnFields = [
  {
    name: 'question',
    type: 'texteditor',
    placeholder: 'Describe your question and share your thoughts...',
    isRequired: true,
    label: 'Question',
  },
];

export const validateHelpBtnForm = (state = [], changeState) => {
  let isValid = true;

  const newState = state.map((field) => {
    field.isValid = true;

    if (field.isRequired) {
      if (!field.value) {
        field.isValid = false;
        isValid = false;
      } else if (field.name === 'email') {
        if (!field.value.includes('@') || !field.value.includes('.')) {
          field.isValid = false;
          isValid = false;
        }
      }
    }
    return field;
  });

  if (changeState) {
    changeState(newState);
  }

  return isValid;
};

export const getHelpBtnSubject = (user) => {
  let userName = user?.vendorname ? user.vendorname : '';
  if (user?.first_name) {
    userName = `${user.first_name} ${user.last_name ? user.last_name : ''}`;
  }
  return `Question from the Vendor Portal user ${
    user?.email ? user.email : ''
  } (${userName})`;
};
