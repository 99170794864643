import { useMemo } from 'react';
import { roles } from '../../utils/accessPage/accessPage.data';
import { eulaStatuses } from './EulaModal.data';

export const checkVersion = (user) => {
  const eula_current_version = user.eula_current_version?.split('.')[0];
  const eula_version = user.eula_version?.split('.')[0];
  if (
    !isNaN(eula_current_version) &&
    !isNaN(eula_version) &&
    eula_current_version !== eula_version
  ) {
    return true;
  } else {
    return false;
  }
};

export const useEulaVersion = (user) => {
  const needShow = useMemo(() => {
    if (
      ![roles.vendor, roles.vendorUser].includes(user.role) ||
      !user.eula_current_version
    )
      return false;

    if (user.eula_status !== eulaStatuses.accepted) return true;
    const eula_current_version = user.eula_current_version?.split('.')[0];
    const eula_version = user.eula_version?.split('.')[0];
    if (
      !isNaN(eula_current_version) &&
      !isNaN(eula_version) &&
      eula_current_version !== eula_version
    ) {
      return true;
    } else {
      return false;
    }
  }, [user]);
  return needShow;
};
