import {
  GET_LQS_DATA,
  GET_LQS_FILTERS,
  GET_LQS_CHART_DATA,
  CLEAR_LQS_FILTERS,
  ADD_LQS_FILTER,
  SET_LOADING_LQS,
  SET_LOADING_CHART,
  GET_LQS_MINDATE,
  FILTER_LQS_BYITEM,
  CLEAR_LQS,
  HIDE_LQS_CHART_DATA,
  SET_LOADING_LQS_FILTERS,
  CHANGE_LQS_DISCONTINUED
} from '../../actions/types';
import { sortLQSfilters } from '../../utils/reducer.service';
import { addLqsFilter } from './lqs.service';

const initialState = {
  lqs: { average_lqs: null },
  lqs_chart: null,
  lqs_byitem_page: null,
  all_filters: [],
  selected_filters: [],
  selected_filters_values: [],
  available_filters: [],
  lqs_byitem_filtered: [],
  lqs_byitem_filtered_qty: null,
  lqs_min_date: null,
  chart_data_props: {
    lqs: true,
    rank: true,
    reviews: true
  },
  filtered_byitem_props: {
    prop: '',
    method: ''
  },
  filtered_byitem_text: '',
  filtered_byitem_page: 1,
  filtered_byitem_problems: [],
  showDiscontinued: true,
  isFirst: true,
  loading: true,
  loading_filters: false,
  chart_loading: false
};

export default function lqs(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_LQS_DATA:
      return {
        ...state,
        lqs: payload,
        loading: false,
        lqs_byitem_filtered: [],
        filtered_byitem_props: {
          prop: '',
          method: ''
        },
        isFirst: false
      };
    case GET_LQS_FILTERS:
      if (payload.filters) {
        state.all_filters.forEach((f) => {
          sortLQSfilters({
            filter: f,
            selected_filters: state.selected_filters,
            filters: payload.filters
          });
        });
      }

      return {
        ...state,
        all_filters:
          !payload.selected && payload.filters
            ? payload.filters
            : state.all_filters,
        available_filters: payload.filters
          ? payload.filters
          : state.available_filters,
        loading_filters: false
      };
    case ADD_LQS_FILTER:
      const [selectedFilters, selectedFiltersValues] = addLqsFilter({
        payload,
        state
      });

      return {
        ...state,
        selected_filters: selectedFilters,
        selected_filters_values: selectedFiltersValues
      };
    case CLEAR_LQS_FILTERS:
      return {
        ...state,
        selected_filters: [],
        selected_filters_values: []
      };
    case GET_LQS_MINDATE:
      return {
        ...state,
        lqs_min_date: payload
      };
    case GET_LQS_CHART_DATA:
      return {
        ...state,
        lqs_chart: payload,
        chart_loading: false
      };
    case SET_LOADING_LQS:
      return {
        ...state,
        loading: true
      };

    case SET_LOADING_CHART:
      return {
        ...state,
        chart_loading: true
      };
    case SET_LOADING_LQS_FILTERS:
      return {
        ...state,
        loading_filters: true
      };
    case FILTER_LQS_BYITEM:
      return {
        ...state,
        filtered_byitem_props: payload?.props
          ? payload?.props
          : state.filtered_byitem_props,
        filtered_byitem_text:
          payload && payload.text !== undefined
            ? payload.text
            : state.filtered_byitem_text,
        filtered_byitem_page: payload?.page
          ? payload?.page
          : state.filtered_byitem_page,
        filtered_byitem_problems: payload?.problems
          ? payload?.problems
          : state.filtered_byitem_problems
      };
    case HIDE_LQS_CHART_DATA:
      return {
        ...state,
        chart_data_props: payload
      };
    case CHANGE_LQS_DISCONTINUED:
      return {
        ...state,
        showDiscontinued: !state.showDiscontinued
      };
    case CLEAR_LQS:
      return initialState;

    default:
      return state;
  }
}
