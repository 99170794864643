import { DataLoader } from '../Loaders/Loaders';
import classes from './Button.module.css';

export const buttonTypes = {
  USER: classes.userButton,
  LQS: classes.lqsButton,
  DELETE: classes.deleteButton,
  ACTIVATE: classes.activateButton,
  SECONDARY: classes.secondaryButton,
  RESET: classes.resetButton
};

export const Button = ({
  className = '',
  buttonType = '',
  labelClassName = '',
  loading,
  loaderSize = '16px',
  loaderColor = '#fff',
  children,
  ...otherProps
}) => {
  return (
    <button
      className={`${classes.button} ${className} ${buttonType}`}
      {...otherProps}
    >
      {loading && (
        <DataLoader
          size={loaderSize}
          color={loaderColor}
          containerClassName={classes.buttonLoader}
        />
      )}
      {children}
    </button>
  );
};
