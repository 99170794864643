import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import brand from './brand';
import lqs from './lqs/lqs';
import sellers from './sellers';
import seller from './seller';
import dashboard from './dashboard';
import user from './user';
import item from './item';
import sales from './sales';
import logs from './logs';
import chat from './chat';
import advertising from './advertising';
import vendoritem from './vendoritem';
import prices from './prices';
import ebay from './ebay';
import sellers_comparison from './sellers_comparison';
import purchaseOrder from './purchaseOrder';
import notifications from './notifications';
import confirmation from './confirmation';

export default combineReducers({
  alert,
  auth,
  brand,
  user,
  item,
  sales,
  lqs,
  sellers,
  logs,
  chat,
  dashboard,
  advertising,
  vendoritem,
  prices,
  ebay,
  seller,
  sellers_comparison,
  purchaseOrder,
  notifications,
  confirmation
});
