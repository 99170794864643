import { useEffect, Fragment, Suspense } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadUser, setURL } from '../../../actions/auth';
import { urls } from '../../../utils/accessPage/accessPage.data';
import Navbar from '../../../UI/Navbar/Navbar';
import { sendUrlAction } from '../../../analitics/Analytics.service';
import HelpButton from '../../../widgets/HelpButton/HelpButton';
import { roles } from '../../../utils/accessPage/accessPage.data';
import {
  checkIfReport,
  checkAdminUrls,
  checkPageAccess,
  checkHelpAccess,
  checkVendorAccess
} from '../../../utils/accessPage/accessPage.service';
import EulaModal from '../../../widgets/EulaModal/EulaModal';
import { MainLoader } from '../../../UI/Loaders/Loaders';

const PrivateRoute = ({
  component: Component,
  componentProps,
  auth: { isAuthenticated, auth_loading, user, url, hasAdBlocker },
  loadUser,
  setURL,
  ...rest
}) => {
  const location = useLocation();
  const isAdmin =
    location.pathname === urls.vendors || location.pathname === urls.users;

  useEffect(() => {
    if (!url) {
      setURL(`${location.pathname}${location.search}`);
    }
  }, [url, location, setURL]);

  useEffect(() => {
    if (!user.email) {
      loadUser();
    }
  }, [loadUser, user.email]);

  //Analytics Tracker
  useEffect(() => {
    if (user.email && user.role !== roles.help) {
      sendUrlAction(location.pathname, user, hasAdBlocker);
    }
  }, [user, location, hasAdBlocker]);

  if (!auth_loading) {
    if (!isAuthenticated) return <Redirect to="/" />;
    //Logic for vendors access
    if (user.role === roles.vendor) {
      if (checkAdminUrls(location.pathname)) {
        return <Redirect to="/dashboard/" />;
      } else if (checkIfReport(user.access, location.pathname)) {
        return <Redirect to="/dashboard/" />;
      } else if (!checkPageAccess(user.page_access, location.pathname)) {
        return <Redirect to="/dashboard/" />;
      }
    }

    if (user.role === roles.vendorUser) {
      if (checkVendorAccess(location.pathname)) {
        return <Redirect to="/dashboard/" />;
      } else if (!checkPageAccess(user.page_access, location.pathname)) {
        return <Redirect to="/dashboard/" />;
      }
    }

    if (user.role === roles.help) {
      if (!checkHelpAccess(location.pathname)) {
        return <Redirect to="/help/" />;
      }
    }

    if (user.role === roles.support && isAdmin) {
      return <Redirect to="/dashboard/" />;
    }

    return (
      <Route
        {...rest}
        render={(props) => (
          <Fragment>
            <Navbar />
            <Suspense fallback={<MainLoader />}>
              {componentProps ? componentProps : <Component {...props} />}
            </Suspense>
            {user.role !== roles.help && <HelpButton />}
            {(user.role === roles.vendor || user.role === roles.vendorUser) && (
              <EulaModal />
            )}
          </Fragment>
        )}
      />
    );
  } else {
    return <MainLoader />;
  }
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, { loadUser, setURL })(PrivateRoute);
