//Alert variables
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

//Auth variables
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const LOAD_USER = 'LOAD_USER';
export const ADD_LOADING = 'ADD_LOADING';
export const SET_URL = 'SET_URL';
export const SET_AVATAR_URL = 'SET_AVATAR_URL';
export const REMOVE_AVATAR_URL = 'REMOVE_AVATAR_URL';
export const SET_EMAIL_LOADING = 'SET_EMAIL_LOADING';
export const SET_CAPTCHA_LOADING = 'SET_CAPTCHA_LOADING';
export const GET_CAPTCHA_SCORE = 'GET_CAPTCHA_SCORE';
export const CHECK_RESET_TOKEN = 'CHECK_RESET_TOKEN';
export const START_TRACKING = 'START_TRACKING';
export const CHANGE_EMAIL = 'CHANGE_EMAIL';
export const SET_CHANGE_EMAIL_LOADING = 'SET_CHANGE_EMAIL_LOADING';
export const SET_CHANGE_PASSWORD_LOADING = 'SET_CHANGE_PASSWORD_LOADING';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const PROHIBITED_EMAIL_ACCEPTED = 'PROHIBITED_EMAIL_ACCEPTED';

//Brand variables
export const SET_ACTIVE_BRAND = 'SET_ACTIVE_BRAND';
export const GET_BRANDS_LIST = 'GET_BRANDS_LIST';
export const CLEAR_BRAND = 'CLEAR_BRAND';
export const SET_LOADING_BRANDS = 'SET_LOADING_BRANDS';
export const CHECK_REPORT_AVAILABILITY = 'CHECK_REPORT_AVAILABILITY';
export const GET_BRAND_DETAILS = 'GET_BRAND_DETAILS';
export const GET_BRAND_USER_DETAILS = 'GET_BRAND_USER_DETAILS';

//Dashboard variables
export const GET_DASHBOARD_LQS = 'GET_DASHBOARD_LQS';
export const SET_DASHBOARD_LOADING_LQS = 'SET_DASHBOARD_LOADING_LQS';
export const GET_DASHBOARD_SELLERS = 'GET_DASHBOARD_SELLERS';
export const SET_DASHBOARD_LOADING_SELLERS = 'SET_DASHBOARD_LOADING_SELLERS';
export const GET_DASHBOARD_ADV = 'GET_DASHBOARD_ADV';
export const SET_DASHBOARD_LOADING_ADV = 'SET_DASHBOARD_LOADING_ADV';
export const GET_DASHBOARD_SALES = 'GET_DASHBOARD_SALES';
export const SET_DASHBOARD_LOADING_SALES = 'SET_DASHBOARD_LOADING_SALES';
export const GET_LQS_SPEED = 'GET_LQS_SPEED';
export const GET_ADV_SPEED = 'GET_ADV_SPEED';
export const GET_SALES_SPEED = 'GET_SALES_SPEED';
export const GET_SELLERS_SPEED = 'GET_SELLERS_SPEED';
export const CLEAR_DASHBOARD = 'CLEAR_DASHBOARD';
export const CHECK_DASHBOARD = 'CHECK_DASHBOARD';
export const GET_DASHBOARD_SQS = 'GET_DASHBOARD_SQS';

//User Variables
export const ADD_USER_LOADING = 'ADD_USER_LOADING';
export const GET_USER = 'GET_USER';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_ITEMS = 'GET_ALL_ITEMS';
export const GET_ALL_VENDORS = 'GET_ALL_VENDORS';
export const CLEAR_USER = 'CLEAR_USER';
export const BUTTON_LOADING = 'BUTTON_LOADING';
export const USERS_LOADING = 'USERS_LOADING';
export const ITEMS_LOADING = 'ITEMS_LOADING';
export const MODAL_LOADING = 'MODAL_LOADING';
export const BRANDS_LOADING = 'BRANDS_LOADING';
export const GET_ALL_BRANDS = 'GET_ALL_BRANDS';
export const SET_EDIT_USER = 'SET_EDIT_USER';
export const GET_EDIT_USER = 'GET_EDIT_USER';
export const SAVE_FILTER = 'SAVE_FILTER';
export const UPDATE_USER = 'UPDATE_USER';
export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const CREATE_VENDOR = 'CREATE_VENDOR';
export const DELETE_VENDOR = 'DELETE_VENDOR';
export const GET_USER_PAGE = 'GET_USER_PAGE';
// Vendor User Variables
export const GET_VENDOR_USERS = 'GET_VENDOR_USERS';
export const UPDATE_VENDOR_USER = 'UPDATE_VENDOR_USER';
export const DELETE_VENDOR_USER = 'DELETE_VENDOR_USER';
export const CREATE_VENDOR_USER = 'CREATE_VENDOR_USER';

//Item Variables
export const SET_ACTIVE_ITEM = 'SET_ACTIVE_ITEM';
export const GET_ITEM = 'GET_ITEM';
export const SET_ITEM_LOADING = 'SET_ITEM_LOADING';
export const SET_CASE = 'SET_CASE';
export const CHANGE_CASE = 'CHANGE_CASE';
export const REMOVE_CASE = 'REMOVE_CASE';
export const CLEAR_ITEM = 'CLEAR_ITEM';

//Sales Variables
export const GET_SALES_DATA = 'GET_SALES_DATA';
export const GET_SALES_TABLE = 'GET_SALES_TABLE';
export const SET_LOADING_SALES_TABLE = 'SET_LOADING_SALES_TABLE';
export const CLEAR_SALES = 'CLEAR_SALES';
export const GET_SALES_CHART = 'GET_SALES_CHART';
export const SET_LOADING_SALES_CHART = 'SET_LOADING_SALES_CHART';
export const GET_INVENTORY_ORDERS_CHART = 'GET_INVENTORY_ORDERS_CHART';
export const SET_LOADING_INVENTORY_ORDERS_CHART =
  'SET_LOADING_INVENTORY_ORDERS_CHART';
export const GET_SALES_MAP = 'GET_SALES_MAP';
export const SET_LOADING_SALES_MAP = 'SET_LOADING_SALES_MAP';
export const GET_INVENTORY_TABLE = 'GET_INVENTORY_TABLE';
export const SET_LOADING_INVENTORY_TABLE = 'SET_LOADING_INVENTORY_TABLE';
export const GET_INVENTORY_CHART = 'GET_INVENTORY_CHART';
export const SET_LOADING_INVENTORY_CHART = 'SET_LOADING_INVENTORY_CHART';

//Sellers Variables
export const GET_MAP_TABLE = 'GET_MAP_TABLE';
export const GET_MAP_CHARTS = 'GET_MAP_CHARTS';
export const SET_LOADING_SELLERS = 'SET_LOADING_SELLERS';
export const CLEAR_SELLERS = 'CLEAR_SELLERS';
export const SET_OFFERS_DATE = 'SET_OFFERS_DATE';
export const FILTER_OFFERS = 'FILTER_OFFERS';
export const GET_SELLERS_DATA = 'GET_SELLERS_DATA';
export const GET_SELLERS_CHARTS = 'GET_SELLERS_CHARTS';
export const SET_SELLERS_CHARTS_LOADING = 'SET_SELLERS_CHARTS_LOADING';
export const SET_SELLERS_DATE = 'SET_SELLERS_DATE';
export const GET_SELLERS_REPORT = 'GET_SELLERS_REPORT';
export const SET_SELLERS_REPORT_LOADING = 'SET_SELLERS_REPORT_LOADING';
export const GET_AVAILABLE_REPORT_DATES = 'GET_AVAILABLE_REPORT_DATES';
export const SET_LOADING_TABLE = 'SET_LOADING_TABLE';
export const HIDE_PRODUCTS_CHART_DATA = 'HIDE_PRODUCTS_CHART_DATA';
export const HIDE_SELLERS_TABLE_DATA = 'HIDE_SELLERS_TABLE_DATA';
export const HIDE_SELLERS_TABLE_USED = 'HIDE_SELLERS_TABLE_USED';
export const HIDE_OFFERS_CHART_DATA = 'HIDE_OFFERS_CHART_DATA';
export const HIDE_SELLERS_CHART_DATA = 'HIDE_SELLERS_CHART_DATA';
export const GET_SELLERS_MODAL = 'GET_SELLERS_MODAL';

// LQS Variables
export const CLEAR_LQS = 'CLEAR_LQS';
export const GET_LQS_DATA = 'GET_LQS_DATA';
export const GET_LQS_CHART_DATA = 'GET_LQS_CHART_DATA';
export const GET_LQS_MINDATE = 'GET_LQS_MINDATE';
export const GET_LQS_FILTERS = 'GET_LQS_FILTERS';
export const ADD_LQS_FILTER = 'ADD_LQS_FILTER';
export const CLEAR_LQS_FILTERS = 'CLEAR_LQS_FILTERS';
export const SET_LOADING_CHART = 'SET_LOADING_CHART';
export const SET_LOADING_LQS = 'SET_LOADING_LQS';
export const SET_LOADING_LQS_FILTERS = 'SET_LOADING_LQS_FILTERS';
export const FILTER_LQS_BYITEM = 'FILTER_LQS_BYITEM';
export const HIDE_LQS_CHART_DATA = 'HIDE_LQS_CHART_DATA';
export const CHANGE_LQS_DISCONTINUED = 'CHANGE_LQS_DISCONTINUED';

// Ebay LQS
export const GET_EBAY_LQS_DATA = 'GET_EBAY_LQS_DATA';
export const GET_EBAY_LQS_CHART = 'GET_EBAY_LQS_CHART';
export const GET_EBAY_LQS_MINDATE = 'GET_EBAY_LQS_MINDATE';
export const SET_LOADING_EBAY_LQS = 'SET_LOADING_EBAY_LQS';
export const SET_LOADING_EBAY_LQS_CHART = 'SET_LOADING_EBAY_LQS_CHART';
export const CLEAR_EBAY_LQS = 'CLEAR_EBAY_LQS';

//Vendor ITEM
export const SET_VENDOR_ITEM = 'SET_VENDOR_ITEM';
export const SET_VENDOR_ITEM_OPTIONS = 'SET_VENDOR_ITEM_OPTIONS';
export const CHECK_ACCESS_VENDOR_ITEM = 'CHECK_ACCESS_LQS_ITEM';
export const CLEAR_VENDOR_ITEM = 'CLEAR_VENDOR_ITEM';
export const GET_VENDOR_ITEM_LQS = 'GET_VENDOR_ITEM_LQS';
export const GET_VENDOR_ITEM_ADV = 'GET_VENDOR_ITEM_ADV';
export const SEARCH_VENDOR_ITEMS = 'SEARCH_VENDOR_ITEMS';
export const SET_LOADING_VENDOR_ITEM_SEARCH = 'SET_LOADING_VENDOR_ITEM_SEARCH';
export const GET_VENDOR_ITEM_SALES = 'GET_VENDOR_ITEM_SALES';
export const GET_VENDOR_ITEM_SALES_HISTORY = 'GET_VENDOR_ITEM_SALES_HISTORY';
export const GET_VENDOR_ITEM_SALES_COGS = 'GET_VENDOR_ITEM_SALES_COGS';
export const GET_VENDOR_ITEM_SALES_TABLE = 'GET_VENDOR_ITEM_SALES_TABLE';
export const SET_LOADING_VENDOR_SALES_HISTORY =
  'SET_LOADING_VENDOR_SALES_HISTORY';
export const SET_LOADING_VENDOR_SALES_COGS = 'SET_LOADING_VENDOR_SALES_COGS';
export const SET_LOADING_VENDOR_SALES_TABLE = 'SET_LOADING_VENDOR_SALES_TABLE';
export const FILTER_VENDOR_SALES_TABLE = 'FILTER_VENDOR_SALES_TABLE';
export const GET_VENDOR_ITEM_MAP = 'GET_VENDOR_ITEM_MAP';
export const GET_VENDOR_ITEM_MAP_CHART = 'GET_VENDOR_ITEM_MAP_CHART';
export const SET_LOADING_VENDOR_ITEM_MAP_CHART =
  'SET_LOADING_VENDOR_ITEM_MAP_CHART';
export const GET_VENDOR_ITEM_MAP_TABLE = 'GET_VENDOR_ITEM_MAP_TABLE';
export const SET_LOADING_VENDOR_ITEM_MAP_TABLE =
  'SET_LOADING_VENDOR_ITEM_MAP_TABLE';
export const FILTER_MAP_CHART_SELLERS = 'FILTER_MAP_CHART_SELLERS';

// Chat Variables
export const GET_CHAT_USERS = 'GET_CHAT_USERS';
export const GET_USER_MESSAGES = 'GET_USER_MESSAGES';
export const GET_ONLINE_USERS = 'GET_ONLINE_USERS';
export const GET_CHAT_MESSAGE = 'GET_CHAT_MESSAGE';
export const READ_MESSAGES = 'READ_MESSAGES';
export const SET_ACTIVE_CHAT_USER = 'SET_ACTIVE_CHAT_USER';
export const SET_LOADING_CHAT = 'SET_LOADING_CHAT';
export const SET_LOADING_MESSAGES = 'SET_LOADING_MESSAGES';
export const CLEAR_ACTIVE_MESSAGES = 'CLEAR_ACTIVE_MESSAGES';
export const CLEAR_CHAT = 'CLEAR_CHAT';
export const GET_UNREAD_MESSAGES = 'GET_UNREAD_MESSAGES';
export const GET_WSS_CONNECTION = 'GET_WSS_CONNECTION';
export const SORT_CHAT_USERS = 'SORT_CHAT_USERS';

//Advertising variables
export const GET_ADVERTISING = 'GET_ADVERTISING';
export const GET_ADVERTISING_TABLE = 'GET_ADVERTISING_TABLE';
export const GET_ADV_KEYWORDS = 'GET_ADV_KEYWORDS';
export const SET_ADV_KEYWORDS_LOADING = 'SET_ADV_KEYWORDS_LOADING';
export const FILTER_ADV_KEYWORDS = 'FILTER_ADV_KEYWORDS';
export const GET_ADV_MIN_DATES = 'GET_ADV_MIN_DATES';
export const GET_ADV_CALCULATOR = 'GET_ADV_CALCULATOR';
export const SET_ADV_CALCULATOR_LOADING = 'SET_ADV_CALCULATOR_LOADING';
export const SET_LOADING_ADVERTISING = 'SET_LOADING_ADVERTISING';
export const SET_LOADING_ADVERTISING_TABLE = 'SET_LOADING_ADVERTISING_TABLE';
export const FILTER_ADVERTISING_TABLE = 'FILTER_ADVERTISING_TABLE';
export const CLEAR_ADVERTISING = 'CLEAR_ADVERTISING';

// Seller variables
export const GET_SELLER = 'GET_SELLER';
export const SET_LOADING_SELLER = 'SET_LOADING_SELLER';
export const GET_SELLER_LIFETIME = 'GET_SELLER_LIFETIME';
export const SET_LOADING_SELLER_LIFETIME = 'SET_LOADING_SELLER_LIFETIME';
export const GET_SELLER_BRANDS = 'GET_SELLER_BRANDS';
export const GET_SELLER_OFFERS = 'GET_SELLER_OFFERS';
export const SET_LOADING_SELLER_OFFERS = 'SET_LOADING_SELLER_OFFERS';
export const GET_SELLER_DATES = 'GET_SELLER_DATES';
export const SET_LOADING_SELLER_DATES = 'SET_LOADING_SELLER_DATES';
export const GET_SELLER_SQSCHART = 'GET_SELLER_SQSCHART';
export const SET_LOADING_SELLER_SQSCHART = 'SET_LOADING_SELLER_SQSCHART';
export const CLEAR_SELLER = 'CLEAR_SELLER';

// Logs variables
export const GET_ALL_LOGS = 'GET_ALL_LOGS';
export const SET_LOADING_LOGS = 'SET_LOADING_LOGS';
export const FILTER_VENDOR_LOGS = 'FILTER_VENDOR_LOGS';
export const FILTER_USER_LOGS = 'FILTER_USER_LOGS';
export const CLEAR_LOGS = 'CLEAR_LOGS';
export const GET_STATISTICS_DAU = 'GET_STATISTICS_DAU';
export const GET_ALL_EULA = 'GET_ALL_EULA';
export const ADD_EULA = 'ADD_EULA';
export const SET_LOADING_ADD_EULA = 'SET_LOADING_ADD_EULA';
export const DELETE_EULA = 'DELETE_EULA';

//Prices variables
export const GET_PRICES_LQS = 'GET_PRICES_LQS';
export const GET_PRICES_AMZ = 'GET_PRICES_AMZ';
export const OPEN_PRICES_LQS_TABLE = 'OPEN_PRICES_LQS_TABLE';
export const CLEAR_PRICES = 'CLEAR_PRICES';

//Sellers Comparison variables
export const GET_SELLERS_COMPARISON = 'GET_SELLERS_COMPARISON';
export const CLEAR_SELLERS_COMPARISON = 'CLEAR_SELLERS_COMPARISON';
export const CHANGE_SELLERS_IN_COMPARISON = 'CHANGE_SELLERS_IN_COMPARISON';
export const GET_SQS_CHART_COMPARISON = 'GET_SQS_CHART_COMPARISON';
export const SET_LOADING_SQS_CHART_COMPARISON =
  'SET_LOADING_SQS_CHART_COMPARISON';
export const GET_OFFERS_CHART_COMPARISON = 'GET_OFFERS_CHART_COMPARISON';
export const SET_LOADING_OFFERS_CHART_COMPARISON =
  'SET_LOADING_OFFERS_CHART_COMPARISON';
export const GET_MAP_CHART_COMPARISON = 'GET_MAP_CHART_COMPARISON';
export const SET_LOADING_MAP_CHART_COMPARISON =
  'SET_LOADING_MAP_CHART_COMPARISON';

//Purchase Order variables
export const GET_PURCHASE_ORDER = 'GET_PURCHASE_ORDER';
export const CLEAR_PURCHASE_ORDER = 'CLEAR_PURCHASE_ORDER';

// Notifications variables
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_LOGS = 'GET_NOTIFICATIONS_LOGS';
export const SET_NOTIFICATIONS_LOGS_LOADING = 'SET_NOTIFICATIONS_LOGS_LOADING';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const SET_UPDATE_NOTIFICATION_LOADING =
  'SET_UPDATE_NOTIFICATION_LOADING';
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const GET_AUTH_SELLERS = 'GET_AUTH_SELLERS';
export const ADD_AUTH_SELLERS = 'ADD_AUTH_SELLERS';
export const SET_ADD_AUTH_SELLERS_LOADING = 'SET_ADD_AUTH_SELLERS_LOADING';
export const CHANGE_AUTH_SELLERS = 'CHANGE_AUTH_SELLERS';
export const SET_CHANGE_AUTH_SELLERS_LOADING =
  'SET_CHANGE_AUTH_SELLERS_LOADING';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

// Confirmation variables
export const CHECK_CONFIRMATION_TOKEN = 'CHECK_CONFIRMATION_TOKEN';
export const SET_CONFIRMATION_LOADING = 'SET_CONFIRMATION_LOADING';
export const SET_CONFIRMATION_OPEN = 'SET_CONFIRMATION_OPEN';
export const SET_CONFIRMATION_MESSAGE_OPEN = 'SET_CONFIRMATION_MESSAGE_OPEN';
export const CLEAR_CONFIRMATION = 'CLEAR_CONFIRMATION';
export const CONFIRM_EMAIL_TAB = 'CONFIRM_EMAIL_TAB';
