import { roles } from '../../utils/accessPage/accessPage.data';

export const vendorNav = [
  { name: 'Home', url: '/dashboard/', icon: 'home' },
  { type: 'devider' },
  {
    name: 'Listing Quality',
    type: 'list',
    icon: 'lqs',
    list: [
      {
        name: 'Amazon Listing Quality',
        url: '/dashboard/lqs/amazon/',
        icon: 'sublist',
        needAccess: true,
        pageAccess: 'lqs'
      },
      {
        name: 'eBay Listing Quality',
        url: '/dashboard/lqs/ebay/',
        icon: 'sublist',
        needAccess: true,
        pageAccess: 'lqsEbay'
      }
    ]
  },
  {
    name: 'MAP Violation & Sellers',
    url: '/dashboard/sellers/',
    icon: 'map',
    needAccess: true,
    pageAccess: 'sellers'
  },
  {
    name: 'Sellers Quality Score',
    url: '/dashboard/sqs/',
    icon: 'sqs',
    needAccess: true,
    pageAccess: 'sqs',
    customIcon: true
  },
  {
    name: 'Sellers Comparison',
    url: '/dashboard/sellers-comparison/',
    icon: 'sellers_comparison',
    needAccess: true,
    pageAccess: 'sellers_comparison'
  },
  {
    name: 'Sales & Inventory',
    url: '/dashboard/sales/',
    icon: 'sales',
    needAccess: true,
    pageAccess: 'sales'
  },
  {
    name: 'Advertising',
    url: '/dashboard/advertising/',
    icon: 'adv',
    needAccess: true,
    pageAccess: 'advertising'
  },
  { type: 'devider' },
  {
    name: 'Prices',
    url: '/dashboard/prices/',
    icon: 'prices',
    needAccess: true,
    pageAccess: 'prices'
  },
  { type: 'devider', pageAccess: 'prices' },
  {
    name: 'Notifications',
    url: '/notifications/',
    icon: 'notifications',
    needAccess: true,
    pageAccess: 'notifications',
    customIcon: true
  },
  // {
  //   name: 'Forecast',
  //   url: '/dashboard/forecast/',
  //   icon: 'forecast',
  //   needAccess: true,
  //   pageAccess: 'forecast',
  // },
  {
    name: 'Users Management',
    url: '/vendorUsers/',
    icon: 'users',
    needAccess: true,
    roleAccess: roles.vendor,
    customIcon: true
  },
  { type: 'devider', pageAccess: 'forecast' },
  { name: 'Help', url: '/help/', icon: 'help' }
];

export const adminNav = [
  { name: 'Home', url: '/dashboard/', icon: 'home' },
  { type: 'devider' },
  {
    name: 'Listing Quality',
    type: 'list',
    icon: 'lqs',
    list: [
      {
        name: 'Amazon Listing Quality',
        url: '/dashboard/lqs/amazon/',
        icon: 'sublist'
      },
      {
        name: 'eBay Listing Quality',
        url: '/dashboard/lqs/ebay/',
        icon: 'sublist'
      }
    ]
  },
  {
    name: 'MAP Violation & Sellers',
    url: '/dashboard/sellers/',
    icon: 'map'
  },
  {
    name: 'Sellers Quality Score',
    url: '/dashboard/sqs/',
    icon: 'sqs'
  },
  {
    name: 'Sellers Comparison',
    url: '/dashboard/sellers-comparison/',
    icon: 'sellers_comparison'
  },
  {
    name: 'Sales & Inventory',
    url: '/dashboard/sales/',
    icon: 'sales'
  },
  {
    name: 'Advertising',
    url: '/dashboard/advertising/',
    icon: 'adv'
  },
  { type: 'devider' },
  {
    name: 'Prices',
    url: '/dashboard/prices/',
    icon: 'prices'
  },
  { type: 'devider' },
  {
    name: 'Notifications',
    url: '/notifications/',
    icon: 'notifications'
  },
  // {
  //   name: 'Forecast',
  //   url: '/dashboard/forecast/',
  //   icon: 'forecast'
  // },
  { type: 'devider' },
  {
    name: 'Vendors',
    url: '/vendors/',
    icon: 'vendors',
    needAccess: true,
    customIcon: true
  },
  {
    name: 'Users',
    url: '/users/',
    icon: 'users',
    needAccess: true,
    customIcon: true
  },
  { name: 'Logs', url: '/logs/', icon: 'logs' },
  { name: 'Help', url: '/help/', icon: 'help' }
];

export const helpNav = [{ name: 'Help', url: '/help/', icon: 'help' }];
