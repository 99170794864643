import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';
import { actionErrorHandler } from '../utils/global.services';

import {
  GET_LQS_DATA,
  GET_LQS_FILTERS,
  GET_LQS_CHART_DATA,
  CLEAR_LQS_FILTERS,
  ADD_LQS_FILTER,
  SET_LOADING_LQS,
  SET_LOADING_CHART,
  FILTER_LQS_BYITEM,
  GET_LQS_MINDATE,
  CLEAR_LQS,
  HIDE_LQS_CHART_DATA,
  SET_LOADING_LQS_FILTERS,
  CHANGE_LQS_DISCONTINUED,
} from './types';

const CancelToken = axios.CancelToken;
let cancelData = null;
let cancelChart = null;

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

//Get Data for LQS page
export const getLQS = (data) => async (dispatch) => {
  setAuthToken(localStorage.token);
  try {
    dispatch({ type: SET_LOADING_LQS });

    if (cancelData) {
      cancelData();
      cancelData = null;
    }

    const config2 = {
      ...config,
      cancelToken: new CancelToken(function executor(c) {
        cancelData = c;
      }),
    };

    const body = JSON.stringify(data);
    const res = await axios.post('/api/mysql/lqs', body, config2);

    dispatch({
      type: GET_LQS_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_LQS_DATA,
      payload: {},
    });
    actionErrorHandler(err, dispatch, setAlert, SET_LOADING_LQS);
  }
};

//Get Data for LQS page
export const getLQSFilters = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_LQS_FILTERS,
    });

    const body = JSON.stringify(data);
    const res = await axios.post('/api/mysql/lqs/filters', body, config);

    dispatch({
      type: GET_LQS_FILTERS,
      payload: { filters: res.data, selected: data.selectedOption },
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      //console.log(err);
      dispatch({
        type: SET_LOADING_LQS_FILTERS,
      });

      return;
    }

    dispatch({
      type: GET_LQS_FILTERS,
      payload: {},
    });
  }
};

export const getLQSminDate = (name) => async (dispatch) => {
  //setAuthToken(localStorage.token);
  try {
    const res = await axios.get(
      `/api/mysql/lqs/mindate?name=${encodeURIComponent(name)}`
    );

    dispatch({
      type: GET_LQS_MINDATE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_LQS_MINDATE,
      payload: '',
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
};

//Get Data for LQS page
export const getLQSChart = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_CHART,
    });

    if (cancelChart) {
      cancelChart();
      cancelChart = null;
    }

    const config2 = {
      ...config,
      cancelToken: new CancelToken(function executor(c) {
        cancelChart = c;
      }),
    };

    const body = JSON.stringify(data);
    const res = await axios.post('/api/mysql/lqs/chart', body, config2);

    dispatch({
      type: GET_LQS_CHART_DATA,
      payload: res.data,
    });
  } catch (err) {
    actionErrorHandler(err, dispatch, setAlert, SET_LOADING_CHART);
  }
};

//Clear Brand
export const clearLQS = () => (dispatch) => {
  dispatch({
    type: CLEAR_LQS,
  });
};

//Add Filter for LQS report
export const addLQSFilter = (data) => (dispatch) => {
  dispatch({
    type: ADD_LQS_FILTER,
    payload: data,
  });
};

//Filter LQS By Item Table
export const filterLQSbyItem = (data) => (dispatch) => {
  dispatch({
    type: FILTER_LQS_BYITEM,
    payload: data,
  });
};

// Hide/Show LQS Chart data
export const hideLQSchartData = (props) => (dispatch) => {
  dispatch({
    type: HIDE_LQS_CHART_DATA,
    payload: props,
  });
};

// Change LSQ filter switcher
export const changeLQSDiscontinued = () => (dispatch) => {
  dispatch({
    type: CHANGE_LQS_DISCONTINUED,
  });
};

// Clear LQS filters
export const clearLQSFilters = () => (dispatch) => {
  dispatch({
    type: CLEAR_LQS_FILTERS,
  });
};
