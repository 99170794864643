import { AiOutlineOrderedList, AiOutlineUnorderedList } from 'react-icons/ai';
import { BsTextCenter, BsTextLeft, BsTextRight } from 'react-icons/bs';

export const textEditorTools = [
  { name: 'bold', command: 'bold', icon: 'B', className: 'textEditorBold' },
  {
    name: 'italic',
    command: 'italic',
    icon: 'I',
    className: 'textEditorItalic',
  },
  {
    name: 'underline',
    command: 'underline',
    icon: 'U',
    className: 'textEditorUnderlined',
    isDevider: true,
  },
  {
    name: 'orderedList',
    command: 'insertOrderedList',
    icon: <AiOutlineOrderedList />,
  },
  {
    name: 'unorderedList',
    command: 'insertUnorderedList',
    icon: <AiOutlineUnorderedList />,
    isDevider: true,
  },
  {
    name: 'justifyLeft',
    command: 'justifyLeft',
    icon: <BsTextLeft />,
    type: 'justify',
  },
  {
    name: 'justifyCenter',
    command: 'justifyCenter',
    icon: <BsTextCenter />,
    type: 'justify',
  },
  {
    name: 'justifyRight',
    command: 'justifyRight',
    icon: <BsTextRight />,
    type: 'justify',
  },
];

export const checkToolSelected = (values = {}) => {
  for (const key in values) {
    if (values[key]) return true;
  }
  return false;
};

export const getCommandsState = () => {
  const newState = {};
  textEditorTools.forEach(
    (t) => (newState[t.name] = document.queryCommandState(t.command))
  );
  return newState;
};
