//'bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top'

export const TooltipPlacement = {
  TOP: 'top',
  TOP_END: 'top-end',
  BOTTOM: 'bottom',
  BOTTOM_END: 'bottom-end',
  RIGHT: 'right'
};
const heightDiff = 12;
const widthDiff = 20;

const checkLeftCoord = ({ leftCalculated, windowWidth, width }) => {
  if (leftCalculated + width > windowWidth) {
    const newLeft = windowWidth - width - widthDiff;
    return newLeft > 0 ? newLeft : leftCalculated;
  }

  return leftCalculated;
};

export const tooltipPlacementCalc = (
  placement,
  { targetHeight, targetWidth, targetX, targetY, height, windowWidth, width }
) => {
  if (placement === TooltipPlacement.TOP) {
    return {
      top: `${targetY - height - heightDiff}px`,
      left: `${checkLeftCoord({
        leftCalculated: targetX,
        windowWidth,
        width
      })}px`
    };
  }
  if (placement === TooltipPlacement.TOP_END) {
    return {
      top: `${targetY - height - heightDiff}px`,
      left: `${checkLeftCoord({
        leftCalculated: targetX + targetWidth,
        windowWidth,
        width
      })}px`
    };
  }
  if (placement === TooltipPlacement.BOTTOM_END) {
    return {
      top: `${targetY + targetHeight + heightDiff}px`,
      left: `${checkLeftCoord({
        leftCalculated: targetX + targetWidth,
        windowWidth,
        width
      })}px`
    };
  }
  if (placement === TooltipPlacement.RIGHT) {
    return {
      top: `${targetY + Math.round(targetHeight / 2) - heightDiff}px`,
      left: `${checkLeftCoord({
        leftCalculated: targetX + targetWidth + heightDiff,
        windowWidth,
        width
      })}px`
    };
  }

  return {
    top: `${targetY + targetHeight + heightDiff}px`,
    left: `${checkLeftCoord({
      leftCalculated: targetX,
      windowWidth,
      width
    })}px`
  };
};
