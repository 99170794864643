import {
  GET_PRICES_LQS,
  OPEN_PRICES_LQS_TABLE,
  CLEAR_PRICES,
  GET_PRICES_AMZ,
} from '../actions/types';

const initialState = {
  lqs: {},
  amazon: {},
  lqs_open: false,
  loading_lqs: true,
  loading_amazon: true,
};

export default function brand(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_PRICES_LQS:
      return {
        ...state,
        lqs: payload,
        loading_lqs: false,
      };
    case GET_PRICES_AMZ:
      return {
        ...state,
        amazon: payload,
        loading_amazon: false,
      };
    case OPEN_PRICES_LQS_TABLE:
      return {
        ...state,
        lqs_open: payload,
      };
    case CLEAR_PRICES:
      return initialState;

    default:
      return state;
  }
}
