import setAuthToken from './utils/setAuthToken';
import WebSocketConnection from './providers/WebSocketConnection/WebSocketConnection';
import { Provider } from 'react-redux';
import store from './store';
import { AppRoutes } from './providers/AppRoutes/AppRoutes';
import EventHandler from './providers/EventHandler/EventHandler';
import AlertBlock from './widgets/AlertBlock/AlertBlock';
//Styles
import './css/Main.css';
import './css/AllPages.css';
import './css/Login.css';
import './css/Dashboard.css';
import './css/Components.css';
import './css/Logs.css';
import './css/Advertising.css';
import './css/LQS.css';
import './css/VendorItem.css';
import './css/Prices.css';
import 'react-datepicker/dist/react-datepicker.css';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  return (
    <Provider store={store}>
      <WebSocketConnection />
      <EventHandler />
      <AlertBlock />
      <AppRoutes />
    </Provider>
  );
};
export default App;
