import {
  SET_CONFIRMATION_LOADING,
  CHECK_CONFIRMATION_TOKEN,
  SET_CONFIRMATION_OPEN,
  SET_CONFIRMATION_MESSAGE_OPEN,
  CONFIRM_EMAIL_TAB,
  CLEAR_CONFIRMATION
} from '../actions/types';

const initialState = {
  isOpenModal: false,
  isOpenConfirm: false,
  emailConfirmed: false,
  checkLoading: true,
  loading: false
};

export default function confirmation(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_CONFIRMATION_LOADING:
      return {
        ...state,
        loading: payload
      };
    case CHECK_CONFIRMATION_TOKEN:
      return {
        ...state,
        emailConfirmed: payload ? true : false,
        checkLoading: false
      };
    case CONFIRM_EMAIL_TAB:
      return {
        ...state,
        emailConfirmed: payload
      };
    case SET_CONFIRMATION_OPEN:
      return {
        ...state,
        isOpenModal: payload
      };
    case SET_CONFIRMATION_MESSAGE_OPEN:
      return {
        ...state,
        isOpenConfirm: payload
      };
    case CLEAR_CONFIRMATION:
      return initialState;

    default:
      return state;
  }
}
