import {
  GET_EBAY_LQS_DATA,
  GET_EBAY_LQS_CHART,
  GET_EBAY_LQS_MINDATE,
  SET_LOADING_EBAY_LQS,
  SET_LOADING_EBAY_LQS_CHART,
  CLEAR_EBAY_LQS,
} from '../actions/types';

const initialState = {
  lqs: {},
  lqs_chart: [],
  lqs_min_date: null,
  loading_lqs: true,
  loading_lqs_chart: true,
};

export default function ebay(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_EBAY_LQS_DATA:
      return {
        ...state,
        lqs: payload,
        loading_lqs: false,
      };
    case GET_EBAY_LQS_MINDATE:
      return {
        ...state,
        lqs_min_date: payload,
      };
    case GET_EBAY_LQS_CHART:
      return {
        ...state,
        lqs_chart: payload,
        loading_lqs_chart: false,
      };
    case SET_LOADING_EBAY_LQS:
      return {
        ...state,
        loading_lqs: true,
      };

    case SET_LOADING_EBAY_LQS_CHART:
      return {
        ...state,
        loading_lqs_chart: true,
      };
    case CLEAR_EBAY_LQS:
      return initialState;

    default:
      return state;
  }
}
