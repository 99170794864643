import axios from 'axios';
import { setAlert } from './alert';
import { actionErrorHandler } from '../utils/global.services';
import {
  CLEAR_NOTIFICATIONS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_LOGS,
  SET_NOTIFICATIONS_LOGS_LOADING,
  UPDATE_NOTIFICATION,
  CREATE_NOTIFICATION,
  SET_UPDATE_NOTIFICATION_LOADING,
  GET_AUTH_SELLERS,
  ADD_AUTH_SELLERS,
  SET_ADD_AUTH_SELLERS_LOADING,
  CHANGE_AUTH_SELLERS,
  SET_CHANGE_AUTH_SELLERS_LOADING
} from './types';

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

//Get All Notifications
export const getNotifications = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/notification`);

    dispatch({
      type: GET_NOTIFICATIONS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_NOTIFICATIONS,
      payload: { notifications: [], types: [] }
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
};

//Get Notifications Logs
export const getNotificationsLogs =
  ({ from, to }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SET_NOTIFICATIONS_LOGS_LOADING
      });

      const res = await axios.get(
        `/api/notification/logs?from=${from}&to=${to}`
      );

      dispatch({
        type: GET_NOTIFICATIONS_LOGS,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: GET_NOTIFICATIONS_LOGS,
        payload: []
      });
      actionErrorHandler(err, dispatch, setAlert);
    }
  };

//Add Notification
export const createNotification = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_UPDATE_NOTIFICATION_LOADING,
      payload: true
    });
    const body = JSON.stringify(data);
    const res = await axios.post('/api/notification', body, config);

    dispatch({
      type: CREATE_NOTIFICATION,
      payload: res.data
    });
    return true;
  } catch (err) {
    dispatch({
      type: SET_UPDATE_NOTIFICATION_LOADING,
      payload: false
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
  return false;
};

//Update Notification
export const updateNotification =
  ({ id, data }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SET_UPDATE_NOTIFICATION_LOADING,
        payload: true
      });
      const body = JSON.stringify(data);
      const res = await axios.put(`/api/notification/${id}`, body, config);

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: res.data
      });
      return true;
    } catch (err) {
      dispatch({
        type: SET_UPDATE_NOTIFICATION_LOADING,
        payload: false
      });
      actionErrorHandler(err, dispatch, setAlert);
    }
    return false;
  };

//Get All Sellers
export const getAllSellers = (data) => async (dispatch) => {
  try {
    const body = JSON.stringify(data);
    const res = await axios.post('/api/authorizedSellers/all', body, config);

    dispatch({
      type: GET_AUTH_SELLERS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_AUTH_SELLERS,
      payload: { unauthorizedSellers: [], authorizedSellers: [] }
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
};

//Add auth Sellers
export const addAuthSellers = (data, changedSellers) => async (dispatch) => {
  try {
    dispatch({
      type: SET_ADD_AUTH_SELLERS_LOADING,
      payload: true
    });
    const body = JSON.stringify(data);
    await axios.post('/api/authorizedSellers', body, config);

    dispatch({
      type: ADD_AUTH_SELLERS,
      payload: changedSellers
    });
    return true;
  } catch (err) {
    dispatch({
      type: SET_ADD_AUTH_SELLERS_LOADING,
      payload: false
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
  return false;
};

//Add auth Sellers
export const changeAuthSellers = (data, changedSellers) => async (dispatch) => {
  try {
    dispatch({
      type: SET_CHANGE_AUTH_SELLERS_LOADING,
      payload: true
    });
    const body = JSON.stringify(data);
    await axios.put('/api/authorizedSellers', body, config);

    dispatch({
      type: CHANGE_AUTH_SELLERS,
      payload: changedSellers
    });
    return true;
  } catch (err) {
    dispatch({
      type: SET_CHANGE_AUTH_SELLERS_LOADING,
      payload: false
    });
    actionErrorHandler(err, dispatch, setAlert);
  }
  return false;
};

//Clear Brand
export const clearNotifications = () => (dispatch) => {
  dispatch({
    type: CLEAR_NOTIFICATIONS
  });
};
