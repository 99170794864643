import * as amplitude from '@amplitude/analytics-browser';
import { analyticsUrlEvents } from './Analytics.data';
import axios from 'axios';

const getUserType = (role) => (role === 'vendor' ? 'Vendor' : 'NonVendor');
const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const getUserData = () => {
  const userData = navigator.userAgentData;
  return {
    platform: userData?.platform,
    device_model:
      userData?.mobile === true
        ? 'Mobile'
        : userData?.mobile === false
        ? 'Web'
        : 'Unknown',
    os_name: Array.isArray(userData?.brands)
      ? userData?.brands[0]?.brand
      : 'Unknown',
    os_version: Array.isArray(userData?.brands)
      ? userData?.brands[0]?.version
      : 'Unknown',
  };
};

export const sendUrlAction = (path, user, hasAdBlocker) => {
  const found = analyticsUrlEvents.find((e) => e.path === path);

  if (found) {
    if (hasAdBlocker) {
      try {
        axios.post(
          '/api/external/private',
          JSON.stringify({
            action: found.name,
            email: user?.email,
            options: {
              UserType: getUserType(user?.role),
              AppVersion: process.env.REACT_APP_VERSION,
              hasAdBlocker: true,
            },
            userData: getUserData(),
          }),
          config
        );
      } catch (err) {
        console.log(err);
      }
    } else {
      amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY, user?.email);
      amplitude.track(found.name, {
        UserType: getUserType(user?.role),
        AppVersion: process.env.REACT_APP_VERSION,
      });
    }
  }
};

export const sendLoginAction = (user) => {
  try {
    axios.post(
      '/api/external/private',
      JSON.stringify({
        action: 'login',
        email: user?.email,
        options: {
          UserType: getUserType(user?.role),
          AppVersion: process.env.REACT_APP_VERSION,
        },
        userData: getUserData(),
      }),
      config
    );
  } catch (err) {
    console.log(err);
  }
};
