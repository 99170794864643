import { connect } from 'react-redux';
import NavContainer from './NavModule/NavContainer';
import { adminNav, helpNav, vendorNav } from './Navbar.data';
import { logout, setProhibitedEmailAccepted } from '../../actions/auth';
import { openConfirmationModal } from '../../actions/confirmation';
import { roles } from '../../utils/accessPage/accessPage.data';

const Navbar = ({
  auth: { user },
  confirmation: { emailConfirmed },
  chat: { wss, unread_messages },
  sellers_comparison: { sellersInComparsion },
  logout,
  setProhibitedEmailAccepted,
  openConfirmationModal
}) => {
  //Disconnect from Websocket connection
  const exit = () => {
    if (wss) {
      wss.send(JSON.stringify({ logoutId: user._id }));
    }
    logout();
  };

  if (user.role === roles.vendor || user.role === roles.vendorUser) {
    return (
      <NavContainer
        user={user}
        unreadMessages={unread_messages}
        exit={exit}
        leftData={vendorNav}
        access={user.access}
        hasCompare={sellersInComparsion.length ? true : false}
        openConfirmationModal={openConfirmationModal}
        emailConfirmed={user.emailConfirmed || emailConfirmed}
        isProhibited={user.prohibitedEmail}
        setProhibitedEmailAccepted={setProhibitedEmailAccepted}
      />
    );
  } else if (user.role === roles.admin || user.role === roles.support) {
    return (
      <NavContainer
        user={user}
        unreadMessages={unread_messages}
        exit={exit}
        leftData={adminNav}
        access={user.role}
        hasCompare={sellersInComparsion.length ? true : false}
      />
    );
  } else if (user.role === roles.help) {
    return (
      <NavContainer
        isHelp
        user={user}
        exit={exit}
        leftData={helpNav}
        access={user.role}
      />
    );
  } else {
    return <div>Navigation</div>;
  }
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  chat: state.chat,
  confirmation: state.confirmation,
  sellers_comparison: state.sellers_comparison
});

export default connect(mapStateToProps, {
  logout,
  openConfirmationModal,
  setProhibitedEmailAccepted
})(Navbar);
