import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';

import {
  SET_ACTIVE_ITEM,
  GET_ITEM,
  SET_ITEM_LOADING,
  SET_CASE,
  CHANGE_CASE,
  REMOVE_CASE,
  CLEAR_ITEM,
} from './types';

//Set Active Item
export const setActiveItem = (asin) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_ITEM,
    payload: asin,
  });
};

//Set Case Value
export const setCase = (key, text) => (dispatch) => {
  dispatch({
    type: SET_CASE,
    payload: { key, text },
  });
};

//Change Case Value
export const changeCase = (key, text) => (dispatch) => {
  dispatch({
    type: CHANGE_CASE,
    payload: { key, text },
  });
};

//Set Case Value
export const removeCase = (key) => (dispatch) => {
  dispatch({
    type: REMOVE_CASE,
    payload: key,
  });
};

export const clearItem = () => (dispatch) => {
  dispatch({
    type: CLEAR_ITEM,
  });
};

//Get Item Data
export const getItem = (asin) => async (dispatch) => {
  setAuthToken(localStorage.token);
  try {
    dispatch({
      type: SET_ITEM_LOADING,
      payload: true,
    });

    const res = await axios.get(`/api/mysql/item?asin=${asin}`);

    dispatch({
      type: GET_ITEM,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }
    dispatch({
      type: GET_ITEM,
      payload: { product_data: { asin }, error: 'server error' },
    });
  }
};
