import { lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import Page404 from '../../pages/Page404/Page404';

const Dashboard = lazy(() => import('../../pages/Dashboard/Dashboard'));
const UserInfoPage = lazy(() =>
  import('../../pages/UserInfoPage/UserInfoPage')
);
const UserPage = lazy(() => import('../../pages/UserPage/UserPage'));
const PurchaseOrderPage = lazy(() =>
  import('../../pages/PurchaseOrderPage/PurchaseOrderPage')
);
const SellerPage = lazy(() => import('../../pages/SellerPage/SellerPage'));
const Contacts = lazy(() => import('../../pages/Contacts/Contacts'));
const Chat = lazy(() => import('../../pages/Chat/Chat'));
const AmazonLQS = lazy(() => import('../../pages/AmazonLQS/AmazonLQS'));
const EbayLQS = lazy(() => import('../../pages/EbayLQS/EbayLQS'));
const SQSPage = lazy(() => import('../../pages/SQSPage/SQSPage'));
const AdvertisingPage = lazy(() =>
  import('../../pages/AdvertisingPage/AdvertisingPage')
);
const PricesPage = lazy(() => import('../../pages/PricesPage/PricesPage'));
const ManageUsersPage = lazy(() =>
  import('../../pages/ManageUsersPage/ManageUsersPage')
);
const ManageVendorsPage = lazy(() =>
  import('../../pages/ManageVendorsPage/ManageVendorsPage')
);
const VendorDashboardPage = lazy(() =>
  import('../../pages/VendorDashboardPage/VendorDashboardPage')
);
const MapViolations = lazy(() =>
  import('../../pages/MapViolations/MapViolations')
);
const Sales = lazy(() => import('../../pages/SalesPage/SalesPage'));
const LogsStatisticsPage = lazy(() =>
  import('../../pages/LogsStatisticsPage/LogsStatisticsPage')
);
const Help = lazy(() => import('../../pages/Help/Help'));
const VendorItemPage = lazy(() =>
  import('../../pages/VendorItemPage/VendorItemPage')
);
const Forecast = lazy(() => import('../../pages/Forecast/Forecast'));
const ResetPassword = lazy(() =>
  import('../../pages/ResetPassword/ResetPassword')
);
const SendResetEmail = lazy(() =>
  import('../../pages/SendResetEmail/SendResetEmail')
);
const Login = lazy(() => import('../../pages/Login/Login'));
const SellersComparison = lazy(() =>
  import('../../pages/SellersComparison/SellersComparison')
);
const NotificationsPage = lazy(() =>
  import('../../pages/NotificationsPage/NotificationsPage')
);
const ManageVendorUsersPage = lazy(() =>
  import('../../pages/ManageVendorUsersPage/ManageVendorUsersPage')
);

export const AppRoutes = () => {
  return (
    <Router>
      <Switch>
        <PublicRoute path="/" exact={true} component={Login} />
        <PublicRoute
          path="/resetPassword/:token"
          exact={true}
          component={ResetPassword}
        />
        <PublicRoute
          path="/resetPassword/"
          exact={true}
          component={SendResetEmail}
        />
        <PrivateRoute exact path="/dashboard/" component={Dashboard} />
        <PrivateRoute
          exact
          path="/dashboard/lqs/amazon/"
          component={AmazonLQS}
        />
        <PrivateRoute exact path="/dashboard/lqs/ebay/" component={EbayLQS} />
        <PrivateRoute exact path={'/dashboard/sqs/'} component={SQSPage} />
        <PrivateRoute
          exact
          path={'/dashboard/vendoritem/:asin'}
          component={VendorItemPage}
        />
        <PrivateRoute
          exact
          path="/dashboard/sellers/"
          component={MapViolations}
        />
        <PrivateRoute exact path="/dashboard/sales/" component={Sales} />
        <PrivateRoute
          exact
          path="/dashboard/advertising/"
          component={AdvertisingPage}
        />
        <PrivateRoute exact path="/dashboard/prices/" component={PricesPage} />
        <PrivateRoute
          exact
          path={'/dashboard/userpage/:id'}
          component={UserPage}
        />
        <PrivateRoute
          exact
          path={'/dashboard/sellerpage/:id/'}
          component={SellerPage}
        />
        <PrivateRoute
          exact
          path={'/dashboard/purchaseOrder/:id/'}
          component={PurchaseOrderPage}
        />
        <PrivateRoute
          exact
          path={'/dashboard/forecast/'}
          component={Forecast}
        />
        <PrivateRoute
          exact
          path={'/dashboard/vendordashboard/'}
          component={VendorDashboardPage}
        />
        <PrivateRoute
          exact
          path={'/dashboard/sellers-comparison/'}
          component={SellersComparison}
        />
        <PrivateRoute exact path={'/users/'} component={ManageUsersPage} />
        <PrivateRoute strict path={'/vendors/'} component={ManageVendorsPage} />
        <PrivateRoute
          strict
          path={'/vendorUsers/'}
          component={ManageVendorUsersPage}
        />
        <PrivateRoute
          exact
          path="/notifications/"
          component={NotificationsPage}
        />
        <PrivateRoute exact path="/userinfo/" component={UserInfoPage} />
        <PrivateRoute exact path="/contacts/" component={Contacts} />
        <PrivateRoute exact path="/chat/" component={Chat} />
        <PrivateRoute exact path="/logs/" component={LogsStatisticsPage} />
        <PrivateRoute exact path="/help/" component={Help} />
        <Route path="*" component={Page404} />
      </Switch>
    </Router>
  );
};
