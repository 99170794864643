import { useState, Fragment, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classes from './NavContainer.module.css';
import logo from '../../../img/logo.png';
import {
  getIcon,
  AccessLink,
  checkPageAccess,
  startLinks
} from './NavContainer.service';
import navIconDown from '../../../img/nav_icon_down.png';
import navIconRight from '../../../img/nav_icon_right.png';
import EmailConfirmationModal from '../../../widgets/EmailConfirmationModal/EmailConfirmationModal';
// Icons
import { ReactComponent as ContactsIcon } from '../../../img/contacts_icon.svg';
import {
  GrFormClose,
  GrMenu,
  GrFormNext,
  GrFormPrevious
} from 'react-icons/gr';
import { AiOutlineUser } from 'react-icons/ai';
import { FiLogOut } from 'react-icons/fi';
import { FaExclamationCircle } from 'react-icons/fa';
import CompareButton from '../../../widgets/CompareButton/CompareButton';
import { MdBookmark, MdChat } from 'react-icons/md';
import { BsGearFill } from 'react-icons/bs';
import ChangeEmailModal from '../../../widgets/ChangeEmailModal/ChangeEmailModal';
import { ModalConfirmation } from '../../../UI/ModalConfirmation/ModalConfirmation';
import { roles } from '../../../utils/accessPage/accessPage.data';

const NavContainer = ({
  user,
  isHelp,
  unreadMessages,
  exit,
  leftData,
  access,
  hasCompare,
  openConfirmationModal,
  emailConfirmed,
  isProhibited,
  setProhibitedEmailAccepted
}) => {
  const location = useLocation();

  const userName = useMemo(() => {
    if (!user) return '';
    const { first_name, last_name, role, email, vendorname } = user;
    return first_name || last_name
      ? `${first_name || ''}${first_name && last_name ? ' ' : ''}${
          last_name || ''
        }`
      : [roles.vendor, roles.vendorUser].includes(role)
      ? vendorname
      : email;
  }, [user]);
  const isVendor = useMemo(() => roles.vendor === user?.role, [user]);
  const isVendorUser = useMemo(() => roles.vendorUser === user?.role, [user]);

  const [isOpen, setOpen] = useState(false);
  const [lists, openCloseList] = useState([]);
  const [links, setLink] = useState([]);
  const [isOpenEmailChange, setOpenEmailChange] = useState(false);
  const [isOpenProhibited, setOpenProhibited] = useState(isProhibited);

  const openModal = () => {
    if (isProhibited) {
      setOpenEmailChange(true);
    } else {
      openConfirmationModal(true);
    }
  };

  useEffect(() => {
    openCloseList(
      leftData
        .filter((data) => data.type === 'list')
        .map((data) => ({
          icon: data.icon,
          isOpen: data.list.some((l) => l.url === location.pathname)
        }))
    );
    setLink(startLinks(leftData, location.pathname));
  }, [leftData, location.pathname]);

  const onMouseOver = (e) => {
    if (e.currentTarget.contains(e.relatedTarget)) return;
    if (window.innerWidth > 1024) {
      setOpen(true);
    }
  };

  const onMouseOut = (e) => {
    if (e.currentTarget.contains(e.relatedTarget)) return;
    if (window.innerWidth > 1024) {
      setOpen(false);
    }
  };

  const showLeftMenu = (e) => {
    setOpen(!isOpen);
  };

  const changeList = (event) => {
    const icon = event.currentTarget.getAttribute('data-icon');
    openCloseList(
      lists.map((list) => {
        if (list.icon === icon) {
          list.isOpen = !list.isOpen;
        } else {
          list.isOpen = false;
        }
        return list;
      })
    );
  };

  const onApprove = () => {
    setOpenProhibited(false);
    if (setProhibitedEmailAccepted && isProhibited) {
      setProhibitedEmailAccepted();
    }
  };

  const setModalProhibited = (payload) => {
    setOpenProhibited(payload);
    if (setProhibitedEmailAccepted && isProhibited) {
      setProhibitedEmailAccepted();
    }
  };

  return (
    <div className={classes.navBox}>
      <div
        className={`${isOpen ? classes.navLeftFull : classes.navLeft}`}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        <div className={classes.navLeftClose}>
          <CompareButton className={classes.navCompareLeft} />
          <GrFormClose
            className={classes.navLeftCloseIcon}
            onClick={showLeftMenu}
          />
        </div>
        <div className={classes.navLeftArrow}>
          {isOpen ? (
            <GrFormPrevious
              className={classes.navLeftCloseIcon}
              onClick={showLeftMenu}
            />
          ) : (
            <GrFormNext
              className={classes.navLeftCloseIcon}
              onClick={showLeftMenu}
            />
          )}
        </div>
        <div className={isOpen ? classes.navLeftBodyFull : classes.navLeftBody}>
          {leftData.map((data, i) => {
            if (data.type === 'list') {
              const hasSublists = data.list.filter((row, j) => {
                if (!checkPageAccess(row.pageAccess, user?.page_access))
                  return false;
                if (row.roleAccess && user?.role !== row.roleAccess)
                  return false;

                return true;
              });

              if (!hasSublists.length) return null;

              return (
                <div
                  className={classes.navLeftOptionList}
                  key={`leftdata-${i}`}
                >
                  <div
                    className={`${classes.navLeftOption} ${classes.navLeftList}`}
                    onClick={changeList}
                    data-icon={data.icon}
                  >
                    {getIcon(data.icon)}
                    {data.name}
                    <div className={classes.navIconImageBox}>
                      {lists.some(
                        (list) => list.icon === data.icon && list.isOpen
                      ) ? (
                        <img
                          src={navIconDown}
                          alt="navIconDown"
                          className={classes.navIconImage}
                        />
                      ) : (
                        <img
                          src={navIconRight}
                          alt="navIconRight"
                          className={classes.navIconImage}
                        />
                      )}
                    </div>
                  </div>
                  <ul
                    className={
                      lists.some(
                        (list) => list.icon === data.icon && list.isOpen
                      )
                        ? ''
                        : classes.navList
                    }
                  >
                    {data.list.map((row, j) => {
                      if (!checkPageAccess(row.pageAccess, user?.page_access))
                        return null;
                      if (row.roleAccess && user?.role !== row.roleAccess)
                        return null;
                      if (row.needAccess) {
                        return (
                          <Fragment key={`leftdata-row-${j}`}>
                            <li className={classes.navSublist}>
                              <AccessLink
                                url={row.url}
                                access={access}
                                text={row.name}
                                customIcon={row.customIcon}
                                customClass={`${classes.navLeftOption} ${
                                  classes.navLeftSublistOption
                                } ${
                                  links.some(
                                    (url) =>
                                      url.path === row.url && url.isActive
                                  )
                                    ? classes.navLeftListActive
                                    : ''
                                }`}
                              >
                                {getIcon(row.icon)}
                              </AccessLink>
                            </li>
                            {j !== data.list.length - 1 ? (
                              <li className={classes.navListDevider}></li>
                            ) : null}
                          </Fragment>
                        );
                      }
                      return (
                        <Fragment key={`leftdata-row-${j}`}>
                          <li className={classes.navSublist}>
                            <Link
                              className={`${classes.navLeftOption} ${
                                classes.navLeftSublistOption
                              } ${
                                links.some(
                                  (url) => url.path === row.url && url.isActive
                                )
                                  ? classes.navLeftListActive
                                  : ''
                              }`}
                              to={row.url}
                            >
                              {getIcon(row.icon)}
                              {row.name}
                            </Link>
                          </li>
                          {j !== data.list.length - 1 ? (
                            <li className={classes.navListDevider}></li>
                          ) : null}
                        </Fragment>
                      );
                    })}
                  </ul>
                </div>
              );
            } else if (data.type === 'devider') {
              if (!checkPageAccess(data.pageAccess, user?.page_access))
                return null;
              return (
                <div className={classes.navDevider} key={`leftdata-${i}`}></div>
              );
            }

            if (!checkPageAccess(data.pageAccess, user?.page_access))
              return null;
            if (data.roleAccess && user?.role !== data.roleAccess) return null;

            if (data.needAccess) {
              return (
                <Fragment key={`leftdata-${i}`}>
                  <AccessLink
                    url={data.url}
                    access={access}
                    text={data.name}
                    customIcon={data.customIcon}
                    customClass={`${classes.navLeftOption} ${
                      links.some((url) => url.path === data.url && url.isActive)
                        ? classes.navLeftListActive
                        : ''
                    }`}
                  >
                    {getIcon(data.icon)}
                  </AccessLink>
                </Fragment>
              );
            }

            return (
              <Link
                className={`${classes.navLeftOption} ${
                  links.some((url) => url.path === data.url && url.isActive)
                    ? classes.navLeftListActive
                    : ''
                }`}
                to={data.url}
                key={`leftdata-${i}`}
              >
                {getIcon(data.icon)}
                {data.name}
              </Link>
            );
          })}
        </div>
      </div>
      <div className={classes.navTop}>
        <div className={classes.navTopMobile}>
          {hasCompare && <MdBookmark className={classes.compareBtnMobile} />}
          <GrMenu className={classes.navTopIcon} onClick={showLeftMenu} />
        </div>
        <div className={classes.navUserContainer}>
          <div className={classes.navUserBox}>
            {isHelp ? (
              <div className={classes.navAvatarBox}>
                <div className={classes.navTopIconBox}>
                  <AiOutlineUser className={classes.navTopUserIcon} />
                </div>
                <span className={classes.navUserName}>
                  Welcome, <b>{userName}</b>
                </span>
              </div>
            ) : (
              <Link className={classes.navAvatarBox} to="/userinfo/">
                {user?.avatar ? (
                  <img
                    src={`${user?.avatar}?${new Date().getTime()}`}
                    alt="avatar"
                    className={classes.navAvatar}
                  />
                ) : (
                  <div className={classes.navTopIconBox}>
                    <AiOutlineUser className={classes.navTopUserIcon} />
                  </div>
                )}
                <span className={classes.navUserName}>
                  Welcome, <b>{userName}</b>
                </span>
                <BsGearFill className={classes.navUserGear} />
              </Link>
            )}
          </div>
          {(!emailConfirmed || isProhibited) && (isVendor || isVendorUser) && (
            <>
              <div
                className={`${classes.verifyEmailBox} ${
                  isProhibited ? classes.verifyEmailBoxProhibited : ''
                }`}
                onClick={openModal}
              >
                <FaExclamationCircle className={classes.verifyEmailIcon} />
                <span>
                  {isProhibited
                    ? 'You are registered under non-existent email.'
                    : 'Your email is not verified.'}
                </span>
                <span className={classes.verifyEmailText}>
                  {isProhibited ? 'Change' : 'Verify'}
                </span>
              </div>
              <FaExclamationCircle
                className={`${classes.verifyEmailIconMain} ${
                  isProhibited ? classes.verifyEmailIconMainProhibited : ''
                }`}
                onClick={openModal}
              />
            </>
          )}
        </div>
        <div className={classes.navLogo}>
          <Link className={classes.blackLink} to="/dashboard/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <div className={classes.navIconsMenu}>
          {!isHelp && (
            <Fragment>
              <CompareButton className={classes.navCompareTop} />
              {!isVendorUser && (
                <div className={classes.navChatBox}>
                  <Link className={classes.blackLink} to="/chat/">
                    <MdChat className={classes.navChatIcon} />
                  </Link>
                  {unreadMessages > 0 && (
                    <span className={classes.navCountIcon}>
                      {unreadMessages}
                    </span>
                  )}
                </div>
              )}
              {isVendor && (
                <Link className={classes.blackLink} to="/contacts/">
                  <ContactsIcon className={classes.navTopIcon} />
                </Link>
              )}
            </Fragment>
          )}
          <Link className={classes.blackLink} to="/" onClick={exit}>
            <FiLogOut className={classes.navMiIcon} />
          </Link>
        </div>
      </div>
      {(isVendor || isVendorUser) && <EmailConfirmationModal />}
      {isProhibited && (
        <ChangeEmailModal
          isOpen={isOpenEmailChange}
          setIsOpen={setOpenEmailChange}
        />
      )}
      {isProhibited && (
        <ModalConfirmation
          isOpen={isOpenProhibited}
          setOpen={setModalProhibited}
          onApprove={onApprove}
          message="You are registered under non-existent email. Please consider changing it in user info!"
          hideCancelBtn
        />
      )}
    </div>
  );
};

export default NavContainer;
