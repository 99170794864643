import axios from 'axios';
import { actionErrorHandler } from '../utils/global.services';
import { setAlert } from './alert';
import {
  SET_LOADING_SELLER,
  CLEAR_SELLER,
  GET_SELLER,
  GET_SELLER_LIFETIME,
  SET_LOADING_SELLER_LIFETIME,
  GET_SELLER_BRANDS,
  GET_SELLER_OFFERS,
  SET_LOADING_SELLER_OFFERS,
  GET_SELLER_DATES,
  SET_LOADING_SELLER_DATES,
  GET_SELLER_SQSCHART,
  SET_LOADING_SELLER_SQSCHART,
} from './types';

const CancelToken = axios.CancelToken;
let cancelSQSchart = null;
let cancelDates = null;
let cancelOffers = null;

//Get Available dates for sellers report
export const getSeller = (data) => async (dispatch) => {
  const { brandName, sellerId } = data;
  dispatch({
    type: SET_LOADING_SELLER,
  });

  try {
    const res = await axios.get(
      `/api/mysql/seller?brandName=${encodeURIComponent(
        brandName
      )}&sellerId=${sellerId}`
    );

    dispatch({
      type: GET_SELLER,
      payload: res.data,
    });
  } catch (err) {
    actionErrorHandler(err, dispatch, setAlert);
    dispatch({
      type: GET_SELLER,
      payload: {},
    });
  }
};

//Get Seller lifetime chart
export const getSellerLifetime = (data) => async (dispatch) => {
  const { sellerId, from, to } = data;
  dispatch({
    type: SET_LOADING_SELLER_LIFETIME,
  });

  try {
    const res = await axios.get(
      `/api/mysql/seller/lifetime?sellerId=${sellerId}&from=${from}&to=${to}`
    );

    dispatch({
      type: GET_SELLER_LIFETIME,
      payload: res.data,
    });
  } catch (err) {
    actionErrorHandler(err, dispatch, setAlert);
    dispatch({
      type: GET_SELLER_LIFETIME,
      payload: [],
    });
  }
};

//Get Seller top Brands
export const getSellerBrands = (sellerId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/mysql/seller/brands?sellerId=${sellerId}`
    );

    dispatch({
      type: GET_SELLER_BRANDS,
      payload: res.data,
    });
  } catch (err) {
    actionErrorHandler(err, dispatch, setAlert);
    dispatch({
      type: GET_SELLER_BRANDS,
      payload: [],
    });
  }
};

//Get Seller offers table
export const getSellerOffers = (data) => async (dispatch) => {
  const { sellerId, brandName, day } = data;
  dispatch({
    type: SET_LOADING_SELLER_OFFERS,
  });

  try {
    if (cancelOffers) {
      cancelOffers();
      cancelOffers = null;
    }

    const cancelConfig = {
      cancelToken: new CancelToken(function executor(c) {
        cancelOffers = c;
      }),
    };

    const res = await axios.get(
      `/api/mysql/seller/offers?brandName=${encodeURIComponent(
        brandName
      )}&sellerId=${sellerId}&day=${day}`,
      cancelConfig
    );

    dispatch({
      type: GET_SELLER_OFFERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_SELLER_OFFERS,
      payload: [],
    });
    actionErrorHandler(err, dispatch, setAlert, SET_LOADING_SELLER_OFFERS);
  }
};

//Get Seller offers table dates
export const getSellerDates = (data) => async (dispatch) => {
  const { sellerId, brandName, from, to } = data;
  dispatch({
    type: SET_LOADING_SELLER_DATES,
  });
  try {
    if (cancelDates) {
      cancelDates();
      cancelDates = null;
    }

    const cancelConfig = {
      cancelToken: new CancelToken(function executor(c) {
        cancelDates = c;
      }),
    };

    const res = await axios.get(
      `/api/mysql/seller/dates?brandName=${encodeURIComponent(
        brandName
      )}&sellerId=${sellerId}&from=${from}&to=${to}`,
      cancelConfig
    );

    dispatch({
      type: GET_SELLER_DATES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_SELLER_DATES,
      payload: [],
    });
    actionErrorHandler(err, dispatch, setAlert, SET_LOADING_SELLER_DATES);
  }
};

//Get Seller sqs chart
export const getSellerSQSchart = (data) => async (dispatch) => {
  const { sellerId, brandName, from, to } = data;
  dispatch({
    type: SET_LOADING_SELLER_SQSCHART,
  });

  try {
    if (cancelSQSchart) {
      cancelSQSchart();
      cancelSQSchart = null;
    }

    const cancelConfig = {
      cancelToken: new CancelToken(function executor(c) {
        cancelSQSchart = c;
      }),
    };

    const res = await axios.get(
      `/api/mysql/seller/sqschart?brandName=${encodeURIComponent(
        brandName
      )}&sellerId=${sellerId}&from=${from}&to=${to}`,
      cancelConfig
    );

    dispatch({
      type: GET_SELLER_SQSCHART,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_SELLER_SQSCHART,
      payload: [],
    });
    actionErrorHandler(err, dispatch, setAlert, SET_LOADING_SELLER_SQSCHART);
  }
};

//Clear Seller
export const clearSeller = () => (dispatch) => {
  dispatch({
    type: CLEAR_SELLER,
  });
};
