import {
  CLEAR_NOTIFICATIONS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_LOGS,
  SET_NOTIFICATIONS_LOGS_LOADING,
  UPDATE_NOTIFICATION,
  CREATE_NOTIFICATION,
  SET_UPDATE_NOTIFICATION_LOADING,
  GET_AUTH_SELLERS,
  ADD_AUTH_SELLERS,
  SET_ADD_AUTH_SELLERS_LOADING,
  CHANGE_AUTH_SELLERS,
  SET_CHANGE_AUTH_SELLERS_LOADING
} from '../actions/types';

const initialState = {
  notifications: [],
  brands: [],
  types: [],
  logs: [],
  unauthorizedSellers: [],
  authorizedSellers: [],
  loading: true,
  loadingLogs: true,
  loadingUpdate: false,
  loadingSellers: true,
  loadingAddSellers: false,
  loadingChangeSellers: false
};

export default function notifications(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        ...payload,
        loading: false
      };
    case GET_NOTIFICATIONS_LOGS:
      return {
        ...state,
        logs: payload,
        loadingLogs: false
      };
    case SET_NOTIFICATIONS_LOGS_LOADING:
      return {
        ...state,
        loadingLogs: true
      };
    case CREATE_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, payload],
        loadingUpdate: false
      };
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.map((n) =>
          n._id === payload._id ? payload : n
        ),
        loadingUpdate: false
      };
    case SET_UPDATE_NOTIFICATION_LOADING:
      return {
        ...state,
        loadingUpdate: payload
      };
    case GET_AUTH_SELLERS:
      return {
        ...state,
        ...payload,
        loadingSellers: false
      };
    case ADD_AUTH_SELLERS:
      return {
        ...state,
        unauthorizedSellers: state.unauthorizedSellers.filter(
          (s) =>
            !payload.some(
              (p) => p.seller_id === s.seller_id && p.brand === s.brand
            )
        ),
        authorizedSellers: [...state.authorizedSellers, ...payload],
        loadingAddSellers: false
      };
    case SET_ADD_AUTH_SELLERS_LOADING:
      return {
        ...state,
        loadingAddSellers: payload
      };
    case CHANGE_AUTH_SELLERS:
      return {
        ...state,
        authorizedSellers: state.authorizedSellers.map((s) => {
          const found = payload.find(
            (p) => p.seller_id === s.seller_id && p.brand === s.brand
          );
          return found ? found : s;
        }),
        loadingChangeSellers: false
      };
    case SET_CHANGE_AUTH_SELLERS_LOADING:
      return {
        ...state,
        loadingChangeSellers: payload
      };
    case CLEAR_NOTIFICATIONS:
      return initialState;

    default:
      return state;
  }
}
