import {
  SET_ACTIVE_BRAND,
  CLEAR_BRAND,
  SET_LOADING_BRANDS,
  GET_BRANDS_LIST,
  CHECK_REPORT_AVAILABILITY,
  GET_BRAND_DETAILS,
  GET_BRAND_USER_DETAILS,
} from '../actions/types';

const initialState = {
  active: null,
  details: {},
  brands: null,
  buyer: {},
  marketing: {},
  available_reports: null,
  isFirst: true,
  getBuyer: false,
  getMarketing: false,
  loading: false,
  brands_loading: false,
};

export default function brand(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_BRAND_DETAILS:
      return {
        ...state,
        details: payload,
        isFirst: false,
        getBuyer: false,
      };
    case SET_ACTIVE_BRAND:
      return {
        ...state,
        active: payload,
        isFirst: true,
      };
    case GET_BRANDS_LIST:
      return {
        ...state,
        brands: payload,
        brands_loading: false,
      };
    case SET_LOADING_BRANDS:
      return {
        ...state,
        brands_loading: payload,
      };
    case CHECK_REPORT_AVAILABILITY:
      return {
        ...state,
        available_reports: payload,
        brands_loading: false,
      };
    case GET_BRAND_USER_DETAILS:
      if (payload.isMarketing) {
        return {
          ...state,
          marketing: payload.data,
          getMarketing: true,
        };
      } else if (payload.isBuyer) {
        return {
          ...state,
          buyer: payload.data,
          getBuyer: true,
        };
      } else {
        return state;
      }
    case CLEAR_BRAND:
      return initialState;

    default:
      return state;
  }
}
