import { IoWarningOutline } from 'react-icons/io5';
import classes from './ModalConfirmation.module.css';
import { ModalContainer } from '../ModalContainer/ModalContainer';

export const ModalConfirmation = ({
  isOpen,
  setOpen,
  onApprove,
  message,
  hideCancelBtn
}) => {
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      setIsOpen={setOpen}
      hideCloseButton
      containerClass={classes.modalContainer}
      bodyClass={classes.modalBody}
      headerClass={classes.modalHeaderContainer}
    >
      <IoWarningOutline className={classes.modalConfirmIcon} />
      <p className={classes.modalConfirmMessage}>{message}</p>
      <div className={classes.modalConfirmButtons}>
        <button
          className={`${classes.modalBtn} ${classes.modalBtnUpdate}`}
          onClick={onApprove}
        >
          OK
        </button>
        {!hideCancelBtn && (
          <button
            className={`${classes.modalBtn} ${classes.modalBtnCancel}`}
            onClick={closeModal}
          >
            CANCEL
          </button>
        )}
      </div>
    </ModalContainer>
  );
};
