import { allPageStyles } from './printStyles/printStyles';
import ReactDOMServer from 'react-dom/server';
import { lqsColors5 } from './constants';

export const isAbotUser = (score) => (score ? score < 0.3 : false);

export const formGridRow = (headers) => {
  let row = '';
  if (Array.isArray(headers)) {
    headers.forEach((h, i) => {
      if (h.width) {
        row += i ? ` ${h.width}` : `${h.width}`;
      }
    });
  }

  return row;
};

export const checkRecaptcha = (checkScore) => {
  if (!window.grecaptcha) {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`;
    script.addEventListener('load', () => {
      if (window.grecaptcha) {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_RECAPTCHA_KEY, { action: 'login' })
            .then((token) => {
              checkScore(token);
            });
        });
      }
    });
    document.body.appendChild(script);
  }
};

export const actionErrorHandler = (err, dispatch, setAlert, loadingType) => {
  if (err.response) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
  } else if (err.message === undefined) {
    dispatch({ type: loadingType });
  } else {
    console.log(err);
  }
};

export const formatLogDate = (date) =>
  new Intl.DateTimeFormat('en', {
    dateStyle: 'short',
    timeStyle: 'medium'
  }).format(date);

export const saveBrand = (brand) => {
  if (!brand) {
    localStorage.removeItem('brand');
  } else {
    localStorage.setItem('brand', brand);
  }
};

export const printComponent = ({
  id,
  styles,
  component,
  type,
  disabledStyles,
  scale,
  header
}) => {
  let printContext = '';
  if (component) {
    printContext = ReactDOMServer.renderToString(component);
  } else if (id) {
    const target = document.querySelector(`#${id}`);
    if (type === 'iframe') {
      printContext = target?.contentWindow?.document.body.innerHTML;
    } else {
      printContext = target?.innerHTML;
    }
  }

  if (printContext) {
    const a = window.open('', '', 'height=600, width=1200');
    a?.document.write(
      `<html>
        <head>
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"/>
        </head>
        <body>  
          <div class="container">
          ${header ? header : ''}
          ${printContext}
          </div>
        </body>
      </html>`
    );
    const disabled = Array.isArray(disabledStyles)
      ? disabledStyles.map((s) => `.${s}{display:none;}`).join(' ')
      : '';
    const mainCSS = document.createElement('style');
    mainCSS.innerHTML = `${
      scale ? `body{transform: scale(${scale}); transform-origin: 0 0;}` : ''
    } ${allPageStyles} ${styles} ${disabled}`;
    a?.document.head.appendChild(mainCSS);
    a?.document.close();
    a?.print();
  }
};

export const convertToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const setTimerRedirect = (seconds, setSeconds, setRedirect) => {
  setTimeout(() => {
    setSeconds(seconds - 1);
    if (seconds > 1) {
      setTimerRedirect(seconds - 1, setSeconds, setRedirect);
    } else {
      setRedirect(true);
    }
  }, 1000);
};

export const shrinkString = (value, lettersQty) => {
  if (value && lettersQty) {
    value =
      value.length > Number(lettersQty)
        ? `${value.substring(0, lettersQty)}...`
        : value;
  }
  return value;
};

export const getColorBySQS = (sellers) => {
  if (!Array.isArray(sellers)) return '';

  const avgSqs = sellers.length
    ? Math.round(
        sellers.reduce(
          (r, c) => (r + c.sellerScore ? c.sellerScore : c.sqs),
          0
        ) / sellers.length
      )
    : 0;
  let color = 'gray';
  if (avgSqs >= 85) {
    color = lqsColors5[4];
  } else if (avgSqs >= 70 && avgSqs <= 84) {
    color = lqsColors5[3];
  } else if (avgSqs >= 55 && avgSqs <= 69) {
    color = lqsColors5[2];
  } else if (avgSqs >= 40 && avgSqs <= 54) {
    color = lqsColors5[1];
  } else if (avgSqs > 0 && avgSqs < 40) {
    color = lqsColors5[0];
  }

  return color;
};

export const getEmailBody = ({ headers, data }) => {
  const bodyData = headers ? headers : data ? data : [];
  let body = `<table style="border: 1px solid black;border-collapse: collapse;padding: 5px 10px; font-size: 14px;">
          <tr style="background-color: #ccc;">
            <th style="text-align: center;padding: 5px 10px;border: 1px solid black;">Name</th>
            <th style="text-align: center;padding: 5px 10px;border: 1px solid black;">Value</th>
          </tr>`;

  bodyData.forEach((h) => {
    if (data[h.key] || h.value) {
      body += `<tr>
        <td style="padding: 5px 10px;border: 1px solid black; text-align: left;">${
          h.label
        }</td>
        <td style="padding: 5px 10px;border: 1px solid black; text-align: left;">${
          data[h.key] || h.value
        }</td>
      </tr>`;
    }
  });

  body += `</table><span style="opacity: 0">${new Date()}</span>`;
  return body;
};

export const checkIfDateCorrect = (dateString) => {
  const date = new Date(dateString);
  if (date instanceof Date && !isNaN(date)) {
    return true;
  }
  return false;
};

export const getCSVfile = ({ headers = [], data = [], fileName = '' }) => {
  const preparedData = [];
  data.forEach((d) => {
    preparedData.push(
      headers.map((h) =>
        d[h.key] ? String(d[h.key]).replace(/,/g, ' ').replace(/"/g, '″') : ''
      )
    );
  });
  const csvString = [headers?.map((h) => h.label), ...preparedData]
    .map((e) => e.join(','))
    .join('\n');
  const timestamp = new Date().getTime();

  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  const file = new File([blob], `${fileName}_${timestamp}.csv`, {
    type: 'text/csv;charset=utf-8;',
    lastModified: timestamp
  });
  return file;
};
