import { basicAccess, pagesForSelect, roles, urls } from './accessPage.data';

export const checkAdminUrls = (url) => {
  const adminUrls = [
    urls.vendors,
    urls.users,
    urls.vendor_dashboard,
    urls.logs
  ];
  let found = false;
  adminUrls.forEach((u) => {
    if (url.indexOf(u) >= 0) {
      found = true;
    }
  });
  return found;
};

export const checkHelpAccess = (url) => {
  const urlsAvailable = [urls.help];
  let found = false;
  urlsAvailable.forEach((u) => {
    if (url.indexOf(u) >= 0) {
      found = true;
    }
  });
  return found;
};

export const checkVendorAccess = (url) => {
  const urlsAvailable = [urls.vendorUsers, urls.chat, urls.contacts];
  let found = false;
  urlsAvailable.forEach((u) => {
    if (url.indexOf(u) >= 0) {
      found = true;
    }
  });
  return found;
};

export const convertPagesForSelect = (page_access) => {
  const value = [];
  for (const key in page_access) {
    const target = pagesForSelect.find(
      (p) => key === p.key && page_access[key]
    );
    if (target) {
      value.push({ name: target.name, key });
    }
  }
  return value;
};

export const checkPageAccess = (page_access, url) => {
  const vendor_pages = {
    lqs: urls.lqs,
    lqsEbay: urls.lqsEbay,
    sqs: urls.sqs,
    sellers: urls.sellers,
    advertising: urls.advertising,
    prices: urls.prices,
    sales: urls.sales,
    forecast: urls.forecast,
    sellers_comparison: urls.sellers_comparison,
    notifications: urls.notifications
  };
  let access = false;
  let contains = false;
  for (const key in vendor_pages) {
    if (vendor_pages[key] === url && page_access[key]) {
      access = true;
    }

    if (vendor_pages[key] === url) {
      contains = true;
    }
  }

  return access ? access : !contains ? true : false;
};

export const checkIfReport = (access, url) => {
  let reports = [
    urls.lqs,
    urls.lqsEbay,
    urls.sellers,
    urls.advertising,
    urls.sales,
    urls.forecast,
    urls.sqs,
    urls.sellers_comparison,
    urls.notifications,
    urls.vendorUsers
  ];
  return reports.includes(url) && access === basicAccess;
};

export const checkIfVendor = (role) => (role === 'vendor' ? true : false);
export const checkIfAdminSupport = (role) =>
  [roles.admin, roles.support].includes(role);
