import { primaryColor } from '../constants';

export const allPageStyles = `* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  }

  body {
    font-family: 'Roboto';
    font-size: 1rem;
    color: ${primaryColor};
    overflow-y: auto;
  }
  a {
    color: ${primaryColor};
  }
  .container {
    -webkit-print-color-adjust:exact;
    padding-top:20px;
    padding-left:20px;
    color:${primaryColor};
    width: fit-content;
  }
  `;

export const tableStyles = `
  body {
    margin-top: 5px;
    margin-left: 10px;
    transform-origin: 0 0;
  }

  .table-box {
    display: grid;
    margin-bottom: 10px;
    grid-auto-rows: max-content;
  }

  .table-body-cell {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-bottom: 1px solid ${primaryColor};
    border-right: 1px solid ${primaryColor};
    text-align: center;
    word-break: break-word;
  }

  .standard-table-header {
    display: grid;
    font-size: 13px;
    font-weight: 600;
    border-top: 1px solid ${primaryColor};
    border-left: 1px solid ${primaryColor};
    margin-right: 12px;
    width: fit-content;
  }

  .table-header-cell {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 7px;
    border-bottom: 1px solid ${primaryColor};
    border-right: 1px solid ${primaryColor};
    text-align: center;
  }

  .standard-table-row {
    display: grid;
    border-left: 1px solid ${primaryColor};
    min-height: 40px;
    font-size: 12px;
  }

  .standard-table-body {
    font-size: 12px;
  }

  .table-cell-align-left {
    justify-content: left !important;
    text-align: left !important;
  }
  
  .MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
  }
  .offers-table-icon {
    color: var(--error-color);
  }
  .offers-table-amz-icon {
    width: 20px;
    min-width: 20px;
    height: auto;
    justify-self: center;
    margin-right: 5px;
    margin-left: 5px;
  }
  .filter-arrow,
  .filter-arrow-hover {
    display: none;
  }
  .table-print-disable {
    display: none;
  }`;
