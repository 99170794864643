import {
  SET_LOADING_ADVERTISING,
  GET_ADVERTISING,
  GET_ADV_CALCULATOR,
  SET_ADV_CALCULATOR_LOADING,
  CLEAR_ADVERTISING,
  GET_ADVERTISING_TABLE,
  FILTER_ADVERTISING_TABLE,
  SET_LOADING_ADVERTISING_TABLE,
  GET_ADV_MIN_DATES,
  GET_ADV_KEYWORDS,
  SET_ADV_KEYWORDS_LOADING,
  FILTER_ADV_KEYWORDS
} from '../actions/types';
import { filterSorts } from '../utils/tables';

const initialState = {
  products_sales: [],
  products_clicks: [],
  brands_sales: [],
  brands_clicks: [],
  total: {},
  calculator_data: {},
  filtered_table_props: {
    prop: 'total_sales',
    method: filterSorts.DSC
  },
  filtered_table_text: '',
  filtered_table_page: 1,
  table: [],
  filtered_table: [],
  filtered_qty: 0,
  min_dates: {
    products_chart_dates: [],
    brand_chart_dates: [],
    product_table_dates: [],
    brand_keywords_dates: []
  },
  keywords: {
    brands_keywords: [],
    filtered_brands: [],
    brandsIsFiltered: false
  },
  filtered_keywords_props: {
    prop: 'sales',
    method: filterSorts.DSC
  },
  // filtered_keywords_text: '',
  filtered_keywords_page: 1,
  get_report: false,
  isFiltered: false,
  dates_loading: true,
  keywords_loading: false,
  table_loading: false,
  calculator_loading: false,
  loading: true
};

export default function brand(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ADVERTISING:
      return {
        ...state,
        ...payload,
        get_report: true,
        isFiltered: false,
        loading: false
      };
    case GET_ADVERTISING_TABLE:
      return {
        ...state,
        table: payload,
        isFiltered: false,
        table_loading: false
      };
    case GET_ADV_KEYWORDS:
      return {
        ...state,
        keywords: { ...state.keywords, brandsIsFiltered: false, ...payload },
        keywords_loading: false
      };
    case GET_ADV_MIN_DATES:
      return {
        ...state,
        min_dates: payload,
        dates_loading: false
      };
    case GET_ADV_CALCULATOR:
      return {
        ...state,
        calculator_data: payload,
        calculator_loading: false
      };
    case SET_ADV_CALCULATOR_LOADING:
      return {
        ...state,
        calculator_loading: true
      };
    case SET_LOADING_ADVERTISING:
      return {
        ...state,
        loading: payload
      };
    case SET_LOADING_ADVERTISING_TABLE:
      return {
        ...state,
        table_loading: true
      };
    case SET_ADV_KEYWORDS_LOADING:
      return {
        ...state,
        keywords_loading: true
      };
    case FILTER_ADVERTISING_TABLE:
      return {
        ...state,
        filtered_table_props: payload?.props
          ? payload?.props
          : state.filtered_table_props,
        filtered_table_text:
          payload && payload.text !== undefined
            ? payload.text
            : state.filtered_table_text,
        filtered_table_page: payload?.page
          ? payload?.page
          : state.filtered_table_page
      };
    case FILTER_ADV_KEYWORDS:
      return {
        ...state,
        filtered_keywords_props: payload?.props
          ? payload?.props
          : state.filtered_keywords_props,
        filtered_keywords_page: payload?.page
          ? payload?.page
          : state.filtered_keywords_page
      };
    case CLEAR_ADVERTISING:
      return initialState;

    default:
      return state;
  }
}
