import {
  SET_LOADING_SELLER,
  CLEAR_SELLER,
  GET_SELLER,
  GET_SELLER_LIFETIME,
  SET_LOADING_SELLER_LIFETIME,
  GET_SELLER_BRANDS,
  GET_SELLER_OFFERS,
  SET_LOADING_SELLER_OFFERS,
  GET_SELLER_DATES,
  SET_LOADING_SELLER_DATES,
  GET_SELLER_SQSCHART,
  SET_LOADING_SELLER_SQSCHART,
} from '../actions/types';

const initialState = {
  sellerInfo: {},
  sellerSQSchart: [],
  sellerOffers: [],
  sellerLifetimeChart: [],
  sellerTopBrands: [],
  offersDates: [],
  loading: true,
  loadingDates: true,
  loadingTopBrands: true,
  loadingSQSchart: true,
  loadingOffers: true,
  loadingLifetimeChart: true,
};

export default function sellers(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_SELLER:
      return {
        ...state,
        sellerInfo: payload,
        loading: false,
      };
    case SET_LOADING_SELLER:
      return {
        ...state,
        loading: true,
      };
    case GET_SELLER_LIFETIME:
      return {
        ...state,
        sellerLifetimeChart: payload,
        loadingLifetimeChart: false,
      };
    case SET_LOADING_SELLER_LIFETIME:
      return {
        ...state,
        loadingLifetimeChart: true,
      };
    case GET_SELLER_BRANDS:
      return {
        ...state,
        sellerTopBrands: payload,
        loadingTopBrands: false,
      };
    case GET_SELLER_OFFERS:
      return {
        ...state,
        sellerOffers: payload,
        loadingOffers: false,
      };
    case SET_LOADING_SELLER_OFFERS:
      return {
        ...state,
        loadingOffers: true,
      };
    case GET_SELLER_DATES:
      return {
        ...state,
        offersDates: payload,
        loadingDates: false,
      };
    case SET_LOADING_SELLER_DATES:
      return {
        ...state,
        loadingDates: true,
      };
    case GET_SELLER_SQSCHART:
      return {
        ...state,
        sellerSQSchart: payload,
        loadingSQSchart: false,
      };
    case SET_LOADING_SELLER_SQSCHART:
      return {
        ...state,
        loadingSQSchart: true,
      };
    case CLEAR_SELLER:
      return initialState;

    default:
      return state;
  }
}
