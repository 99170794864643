import {
  formatCurrency,
  formatDateUS,
  formatLogsDate,
  formatNumber
} from './constants';
import { checkIfDateCorrect } from './global.services';
import { recalcTime, timezones } from './timezone/timezone';

export const filterSorts = {
  ASC: 'asc',
  DSC: 'dsc'
};

export const tableCellFormats = {
  NUMBER: 'number',
  CURRENCY: 'currency',
  PERCENT: 'percent',
  PERCENT_HIDE: 'percent-hide',
  DATE: 'date',
  DATETIME: 'datetime',
  DATETIME_RECALC: 'datetime-recalc',
  TIMEZONE: 'timezone'
};

export const formatTableCell = (value, format, timezone) => {
  switch (format) {
    case tableCellFormats.CURRENCY:
      return formatCurrency(value);
    case tableCellFormats.NUMBER:
      return formatNumber(value);
    case tableCellFormats.PERCENT:
      return `${value}%`;
    case tableCellFormats.PERCENT_HIDE:
      return value ? `${value}%` : '';
    case tableCellFormats.DATE:
      const date = new Date(value);
      const isDate = checkIfDateCorrect(value);
      return isDate ? formatDateUS(date) : value;
    case tableCellFormats.DATETIME:
      return formatLogsDate(value);
    case tableCellFormats.DATETIME_RECALC:
      return recalcTime(value, timezone);
    case tableCellFormats.TIMEZONE:
      const t = timezones?.find((w) => w.value === value);
      return t ? t.label : value;

    default:
      return value;
  }
};

export const setTableSort = ({ prop, sortFilter, setSortFilter }) => {
  const res = {
    prop,
    method: filterSorts.ASC
  };

  if (prop === sortFilter.prop) {
    res.method =
      sortFilter.method === filterSorts.ASC ? filterSorts.DSC : filterSorts.ASC;
  }
  setSortFilter({ ...sortFilter, ...res });
};

export const getFilterClassName = ({ prop, sortFilter }) => {
  let className = '';
  if (sortFilter.prop === prop) {
    className =
      sortFilter.method === filterSorts.ASC
        ? 'active-arrow-asc'
        : 'active-arrow-dsc';
  }

  return className;
};

export const formTableRow = (headers) => {
  let row = '';
  if (Array.isArray(headers)) {
    headers.forEach((h, i) => {
      if (h.width) {
        row += i ? ` ${h.width}` : `${h.width}`;
      }
    });
  }

  return row;
};
