import { Link } from 'react-router-dom';
import { basicAccess } from '../../../utils/accessPage/accessPage.data';
import classes from './NavContainer.module.css';
import { ReactComponent as LqsIcon } from '../../../img/lqs_icon.svg';
import { ReactComponent as MapIcon } from '../../../img/map_icon.svg';
import { ReactComponent as SalesIcon } from '../../../img/sales_icon.svg';
import { ReactComponent as AdvIcon } from '../../../img/adv_icon.svg';
import { ReactComponent as PricesIcon } from '../../../img/prices_icon.svg';
import { ReactComponent as ForecastIcon } from '../../../img/forecast_icon.svg';
import { ReactComponent as LqsItemIcon } from '../../../img/lqs_item_icon.svg';
import { ReactComponent as HomeIcon } from '../../../img/home_icon.svg';
import { ReactComponent as SellersComparison } from '../../../img/navigation/nav_sellers_comparison.svg';
import {
  MdBusinessCenter,
  MdExitToApp,
  MdHelpOutline,
  MdPeople,
  MdTravelExplore
} from 'react-icons/md';
import { RiMailSettingsLine } from 'react-icons/ri';
import { TooltipBox } from '../../TooltipBox/TooltipBox';
import { TooltipPlacement } from '../../TooltipBox/TooltipBox.service';

export const getIcon = (name) => {
  switch (name) {
    case 'lqs':
      return <LqsIcon className={classes.dashNavIcon} />;
    case 'map':
      return <MapIcon className={classes.dashNavIcon} />;
    case 'sales':
      return <SalesIcon className={classes.dashNavIcon} />;
    case 'adv':
      return <AdvIcon className={classes.dashNavIcon} />;
    case 'prices':
      return <PricesIcon className={classes.dashNavIcon} />;
    case 'forecast':
      return <ForecastIcon className={classes.dashNavIcon} />;
    case 'sublist':
      return <div className={classes.navSublistIcon}></div>;
    case 'help':
      return <MdHelpOutline className={classes.dashNavReactIcon} />;
    case 'users':
      return <MdPeople className={classes.dashNavReactIcon} />;
    case 'vendors':
      return <MdBusinessCenter className={classes.dashNavReactIcon} />;
    case 'logs':
      return <MdExitToApp className={classes.dashNavReactIcon} />;
    case 'lqsItem':
      return <LqsItemIcon className={classes.dashNavIcon} />;
    case 'home':
      return <HomeIcon className={classes.dashNavIcon} />;
    case 'sqs':
      return <MdTravelExplore className={classes.dashNavReactIcon} />;
    case 'sellers_comparison':
      return <SellersComparison className={classes.dashNavIcon} />;
    case 'notifications':
      return <RiMailSettingsLine className={classes.dashNavReactIcon} />;
    default:
      return null;
  }
};

export const AccessLink = ({
  url,
  access,
  text,
  children,
  customClass,
  customIcon
}) => {
  if (access === basicAccess || access === 'support') {
    return (
      <TooltipBox
        text={`${access === basicAccess ? 'Full' : 'Admin'} access required`}
        placement={TooltipPlacement.RIGHT}
      >
        <div
          className={`${customClass ? customClass : classes.navLeftOption} ${
            customIcon ? classes.dashNavDisabledCustom : classes.dashNavDisabled
          }`}
        >
          {children}
          {text}
        </div>
      </TooltipBox>
    );
  } else {
    return (
      <Link
        className={customClass ? customClass : classes.navLeftOption}
        to={url}
      >
        {children}
        {text}
      </Link>
    );
  }
};

export const checkPageAccess = (accessName, pageAccess) => {
  if (!accessName) return true;
  if (pageAccess[accessName]) return true;
  return false;
};

export const startLinks = (leftData, pathname) => {
  const data = [];
  if (Array.isArray(leftData))
    leftData.forEach((d) => {
      if (d.type === 'list' && Array.isArray(d.list)) {
        d.list.forEach((l) => {
          if (l.url) {
            data.push({
              path: l.url,
              isActive: l.url === pathname
            });
          }
        });
      } else if (d.url) {
        data.push({
          path: d.url,
          isActive: d.url === pathname
        });
      }
    });

  return data;
};
