import { MdSearch } from 'react-icons/md';
import classes from './Input.module.css';

export const InputTypes = {
  LOGIN: classes.inputLogin,
  USER: classes.inputUser,
  FILE: classes.inputFile,
  LABEL: classes.inputLabel,
  LABELOUTLINE: classes.inputLabelOutline,
  SEARCH: classes.inputSearch,
  SEARCHLINE: classes.inputSearchLine
};

export const Input = ({
  className = '',
  classNameInput = '',
  value,
  onChange,
  error,
  label,
  inputType = label ? InputTypes.LABEL : '',
  placeholder = ' ',
  isSearch,
  ...otherProps
}) => {
  return (
    <div
      className={`${className} ${
        label && !isSearch ? classes.inputLabelContainer : ''
      } ${
        inputType === InputTypes.SEARCH ? classes.inputSearchContainer : ''
      } ${
        inputType === InputTypes.SEARCHLINE
          ? classes.inputSearchLineContainer
          : ''
      }`}
    >
      <input
        className={`${classes.input} ${inputType} ${
          error ? classes.inputError : ''
        } ${classNameInput}`}
        placeholder={placeholder}
        value={value}
        autoComplete="off"
        onChange={onChange}
        {...otherProps}
      />
      {label && !isSearch && (
        <label
          className={`${
            inputType === InputTypes.LABEL
              ? classes.inputHeading
              : classes.inputHeadingOutline
          }`}
          htmlFor={otherProps.id}
        >
          {label}
        </label>
      )}
      {error !== undefined && !isSearch && (
        <p className={classes.error}>{error}</p>
      )}
      {isSearch && <MdSearch className={classes.inputSearchIcon} />}
    </div>
  );
};
